import { Auth } from "aws-amplify"
import Constants from "../../Constants"

export default class UserPermissionLevel {
  private static instance: UserPermissionLevel

  private permissionLevel: string

  private constructor() {
    this.permissionLevel = Constants.PermissionLevels.InternalUnauthorized
  }

  static getInstance() {
    if (!UserPermissionLevel.instance) {
      UserPermissionLevel.instance = new UserPermissionLevel()
    }
    return UserPermissionLevel.instance
  }

  getPermissionLevel() {
    return this.permissionLevel
  }

  // TODO: Once all components are refactored to use FeatureAccessControl.isFeatureOn,
  // the getIs<user-type> methods below should be removed as they will no longer be
  // needed.
  getIsExternalCustomer() {
    return (
      this.getPermissionLevel() === Constants.PermissionLevels.ExternalUsers
    )
  }

  getIsTsoLogicUser() {
    return (
      this.getPermissionLevel() === Constants.PermissionLevels.TsoLogicUsers
    )
  }

  getIsAdminUser() {
    return this.getPermissionLevel() === Constants.PermissionLevels.AdminUsers
  }

  getIsAmazonUser() {
    return this.getPermissionLevel() === Constants.PermissionLevels.AmazonUsers
  }

  getIsUnauthorizedInternalUser() {
    return (
      this.getPermissionLevel() ===
      Constants.PermissionLevels.InternalUnauthorized
    )
  }

  checkUserPermissionLevel = async () => {
    const idToken = (await Auth.currentSession()).getIdToken().decodePayload()

    const permissionsList = JSON.parse(idToken["ems:user_roles"])

    // Note: The order in which the permissionLevel is set is important here. This
    // guarantees that only the least permissive level is assigned to the user. For
    // example, a TsoLogicUser who's also on the UnAuthorized list will have their
    // access level set as UnAuthorized.
    if (permissionsList.includes(Constants.PermissionLevels.AdminUsers))
      this.permissionLevel = Constants.PermissionLevels.AdminUsers
    if (permissionsList.includes(Constants.PermissionLevels.TsoLogicUsers))
      this.permissionLevel = Constants.PermissionLevels.TsoLogicUsers
    if (permissionsList.includes(Constants.PermissionLevels.AmazonUsers))
      this.permissionLevel = Constants.PermissionLevels.AmazonUsers
    if (permissionsList.includes(Constants.PermissionLevels.ExternalUsers))
      this.permissionLevel = Constants.PermissionLevels.ExternalUsers
    if (
      permissionsList.includes(Constants.PermissionLevels.InternalUnauthorized)
    )
      this.permissionLevel = Constants.PermissionLevels.InternalUnauthorized
  }
}
