import { defineMessages } from "react-intl"

const messages = defineMessages({
  navHeader: {
    id: "nav.header",
    defaultMessage: "Migration Evaluator"
  }
})

export default messages
