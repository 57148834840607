import * as React from "react"
import Modal from "@amzn/awsui-components-react/polaris/modal"
import Box from "@amzn/awsui-components-react/polaris/box"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between"
import Button from "@amzn/awsui-components-react/polaris/button"
import Alert from "@amzn/awsui-components-react/polaris/alert"
import FormField from "@amzn/awsui-components-react/polaris/form-field"
import Input from "@amzn/awsui-components-react/polaris/input"

interface IConfirmationModalProps {
  onDismiss: () => void | Promise<void>
  visible: boolean
  closeAriaLabel: string
  onClickCancel: () => void | Promise<void>
  onClickConfirm: () => void | Promise<void>
  confirm: string
  header: string
  content: string
  label: string
  description?: string
  placeholder: string
  modalId: string
  cancelId: string
  confirmId: string
  inputId: string
}

interface IConfirmationModalState {
  value: string
  confirmDisabled: boolean
}

class ConfirmationModal extends React.Component<
  IConfirmationModalProps,
  IConfirmationModalState
> {
  constructor(props: IConfirmationModalProps) {
    super(props)
    this.state = {
      value: "",
      confirmDisabled: true
    }
  }

  render() {
    const {
      onDismiss,
      visible,
      closeAriaLabel,
      onClickCancel,
      onClickConfirm,
      confirm,
      header,
      content,
      label,
      description,
      placeholder,
      modalId,
      cancelId,
      confirmId,
      inputId
    } = this.props
    const { value, confirmDisabled } = this.state
    return (
      <Modal
        data-testid={modalId}
        onDismiss={onDismiss}
        visible={visible}
        closeAriaLabel={closeAriaLabel}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                data-testid={cancelId}
                variant="link"
                onClick={onClickCancel}
              >
                Cancel
              </Button>
              <Button
                data-testid={confirmId}
                disabled={confirmDisabled}
                variant="primary"
                onClick={onClickConfirm}
              >
                {confirm}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={header}
      >
        <Alert statusIconAriaLabel="Warning" type="warning">
          {content}
        </Alert>
        <br />
        <FormField label={label} description={description}>
          <Input
            data-testid={inputId}
            onChange={({ detail }) => {
              this.setState({
                value: detail.value,
                confirmDisabled: detail.value !== placeholder
              })
            }}
            value={value}
            placeholder={placeholder}
          />
        </FormField>
      </Modal>
    )
  }
}

export default ConfirmationModal
