import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { Auth } from "aws-amplify"
import {
  StatusIndicator,
  Box,
  Flashbar,
  ColumnLayout,
  Button,
  Input,
  InputProps,
  Form,
  FormField,
  SpaceBetween
} from "@amzn/awsui-components-react/polaris"
import QrCode from "qrcode.react"
import messages from "./EnableMfa.messages"
import commonMessages from "../Common.messages"
import Constants from "../../Constants"

interface IEnableMfaProps {
  intl: IntlShape
}
interface IEnableMfaState {
  loading: boolean
  mfaEnabled: boolean
  user: any
  totpKey: string
  totpQrCode: string
  totpToken: string
  serverSideError: string
}

export class EnableMfa extends React.PureComponent<
  IEnableMfaProps,
  IEnableMfaState
> {
  willUnmount: boolean = false

  constructor(props: IEnableMfaProps) {
    super(props)
    this.state = {
      loading: true,
      mfaEnabled: false,
      user: undefined,
      totpKey: "",
      totpQrCode: "",
      totpToken: "",
      serverSideError: ""
    }
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      this.setState({ user })
      const preferredMfa = await Auth.getPreferredMFA(user)
      const mfaEnabled = preferredMfa === "SOFTWARE_TOKEN_MFA"
      this.setState({
        mfaEnabled,
        loading: false
      })
      if (!mfaEnabled) {
        await this.generateQrCodeAsync()
      }
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line
      console.error(err)
    }
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  generateQrCodeAsync = async () => {
    const { user } = this.state
    const totpKey = await Auth.setupTOTP(user)
    const totpQrCode = `otpauth://totp/AWS%20TSO%20Logic:${user.attributes.email}?secret=${totpKey}&issuer=AWS_TSO_Logic`

    if (!this.willUnmount) {
      this.setState({ totpKey, totpQrCode })
    }
  }

  verifyTotpTokenAsync = async () => {
    const {
      intl: { formatMessage }
    } = this.props
    const { user, totpToken } = this.state
    try {
      await Auth.verifyTotpToken(user, totpToken)
      await Auth.setPreferredMFA(user, "TOTP")
      if (!this.willUnmount) {
        this.setState({ mfaEnabled: true })
      }
    } catch (err: any) { // eslint-disable-line
      let errorMessage: string
      if (err.code) {
        switch (err.code) {
          case "InvalidParameterException":
            errorMessage = formatMessage(messages.enableMfaErrorTokenInvalid)
            break
          case "EnableSoftwareTokenMFAException":
            errorMessage = formatMessage(messages.enableMfaErrorTokenMismatch)
            break
          default:
            errorMessage = err.code
            // eslint-disable-next-line
              console.error(err)
            break
        }
      } else {
        errorMessage = `Unknown Error: ${err}`
        // eslint-disable-next-line
        console.error(err)
      }
      if (!this.willUnmount) {
        this.setState({ serverSideError: errorMessage })
      }
    }
  }

  submitMfaOnReturn = (detail: InputProps.KeyDetail) => {
    if (detail.keyCode === Constants.ReturnKeyCode) {
      this.onSubmitMfaToken()
    }
  }

  onSubmitMfaToken = () => {
    return this.verifyTotpTokenAsync()
  }

  onTotpTokenChange = (detail: InputProps.ChangeDetail) => {
    this.setState({
      totpToken: detail.value
    })
  }

  render() {
    const {
      mfaEnabled,
      loading,
      totpKey,
      totpQrCode,
      totpToken,
      serverSideError
    } = this.state
    const {
      intl: { formatMessage }
    } = this.props

    if (loading) {
      return (
        <div className="awsui text-center">
          <StatusIndicator type="loading">
            {formatMessage(commonMessages.loading)}
          </StatusIndicator>
        </div>
      )
    }

    if (mfaEnabled) {
      return (
        <Flashbar
          id="mfaEnabledFlash"
          items={[
            {
              type: "success",
              content: formatMessage(messages.enableMfaEnabled),
              dismissible: false
            }
          ]}
        />
      )
    }

    return (
      <div>
        <Form
          id="enableMfaForm"
          errorText={serverSideError}
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                id="submitMfaTokenButton"
                variant="primary"
                onClick={this.onSubmitMfaToken}
              >
                {formatMessage(commonMessages.submit)}
              </Button>
            </SpaceBetween>
          }
        >
          <ColumnLayout>
            <FormField
              stretch
              label={formatMessage(messages.enableMfaScanQrCode)}
              description={formatMessage(
                messages.enableMfaScanQrCodeDescription
              )}
            >
              <Box margin="l">
                <QrCode
                  value={totpQrCode}
                  renderAs="svg"
                  size={Constants.QrCodeSize}
                />
              </Box>
            </FormField>
            <FormField
              stretch
              label={formatMessage(messages.enableMfaTotpSetupKey)}
              description={formatMessage(
                messages.enableMfaTotpSetupKeyDescription
              )}
            >
              <Input
                id="enableMfaTotpKeyInput"
                type="text"
                readOnly
                value={totpKey}
                onChange={({ detail }) => this.onTotpTokenChange(detail)}
              />
            </FormField>
            <FormField
              stretch
              label={formatMessage(messages.enableMfaToken)}
              description={formatMessage(messages.enableMfaTokenDescription)}
            >
              <Input
                id="enableMfaTotpTokenInput"
                type="text"
                autoFocus
                value={totpToken}
                onChange={({ detail }) => this.onTotpTokenChange(detail)}
                onKeyDown={({ detail }) => this.submitMfaOnReturn(detail)}
              />
            </FormField>
          </ColumnLayout>
          <Box padding={{ bottom: "l" }} />
        </Form>
      </div>
    )
  }
}

export default injectIntl(EnableMfa)
