import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import {
  Box,
  Button,
  Container,
  Grid
} from "@amzn/awsui-components-react/polaris"
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom"
import { library as iconsLibrary } from "@fortawesome/fontawesome-svg-core"
import {
  faSearch,
  faServer,
  faBuilding
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import messages from "./Discover.messages"
import Constants from "../../Constants"

iconsLibrary.add(faSearch, faServer, faBuilding)

interface IDiscoverProps {
  intl: IntlShape
}
interface IDiscoverState {
  redirect: string
}

export class Discover extends React.PureComponent<
  RouteComponentProps & IDiscoverProps,
  IDiscoverState
> {
  constructor(props: RouteComponentProps & IDiscoverProps) {
    super(props)
    this.state = {
      redirect: ""
    }
  }

  componentDidMount() {
    const {
      intl: { formatMessage }
    } = this.props
    document.title = formatMessage(messages.discoverDocumentTitle)
  }

  onStartDiscovery = () => {
    const {
      match: { url }
    } = this.props
    this.setState({ redirect: `${url}/${Constants.PathName.DiscoverTools}` })
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props

    const { redirect } = this.state

    if (redirect.length > 0) {
      return <Redirect push to={redirect} />
    }

    return (
      <Container
        footer={
          <Box textAlign="center">
            <Button
              data-testid="startDiscovery"
              variant="primary"
              onClick={this.onStartDiscovery}
            >
              {formatMessage(messages.discoverStartDiscovery)}
            </Button>
          </Box>
        }
      >
        <Box textAlign="center">
          <Box
            variant="span"
            fontSize="display-l"
            padding={{ top: "n", bottom: "s" }}
            fontWeight="light"
          >
            {formatMessage(messages.discoverTitle)}
          </Box>
        </Box>

        <div>
          <Box padding={{ vertical: "xxl" }} />
          <Box padding={{ vertical: "xxl" }} />
          <Grid
            gridDefinition={[
              { colspan: { xxs: 4 } },
              { colspan: { xxs: 4 } },
              { colspan: { xxs: 4 } }
            ]}
          >
            <Box textAlign="center">
              <FontAwesomeIcon size="5x" icon="search" />
            </Box>
            <Box textAlign="center">
              <FontAwesomeIcon size="5x" icon="server" />
            </Box>
            <Box textAlign="center">
              <FontAwesomeIcon size="5x" icon="building" />
            </Box>
          </Grid>
          <Grid
            gridDefinition={[
              { colspan: { xxs: 4 } },
              { colspan: { xxs: 4 } },
              { colspan: { xxs: 4 } }
            ]}
          >
            <Box textAlign="center">
              {formatMessage(messages.discoverDiscoverServers)}
            </Box>
            <Box textAlign="center">
              {formatMessage(messages.discoverMeasurePerformance)}
            </Box>
            <Box textAlign="center">
              {formatMessage(messages.discoverCaptureLicenseAndEnvironments)}
            </Box>
          </Grid>
          <Box padding={{ vertical: "xxl" }} />
          <Box padding={{ vertical: "xxl" }} />
        </div>
        <div />
      </Container>
    )
  }
}

export default injectIntl(withRouter(Discover))
