/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { withRouter, RouteComponentProps } from "react-router-dom"
import {
  Box,
  BoxProps,
  Container,
  Grid,
  Header,
  Badge,
  ColumnLayout,
  FormField,
  Form,
  Toggle,
  Input,
  Button,
  ToggleProps,
  Spinner
} from "@amzn/awsui-components-react/polaris"
import { API, graphqlOperation } from "aws-amplify"
import { getEngagement } from "./graphql/queries"
import messages from "./EngagementConfiguration.messages"
import CopyButton from "../utility/CopyButton"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"

interface ICollector {
  UserAccessKey: string
  UserSecretKey: string
  UserAccessKeyStatus?: string
  UserAccessKeyExpiryTime?: string
  Certificate: string
  Installer: string
  Bootstrapper: string
  Status: string
}

interface IEngagementDetail {
  getEngagement: {
    Id: string
    Name: string
    Customer: string
    Status: string
    S3Bucket: string
    S3KeyPrefix: string
    Collector: ICollector
  }
}

interface IEngagementConfigurationProp {
  intl: IntlShape
  engagementId: string
}

interface IEngagementConfigurationState {
  loading: boolean
  engagement: IEngagementDetail
  showAccessToken: boolean
  foundError: boolean
  externalCustomer: boolean
}

export class EngagementConfiguration extends React.Component<
  RouteComponentProps & IEngagementConfigurationProp,
  IEngagementConfigurationState
> {
  private mounted: boolean

  private readonly hide: string = "hide"

  private readonly show: string = "show"

  constructor(props: RouteComponentProps & IEngagementConfigurationProp) {
    super(props)
    this.state = {
      loading: true,
      showAccessToken: false,
      foundError: false,
      engagement: {} as IEngagementDetail,
      externalCustomer: false
    }
    this.mounted = false
  }

  async componentDidMount() {
    this.mounted = true
    let currentEngagement: IEngagementDetail | undefined
    try {
      const response = await API.graphql(
        graphqlOperation(getEngagement, { id: this.props.engagementId })
      )
      const engagementData = response as {
        data: IEngagementDetail
      }
      currentEngagement = engagementData.data
    } catch (err: any) { // eslint-disable-line
      console.error(err)
    }
    if (this.mounted) {
      if (currentEngagement !== undefined) {
        this.updateState(currentEngagement)
      } else {
        this.setState({
          loading: false,
          foundError: true
        })
      }
    }
    await this.checkUserPermissionLevel()
  }

  // This method should always keep the setState within the condition
  // otherwise it will end up into infinite loop
  async componentDidUpdate(
    prevProps: RouteComponentProps & IEngagementConfigurationProp
  ) {
    let currentEngagement: IEngagementDetail | undefined
    if (this.props.engagementId !== prevProps.engagementId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: true
      })
      try {
        const response = await API.graphql(
          graphqlOperation(getEngagement, { id: this.props.engagementId })
        )
        const engagementData = response as {
          data: IEngagementDetail
        }
        currentEngagement = engagementData.data
      } catch (err: any) { // eslint-disable-line
        console.error(err)
      }
      if (this.mounted) {
        if (currentEngagement !== undefined) {
          this.updateState(currentEngagement)
        } else {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            loading: true,
            foundError: true
          })
        }
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  toggleShowAccessToken = (detail: ToggleProps.ChangeDetail) => {
    this.setState({ showAccessToken: detail.checked })
  }

  checkUserPermissionLevel = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()
    if (this.mounted) {
      this.setState({
        externalCustomer: UserPermissionLevel.getInstance().getIsExternalCustomer()
      })
    }
  }

  private formatS3Path = (s3Bucket: string, s3KeyPrefix: string): string => {
    return s3KeyPrefix ? `${s3Bucket}/${s3KeyPrefix}` : s3Bucket
  }

  private getBadgeColorForAccessKeyStatus = (
    status?: string
  ): "red" | "green" | "grey" => {
    if (status === "Active") return "green"
    if (status === "Inactive") return "red"
    return "grey"
  }

  private getAccessKeyFormattedStatus = (status?: string): string => {
    const {
      intl: { formatMessage }
    } = this.props
    if (status === "Active")
      return formatMessage(messages.engagementAccessKeyStatusActive)
    if (status === "Inactive")
      return formatMessage(messages.engagementAccessKeyStatusExpired)
    return formatMessage(messages.engagementAccessKeyStatusUnknown)
  }

  private getAccessKeyHint = (
    status?: string,
    expiryTime?: string
  ): JSX.Element => {
    const {
      intl: { formatMessage, formatRelativeTime }
    } = this.props

    let message = ""
    let textColor: BoxProps.Color = "text-body-secondary"

    if (status === "Inactive")
      message = formatMessage(messages.engagementContactSAForRenewalMessage)
    if (status === "Active" && expiryTime) {
      const expiryTimeParsed = new Date(expiryTime)
      const now = new Date()
      const daysUntilExpiry = Math.floor(
        (expiryTimeParsed.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
      )

      if (daysUntilExpiry <= 14) {
        textColor = "text-status-error"

        if (daysUntilExpiry <= 0) {
          message = formatMessage(messages.engagementContactSAForRenewalMessage)
        } else {
          const timeRemaining = formatRelativeTime(daysUntilExpiry, "days")
          message = `${formatMessage(
            messages.engagementAccessKeyExpiryNotification,
            {
              timeRemaining
            }
          )} ${formatMessage(messages.engagementContactSAForRenewalMessage)}`
        }
      }
    }

    return (
      <Box variant="small" color={textColor}>
        {message}
      </Box>
    )
  }

  showSecretKeyBasedOnUserRole = () => {
    const {
      intl: { formatMessage }
    } = this.props
    const { engagement, externalCustomer, showAccessToken } = this.state

    if (externalCustomer) {
      return (
        <>
          {engagement.getEngagement.Collector.UserAccessKeyStatus !==
          "Inactive" ? (
            <FormField
              data-testid="engagementAccessTokenField"
              label={formatMessage(messages.engagementAccessTokenTitle)}
              secondaryControl={
                <CopyButton
                  value={engagement.getEngagement.Collector.UserSecretKey}
                />
              }
            >
              <Input
                data-testid="engagementAccessTokenFieldInput"
                readOnly
                type={showAccessToken ? "text" : "password"}
                value={engagement.getEngagement.Collector.UserSecretKey}
              />
              <Toggle
                checked={showAccessToken}
                onChange={({ detail }) => this.toggleShowAccessToken(detail)}
              >
                {formatMessage(messages.engagementAccessTokenShow)}
              </Toggle>
            </FormField>
          ) : null}
        </>
      )
    }

    return null
  }

  private updateState(currentEngagement: IEngagementDetail) {
    const { engagementId } = this.props
    if (this.mounted && engagementId === currentEngagement.getEngagement.Id) {
      this.setState({
        loading: false,
        engagement: currentEngagement
      })
    }
  }

  render() {
    const {
      intl: { formatMessage, formatDate, formatTime }
    } = this.props

    const { loading, engagement, foundError } = this.state

    if (loading || engagement.getEngagement === undefined) {
      return (
        <Container
          header={
            <Header variant="h2">
              {formatMessage(messages.engagementConfigurationHeader)}
            </Header>
          }
        >
          <div className="awsui text-center">
            <span className="v-center">
              <Spinner size="big" variant="disabled" />
            </span>
          </div>
        </Container>
      )
    }
    return (
      <Container
        data-testid="onPremConfigDetails"
        header={
          <Header variant="h2">
            {formatMessage(messages.engagementConfigurationHeader)}: &nbsp;
            {engagement.getEngagement.Name}
          </Header>
        }
      >
        <Grid gridDefinition={[{ colspan: { default: 12, m: 8 } }]}>
          <Form>
            <ColumnLayout columns={1} id="configuration">
              <FormField
                id="engagementCertificateField"
                label={formatMessage(messages.engagementCertificateTitle)}
              >
                <Button
                  data-testid="downloadCertificateButton"
                  href={engagement.getEngagement.Collector.Certificate}
                  iconName="download"
                  disabled={foundError}
                >
                  {formatMessage(messages.engagementCertificateLinkText)}
                </Button>
              </FormField>
              <FormField
                data-testid="engagementS3BucketRegionField"
                label={formatMessage(messages.engagementS3BucketRegionTitle)}
              >
                <Input
                  data-testid="engagementS3BucketRegionFieldInput"
                  readOnly
                  value={formatMessage(messages.engagementS3BucketRegion)}
                />
              </FormField>
              <FormField
                data-testid="engagementS3BucketField"
                label={formatMessage(messages.engagementS3BucketTitle)}
                secondaryControl={
                  <CopyButton
                    value={this.formatS3Path(
                      engagement.getEngagement.S3Bucket,
                      engagement.getEngagement.S3KeyPrefix
                    )}
                  />
                }
              >
                <Grid
                  disableGutters
                  gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}
                >
                  <Input disabled value="S3://" />
                  <Input
                    data-testid="engagementS3BucketFieldInput"
                    readOnly
                    value={this.formatS3Path(
                      engagement.getEngagement.S3Bucket,
                      engagement.getEngagement.S3KeyPrefix
                    )}
                  />
                </Grid>
              </FormField>
              <FormField
                data-testid="engagementAccessKeyField"
                label={
                  <span>
                    {formatMessage(messages.engagementAccessKeyTitle)}
                    &nbsp;
                    <Badge
                      color={this.getBadgeColorForAccessKeyStatus(
                        engagement.getEngagement.Collector.UserAccessKeyStatus
                      )}
                      data-testid="badgeStatus"
                    >
                      {this.getAccessKeyFormattedStatus(
                        engagement.getEngagement.Collector.UserAccessKeyStatus
                      )}
                    </Badge>
                    &nbsp;
                    {engagement.getEngagement.Collector.UserAccessKeyStatus !==
                      "Inactive" &&
                    engagement.getEngagement.Collector
                      .UserAccessKeyExpiryTime ? (
                      <Badge>
                        {formatMessage(messages.engagementAccessKeyExpiryDate)}:
                        &nbsp;
                        {`${formatDate(
                          engagement.getEngagement.Collector
                            .UserAccessKeyExpiryTime
                        )} ${formatTime(
                          engagement.getEngagement.Collector
                            .UserAccessKeyExpiryTime
                        )}`}
                      </Badge>
                    ) : null}
                  </span>
                }
                secondaryControl={
                  <CopyButton
                    value={engagement.getEngagement.Collector.UserAccessKey}
                  />
                }
                constraintText={this.getAccessKeyHint(
                  engagement.getEngagement.Collector.UserAccessKeyStatus,
                  engagement.getEngagement.Collector.UserAccessKeyExpiryTime
                )}
              >
                <Input
                  data-testid="engagementAccessKeyFieldInput"
                  readOnly
                  value={engagement.getEngagement.Collector.UserAccessKey}
                />
              </FormField>
              {this.showSecretKeyBasedOnUserRole()}
            </ColumnLayout>
          </Form>
        </Grid>
      </Container>
    )
  }
}

export default injectIntl(withRouter(EngagementConfiguration))
