import { defineMessages } from "react-intl"

const messages = defineMessages({
  engagementConfigurationHeader: {
    id: "engagements.configurationHeader",
    defaultMessage: "Configuration"
  },
  engagementCertificateTitle: {
    id: "engagements.certificateTitle",
    defaultMessage: "Encryption certificate"
  },
  engagementCertificateLinkText: {
    id: "engagements.certificateLinkText",
    defaultMessage: "Download certificate"
  },
  engagementS3BucketTitle: {
    id: "engagement.s3BucketTitle",
    defaultMessage: "S3 synchronization path"
  },
  engagementAccessKeyTitle: {
    id: "engagement.accessKeyTitle",
    defaultMessage: "Access key"
  },
  engagementAccessTokenTitle: {
    id: "engagement.accessTokenTitle",
    defaultMessage: "Secret access key"
  },
  engagementAccessTokenShow: {
    id: "engagement.accessTokenShow",
    defaultMessage: "Show secret"
  },
  engagementS3BucketRegionTitle: {
    id: "engagement.s3BucketRegionTitle",
    defaultMessage: "Region"
  },
  engagementS3BucketRegion: {
    id: "engagement.s3BucketRegion",
    defaultMessage: "US East (N. Virginia)"
  },
  engagementAccessKeyStatusActive: {
    id: "engagement.accessKeyStatusActive",
    defaultMessage: "Active"
  },
  engagementAccessKeyStatusExpired: {
    id: "engagement.accessKeyStatusExpired",
    defaultMessage: "Expired"
  },
  engagementAccessKeyStatusUnknown: {
    id: "engagement.accessKeyStatusUnknown",
    defaultMessage: "Status unknown"
  },
  engagementAccessKeyExpiryDate: {
    id: "engagement.accessKeyExpiryDate",
    defaultMessage: "Expires on"
  },
  engagementAccessKeyExpiryNotification: {
    id: "engagement.accessKeyExpiryNotification",
    defaultMessage: "Your access key is about to expire {timeRemaining}."
  },
  engagementContactSAForRenewalMessage: {
    id: "engagement.contactSAForRenewalMessage",
    defaultMessage:
      "Please contact your account manager to renew this key to continue synchronizing data to Migration Evaluator."
  }
})

export default messages
