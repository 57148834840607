import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { API, graphqlOperation } from "aws-amplify"
import { Box, Grid, Alert } from "@amzn/awsui-components-react/polaris"
import messages from "./MyEngagements.messages"
import commonMessages from "../../components/Common.messages"
import Engagements, {
  IEngagement
} from "../../components/engagements/Engagements"
import CustomerDataShareConsentOptions from "../../components/engagements/CustomerDataShareConsentOptions"
import SummaryReport from "../../components/engagements/SummaryReport"
import { getEngagementAndListReports } from "../../components/engagements/graphql/queries"
import FrequentlyAskedQuestions from "../../components/help/FrequentlyAskedQuestions"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"
import AppConfig from "../../Config"

const { Config } = AppConfig

interface IEngagementData {
  getEngagementAndListReports: {
    Id: string
    Name: string
    Customer: string
    SummaryReports: IEngagementSummaryReportInfo[]
  }
}

interface IEngagementSummaryReportInfo {
  Id: string
  DetailedId: string
  AvailableDetailedFormats: string[]
  CreatedOn: string
}

interface IMyEngagementsProps {
  intl: IntlShape
  addBreadCrumb(text: string, href: string): void
  removeBreadCrumb(href: string): void
  setSelectedEngagement(newSelectedEngagement: IEngagement | null): void
  selectedEngagement: IEngagement | null
}

interface IMyEngagementsState {
  engagementReportExists: boolean
  latestReportId: string
  latestDetailedReportId: string
  availableDetailedFormats: string[]
  externalCustomer: boolean
  errorExist: boolean
}

export class MyEngagements extends React.Component<
  RouteComponentProps & IMyEngagementsProps,
  IMyEngagementsState
> {
  private mounted: boolean

  constructor(props: RouteComponentProps & IMyEngagementsProps) {
    super(props)
    this.state = {
      engagementReportExists: false,
      latestReportId: "",
      latestDetailedReportId: "",
      availableDetailedFormats: [],
      externalCustomer: true,
      errorExist: false
    }

    this.mounted = false
  }

  async componentDidMount() {
    const {
      addBreadCrumb,
      match,
      selectedEngagement,
      intl: { formatMessage }
    } = this.props
    addBreadCrumb(formatMessage(messages.myEngagementsBreadCrumb), match.url)
    document.title = formatMessage(messages.myEngagementsDocumentTitle)
    this.mounted = true

    await this.checkUserPermissionLevel()

    if (selectedEngagement?.Id) {
      await this.checkLatestSummaryReport(selectedEngagement.Id)
    }
  }

  componentWillUnmount() {
    const { removeBreadCrumb, match } = this.props
    removeBreadCrumb(match.url)
    this.mounted = false
  }

  updateSelectedEngagement = async (
    newSelectedEngagement: IEngagement | null
  ) => {
    const { setSelectedEngagement } = this.props

    this.setState({
      engagementReportExists: false,
      latestReportId: ""
    })

    setSelectedEngagement(newSelectedEngagement)

    if (Config.Features.SummaryReport && newSelectedEngagement?.Id) {
      await this.checkLatestSummaryReport(newSelectedEngagement?.Id)
    }
  }

  checkUserPermissionLevel = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()

    if (this.mounted) {
      this.setState({
        externalCustomer: UserPermissionLevel.getInstance().getIsExternalCustomer()
      })
    }
  }

  private checkLatestSummaryReport = async (engagementId: string) => {
    // make list call
    try {
      this.setState({ errorExist: false })
      const response = await API.graphql(
        graphqlOperation(getEngagementAndListReports, { id: engagementId })
      )
      const engagement = response as { data: IEngagementData }

      this.updateStateWithLatestResultId(engagement.data)
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      if (this.mounted) {
        this.setState({ errorExist: true })
      }
    }
  }

  updateStateWithLatestResultId(currentEngagementData: IEngagementData) {
    const { selectedEngagement } = this.props
    const selectedEngagementId = selectedEngagement?.Id

    if (
      selectedEngagementId ===
      currentEngagementData.getEngagementAndListReports.Id
    ) {
      const result =
        currentEngagementData.getEngagementAndListReports.SummaryReports
      let latestDetailedReportId = ""
      let availableDetailedFormats: string[] = []
      if (result.length > 0) {
        result.sort((a, b) => (a.CreatedOn > b.CreatedOn ? 1 : -1))
        const latestResult = result[result.length - 1]
        this.setState({
          engagementReportExists: true,
          latestReportId: latestResult.Id
        })

        if (latestResult.DetailedId) {
          latestDetailedReportId = latestResult.DetailedId
          availableDetailedFormats = latestResult.AvailableDetailedFormats
        }
      } else if (this.mounted) {
        this.setState({
          engagementReportExists: false
        })
      }

      this.setState({
        latestDetailedReportId,
        availableDetailedFormats
      })
    }
  }

  render() {
    const {
      intl: { formatMessage },
      selectedEngagement
    } = this.props
    const {
      engagementReportExists,
      latestReportId,
      latestDetailedReportId,
      availableDetailedFormats,
      externalCustomer,
      errorExist
    } = this.state

    const selectedEngagementId = selectedEngagement?.Id

    return (
      <div>
        <Alert
          id="serverError"
          header={formatMessage(commonMessages.errorHeader)}
          type="error"
          dismissible
          visible={errorExist}
          onDismiss={() => this.setState({ errorExist: false })}
          dismissAriaLabel="Close alert"
        >
          {formatMessage(commonMessages.error)}
        </Alert>
        <Grid gridDefinition={[{ colspan: 12 }]}>
          <Engagements
            setSelectedEngagement={this.updateSelectedEngagement}
            selectedEngagement={selectedEngagement}
            isOnEngagementsPage
          />
        </Grid>
        {Config.Features.SummaryReport ? (
          <Box margin={{ bottom: "m" }}>
            <Grid gridDefinition={[{ colspan: 12 }]}>
              {selectedEngagementId &&
              (Config.Features.EngagementSummaryReportGeneralAvailability ||
                engagementReportExists) ? (
                <SummaryReport
                  engagementId={selectedEngagementId}
                  hasReport={engagementReportExists}
                  summaryReportId={latestReportId}
                  detailedReportId={latestDetailedReportId}
                  availableDetailedFormats={availableDetailedFormats}
                />
              ) : null}
            </Grid>
          </Box>
        ) : null}

        {selectedEngagementId ? (
          <Box margin={{ bottom: "m" }}>
            <Grid gridDefinition={[{ colspan: 12 }]}>
              <CustomerDataShareConsentOptions
                engagementId={selectedEngagementId}
              />
            </Grid>
          </Box>
        ) : null}
        <Box margin={{ bottom: "m" }}>
          <Grid gridDefinition={[{ colspan: 12 }]}>
            <FrequentlyAskedQuestions externalCustomer={externalCustomer} />
          </Grid>
        </Box>
      </div>
    )
  }
}

export default injectIntl(withRouter(MyEngagements))
