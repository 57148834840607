import Constants from "../../Constants"
import UserPermissionLevel from "./UserPermissionLevel"

const { Features: features } = Constants
const { PermissionLevels: permissions } = Constants

const FeatureAccessControlMap = {
  // Managed Accounts
  [features.CanCreateManagedAccount]: [
    permissions.AdminUsers,
    permissions.TsoLogicUsers
  ],
  [features.CanCloseManagedAccount]: [
    permissions.AdminUsers,
    permissions.TsoLogicUsers
  ],
  [features.CanCreateAccessKeyForManagedAccount]: [
    permissions.AdminUsers,
    permissions.TsoLogicUsers,
    permissions.ExternalUsers
  ],
  [features.CanFederateIntoManagedAccount]: [
    permissions.AdminUsers,
    permissions.TsoLogicUsers,
    permissions.ExternalUsers
  ],
  [features.CanRetrieveAuditLogForManagedAccount]: [
    permissions.AdminUsers,
    permissions.TsoLogicUsers,
    permissions.ExternalUsers,
    permissions.AmazonUsers
  ],

  // AutoImporter
  [features.CanDownloadCustomerReportedFiles]: [
    permissions.AdminUsers,
    permissions.TsoLogicUsers
  ],
  [features.CanDownloadValidationReport]: [
    permissions.AdminUsers,
    permissions.TsoLogicUsers
  ],

  // Summary Report
  [features.CanDownloadPhysicalServerReport]: [
    permissions.AdminUsers,
    permissions.TsoLogicUsers
  ]
}

export default class FeatureAccessControl {
  static isFeatureOn = async (feature: string) => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()
    return (
      feature in FeatureAccessControlMap &&
      FeatureAccessControlMap[feature].includes(
        UserPermissionLevel.getInstance().getPermissionLevel()
      )
    )
  }
}
