import React from "react"
import { Alert, TextContent, Link } from "@amzn/awsui-components-react/polaris"
import { injectIntl, IntlShape } from "react-intl"
import {
  AlertType,
  CREATE_ACCOUNT_TICKET_URL,
  CLOSE_ACCOUNT_TICKET_URL,
  ACCOUNT_FEDERATION_TICKET_URL,
  ACCOUNT_AUDIT_LOG_RETRIEVAL_TICKET_URL,
  ACCOUNT_ACCESS_KEYS_RETRIEVAL_TICKET_URL
} from "./AccountDetails.constants"
import messages from "./AccountDetailsAlert.messages"

interface IAccountDetailsAlertProps {
  intl: IntlShape
  alertType: AlertType
  externalCustomer: boolean
  dismissAlert: Function
}

export class AccountDetailsAlert extends React.Component<
  IAccountDetailsAlertProps,
  {}
> {
  private formatMessage: Function

  constructor(props: IAccountDetailsAlertProps) {
    super(props)
    const {
      intl: { formatMessage }
    } = this.props
    this.formatMessage = formatMessage
  }

  private getAlertHeader = (alertType: AlertType) => {
    switch (alertType) {
      case AlertType.AccountCreationError:
        return this.formatMessage(messages.accountCreationFailedHeader)
      case AlertType.AccountCreationTimeout:
        return this.formatMessage(messages.accountCreationTimeoutHeader)
      case AlertType.AccountClosureError:
        return this.formatMessage(messages.accountClosureFailedHeader)
      case AlertType.AccountClosureTimeout:
        return this.formatMessage(messages.accountClosureTimeoutHeader)
      case AlertType.AuditLogRetrievalError:
        return this.formatMessage(messages.accountAuditLogRetrievalFailedHeader)
      case AlertType.AccessKeysRetrievalError:
        return this.formatMessage(messages.createAccessKeysFailedHeader)
      case AlertType.AccessKeysLimitExceeded:
        return this.formatMessage(messages.accessKeysLimitExceededFailedHeader)
      case AlertType.FederationError:
      default:
        return this.formatMessage(messages.accountFederationFailedHeader)
    }
  }

  private getRecoveryMessage = (
    externalCustomer: boolean,
    key: string,
    href: string
  ) => {
    return externalCustomer
      ? this.formatMessage(messages.generalErrorRecoveryExternal)
      : this.formatMessage(messages.generalErrorInternal, {
          createTicket: (
            <Link key={key} href={href} external>
              {this.formatMessage(messages.createTroubleTicket)}
            </Link>
          )
        })
  }

  private getAlertText = (alertType: AlertType, externalCustomer: boolean) => {
    let failureMessage
    let recoveryMessage

    if (alertType === AlertType.AccountCreationError) {
      failureMessage = this.formatMessage(messages.accountCreationFailedText)
      recoveryMessage = this.formatMessage(
        messages.accountCreationFailedRecovery
      )
    } else if (alertType === AlertType.AccountCreationTimeout) {
      failureMessage = this.formatMessage(messages.accountCreationTimeoutText)
      recoveryMessage = this.getRecoveryMessage(
        externalCustomer,
        "createAccountTicketLink",
        CREATE_ACCOUNT_TICKET_URL
      )
    } else if (alertType === AlertType.AccountClosureError) {
      failureMessage = this.formatMessage(messages.accountClosureFailedText)
      recoveryMessage = this.formatMessage(
        messages.accountClosureFailedRecovery
      )
    } else if (alertType === AlertType.AccountClosureTimeout) {
      failureMessage = this.formatMessage(messages.accountClosureTimeoutText)
      recoveryMessage = this.getRecoveryMessage(
        externalCustomer,
        "closeAccountTicketLink",
        CLOSE_ACCOUNT_TICKET_URL
      )
    } else if (alertType === AlertType.AuditLogRetrievalError) {
      failureMessage = this.formatMessage(
        messages.accountAuditLogRetrievalFailedText
      )
      recoveryMessage = this.getRecoveryMessage(
        externalCustomer,
        "createAuditLogRetrievalTicketLink",
        ACCOUNT_AUDIT_LOG_RETRIEVAL_TICKET_URL
      )
    } else if (alertType === AlertType.AccessKeysRetrievalError) {
      failureMessage = this.formatMessage(messages.createAccessKeysFailedText)
      recoveryMessage = this.getRecoveryMessage(
        externalCustomer,
        "createAccessKeysRetrievalTicketLink",
        ACCOUNT_ACCESS_KEYS_RETRIEVAL_TICKET_URL
      )
    } else if (alertType === AlertType.AccessKeysLimitExceeded) {
      failureMessage = this.formatMessage(
        messages.accessKeysLimitExceededFailedText
      )
      recoveryMessage = this.getRecoveryMessage(
        externalCustomer,
        "accessKeysLimitExceededTicketLink",
        ACCOUNT_ACCESS_KEYS_RETRIEVAL_TICKET_URL
      )
    } else {
      failureMessage = this.formatMessage(messages.accountFederationFailedText)
      recoveryMessage = this.getRecoveryMessage(
        externalCustomer,
        "createAccountFederationTicketLink",
        ACCOUNT_FEDERATION_TICKET_URL
      )
    }

    return (
      <TextContent>
        <p>{failureMessage}</p>
        <p>{recoveryMessage}</p>
      </TextContent>
    )
  }

  private getAlertType = (alertType: AlertType) => {
    if (
      alertType === AlertType.AccountCreationError ||
      alertType === AlertType.AccountClosureError ||
      alertType === AlertType.FederationError ||
      alertType === AlertType.AuditLogRetrievalError ||
      alertType === AlertType.AccessKeysRetrievalError ||
      alertType === AlertType.AccessKeysLimitExceeded
    ) {
      return "error" as const
    }
    return "info" as const
  }

  render() {
    const { alertType, dismissAlert, externalCustomer } = this.props

    return (
      <Alert
        header={this.getAlertHeader(alertType)}
        type={this.getAlertType(alertType)}
        dismissible
        onDismiss={() => dismissAlert()}
        data-testid="accountDetailsAlert"
      >
        {this.getAlertText(alertType, externalCustomer)}
      </Alert>
    )
  }
}

export default injectIntl(AccountDetailsAlert)
