import { defineMessages } from "react-intl"

const messages = defineMessages({
  myEngagementsDocumentTitle: {
    id: "myEngagements.documentTitle",
    defaultMessage: "My engagements"
  },
  myEngagementsBreadCrumb: {
    id: "myEngagements.breadcrumb",
    defaultMessage: "My engagements"
  }
})
export default messages
