export const getInstallersQuery = `query GetInstallers {
  getInstallers {
    InstallerVersion
    Installer
    Bootstrapper
    CollectorInstallGuide
    CollectorOpenSourceSoftwareList
  }
}
`

export const getSelfReportedDataTemplateQuery = `query GetSelfReportedDataTemplate {
  getInstallers {
    CustomerSelfReportedDataTemplate
  }
}
`
