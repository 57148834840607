import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  Link,
  StatusIndicator,
  Flashbar,
  ColumnLayout,
  Form,
  FormField,
  Input,
  InputProps
} from "@amzn/awsui-components-react/polaris"
import { Auth } from "aws-amplify"
import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom"
import AppConfig from "../../Config"
import Constants from "../../Constants"
import commonMessages from "../Common.messages"
import { AuthConfigureCommon } from "./AuthConfigureCommon"
import messages from "./Login.messages"

const { Config } = AppConfig

interface ILoginProps {
  intl: IntlShape
}
interface ILoginState {
  signUp: boolean
  userConfirmationRequired: boolean
  resetPassword: boolean
  resetPasswordRequired: boolean
  challenge: string
  successfullyLoggedIn: boolean
  loading: boolean
  login: ILoginFormState
  mfa: IMfaFormState
  showSuccessfulSignUpMessage: boolean
  user: any
}

interface ILoginFormState {
  email: string
  emailError: string
  password: string
  passwordError: string
  serverSideError: string
  validateOnChange: boolean
}
interface IMfaFormState {
  mfaToken: string
  serverSideError: string
}

export class Login extends React.PureComponent<
  ILoginProps & RouteComponentProps,
  ILoginState
> {
  constructor(props: ILoginProps & RouteComponentProps) {
    super(props)
    this.state = {
      showSuccessfulSignUpMessage: false,
      signUp: false,
      userConfirmationRequired: false,
      resetPassword: false,
      resetPasswordRequired: false,
      challenge: "NONE",
      loading: false,
      successfullyLoggedIn: false,
      login: {
        email: "",
        emailError: "",
        password: "",
        passwordError: "",
        serverSideError: "",
        validateOnChange: false
      },
      mfa: {
        mfaToken: "",
        serverSideError: ""
      },
      user: undefined
    }
  }

  async componentDidMount() {
    const {
      intl: { formatMessage },
      location: { state, search }
    } = this.props
    document.title = formatMessage(messages.loginDocumentTitle)

    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        this.setState({
          successfullyLoggedIn: true,
          loading: false
        })
      }
    } catch (err: any) { // eslint-disable-line
      // suppress
    }

    const locationState = state as any
    if (locationState) {
      if (locationState.successfullySignedUp) {
        this.setState({
          showSuccessfulSignUpMessage: true
        })
      }
    }
    const searchParams = new URLSearchParams(search)
    if (searchParams.has(Constants.SingleSignOnQueryParamName)) {
      if (
        searchParams.get(Constants.SingleSignOnQueryParamName) ===
        Constants.AmazonFederateProviderName
      ) {
        this.onMidwayLogin()
        this.setState({
          loading: true
        })
      }
    }
  }

  onEmailChange = (detail: InputProps.ChangeDetail) => {
    const {
      login: { validateOnChange }
    } = this.state
    this.setState(state => {
      return {
        login: { ...state.login, email: detail.value }
      }
    })
    if (validateOnChange) {
      this.validateLoginInputs()
    }
  }

  onPasswordChange = (detail: InputProps.ChangeDetail) => {
    const {
      login: { validateOnChange }
    } = this.state
    this.setState(state => {
      return {
        login: { ...state.login, password: detail.value }
      }
    })

    if (validateOnChange) {
      this.validateLoginInputs()
    }
  }

  onMfaTokenChange = (detail: InputProps.ChangeDetail) => {
    this.setState(state => {
      return {
        mfa: { ...state.mfa, mfaToken: detail.value }
      }
    })
  }

  validateLoginInputs = (): boolean => {
    const {
      login: { email, password }
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    let emailError: string = ""
    if (!email || email === "") {
      emailError = formatMessage(messages.loginEmailErrorRequired)
    } else if (
      !email.match(/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.)+([a-zA-Z]{2,})$/i)
    ) {
      emailError = formatMessage(messages.loginEmailErrorInvalid)
    }

    let passwordError: string = ""
    if (!password) {
      passwordError = formatMessage(messages.loginPasswordRequired)
    }

    this.setState(state => {
      return {
        login: { ...state.login, emailError, passwordError }
      }
    })

    return emailError === "" && passwordError === ""
  }

  onSignUp = () => {
    this.setState({
      signUp: true
    })
  }

  onResetPassword = () => {
    this.setState({
      resetPassword: true
    })
  }

  onMidwayLogin = async () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    AuthConfigureCommon.useNewPool()
    await Auth.federatedSignIn({
      customProvider: "AmazonFederate"
    })
  }

  loginOnReturn = (event: CustomEvent<InputProps.KeyDetail>) => {
    const {
      detail: { keyCode }
    } = event
    if (keyCode === Constants.ReturnKeyCode) {
      this.onLogin()
    }
  }

  onLogin = async () => {
    this.setState(state => {
      return {
        successfullyLoggedIn: false,
        loading: false,
        login: { ...state.login, validateOnChange: true }
      }
    })

    if (this.validateLoginInputs()) {
      this.setState({
        successfullyLoggedIn: false,
        loading: true
      })

      await this.signIn(false)
    }
  }

  signIn = async (useOldPool: boolean) => {
    const {
      login: { email, password }
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    if (useOldPool) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      AuthConfigureCommon.useOldPool()
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      AuthConfigureCommon.useNewPool()
    }

    try {
      const user = await Auth.signIn(email.toLowerCase(), password)
      if (
        user.challengeName === Constants.Cognito.ChallengeNameNoMfa ||
        user.challengeName === Constants.Cognito.ChallengeNameSoftwareTokenMfa
      ) {
        this.setState({
          challenge: user.challengeName,
          user,
          loading: false
        })
      } else if (
        user.challengeName === Constants.Cognito.ChallengeNameMfaSetup
      ) {
        // eslint-disable-next-line
          console.error("MFA Setup is required")
      } else {
        this.setState({
          successfullyLoggedIn: true,
          loading: false
        })
      }
    } catch (err: any) { // eslint-disable-line
      let errorMessage: string
      switch (err.code) {
        case "InvalidParameterException":
          errorMessage = formatMessage(messages.loginErrorNotAuthorized)
          break
        case "NotAuthorizedException":
          errorMessage = formatMessage(messages.loginErrorNotAuthorized)
          break
        case "UserNotFoundException":
          if (Config.Features.MultiPoolLogin && !useOldPool) {
            this.signIn(true)
            return
          }
          errorMessage = formatMessage(messages.loginErrorNotAuthorized)
          break
        case "PasswordResetRequiredException":
          this.setState({ resetPasswordRequired: true })
          await Auth.forgotPassword(email.toLowerCase())
          break
        case "UserNotConfirmedException":
          this.setState({ userConfirmationRequired: true })
          break
        default:
          errorMessage = err.code
          // eslint-disable-next-line
            console.error(err)
          break
      }

      this.setState(state => {
        return {
          successfullyLoggedIn: false,
          loading: false,
          login: { ...state.login, serverSideError: errorMessage }
        }
      })
    }
  }

  submitMfaOnReturn = (event: CustomEvent<InputProps.KeyDetail>) => {
    const {
      detail: { keyCode }
    } = event
    if (keyCode === Constants.ReturnKeyCode) {
      this.onSubmitMfa()
    }
  }

  onSubmitMfa = () => {
    const {
      intl: { formatMessage }
    } = this.props
    const {
      user,
      mfa: { mfaToken }
    } = this.state
    Auth.confirmSignIn(user, mfaToken, "SOFTWARE_TOKEN_MFA")
      .then(loggedInUser => {
        this.setState({
          user: loggedInUser,
          successfullyLoggedIn: true,
          loading: false
        })
      })
      .catch(err => {
        let errorMessage: string
        switch (err.code) {
          case "CodeMismatchException":
            errorMessage = formatMessage(messages.loginErrorInvalidMfaToken)
            break
          default:
            errorMessage = err.code
            // eslint-disable-next-line
              console.error(err)
            break
        }
        this.setState(state => {
          return {
            loading: false,
            mfa: { ...state.mfa, serverSideError: errorMessage }
          }
        })
      })
  }

  getVerifyMfaContainer = () => {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      mfa: { mfaToken, serverSideError }
    } = this.state

    return (
      <Container
        header={
          <Header variant="h2">{formatMessage(messages.loginMfaHeader)}</Header>
        }
        footer={
          <Grid gridDefinition={[{ colspan: 6, push: 6 }]}>
            <div>
              <Box float="right">
                <Button
                  id="verifyMfaSubmitButton"
                  variant="primary"
                  onClick={this.onSubmitMfa}
                >
                  {formatMessage(commonMessages.submit)}
                </Button>
              </Box>
            </div>
          </Grid>
        }
      >
        <div>
          <Form id="verifyMfaForm" errorText={serverSideError}>
            <ColumnLayout>
              <FormField
                id="verifyMfaTokenFormField"
                stretch
                label={formatMessage(messages.loginMfaToken)}
                description={formatMessage(messages.loginMfaTokenDescription)}
              >
                <Input
                  id="verifyMfaTokenInput"
                  type="text"
                  value={mfaToken}
                  onChange={({ detail }) => this.onMfaTokenChange(detail)}
                  onKeyDown={this.submitMfaOnReturn}
                  ariaLabelledby={formatMessage(messages.loginMfaToken)}
                />
              </FormField>
              <Box padding={{ bottom: "s" }} />
            </ColumnLayout>
          </Form>
        </div>
      </Container>
    )
  }

  getLoginContainer = () => {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      showSuccessfulSignUpMessage,
      login: { email, emailError, password, passwordError, serverSideError }
    } = this.state

    return (
      <div>
        {showSuccessfulSignUpMessage ? (
          <Flashbar
            id="successfulSignUpMessageFlash"
            className="margin-b-20"
            items={[
              {
                type: "success",
                content: formatMessage(messages.loginSuccessfullySignedUp),
                dismissible: true,
                onDismiss: () =>
                  this.setState({ showSuccessfulSignUpMessage: false })
              }
            ]}
          />
        ) : null}
        <Container
          header={
            <Header variant="h2" data-testid="customerSignInHeader">
              {formatMessage(messages.loginHeader)}
            </Header>
          }
          footer={
            <Grid
              gridDefinition={[
                { colspan: 4, push: 8 },
                { colspan: 8, pull: 4 }
              ]}
            >
              <div>
                <Box float="right">
                  <Button
                    variant="primary"
                    onClick={this.onLogin}
                    data-testid="customerSignInBtn"
                  >
                    {formatMessage(messages.loginLogin)}
                  </Button>
                </Box>
              </div>
              <div>
                <Box float="left">
                  <Button
                    id="signUpButton"
                    variant="link"
                    onClick={this.onSignUp}
                  >
                    {formatMessage(messages.loginSignUp)}
                  </Button>
                </Box>
              </div>
            </Grid>
          }
        >
          <div>
            <Form
              id="loginForm"
              errorText={
                serverSideError && (
                  <Box data-testid="loginServerError">{serverSideError}</Box>
                )
              }
            >
              <ColumnLayout>
                <FormField
                  id="loginEmailFormField"
                  stretch
                  errorText={emailError}
                  label={formatMessage(messages.loginEmail)}
                >
                  <Input
                    id="loginEmailInput"
                    type="email"
                    autoFocus
                    value={email}
                    onChange={({ detail }) => this.onEmailChange(detail)}
                    ariaLabelledby={formatMessage(messages.loginEmail)}
                    data-testid="customerEmailTbx"
                  />
                </FormField>
                <FormField
                  id="loginPasswordFormField"
                  stretch
                  errorText={passwordError}
                  label={formatMessage(messages.loginPassword)}
                >
                  <Input
                    id="loginPasswordInput"
                    type="password"
                    value={password}
                    onChange={({ detail }) => this.onPasswordChange(detail)}
                    onKeyDown={this.loginOnReturn}
                    ariaLabelledby={formatMessage(messages.loginPassword)}
                    data-testid="customerPasswordTbx"
                  />
                  <Box float="right">
                    <Link href={`/${Constants.PathName.ResetPassword}`}>
                      {formatMessage(messages.loginResetPassword)}
                    </Link>
                  </Box>
                </FormField>
                <Box padding={{ bottom: "s" }} />
              </ColumnLayout>
            </Form>
          </div>
        </Container>
      </div>
    )
  }

  getFederateLoginContainer = () => {
    const {
      intl: { formatMessage }
    } = this.props

    return (
      <div>
        <Container
          header={
            <Header variant="h2">
              {formatMessage(messages.federateLoginHeader)}
            </Header>
          }
          footer={formatMessage(messages.MidwayLoginAmazonEmployeeHint)}
        >
          <Grid>
            <Box float="left">
              <Button
                variant="normal"
                onClick={this.onMidwayLogin}
                data-testid="midwayLogInBtn"
              >
                {formatMessage(messages.MidwayLogin)}
              </Button>
            </Box>
          </Grid>
        </Container>
      </div>
    )
  }

  wrapInAwsUiGrid = (innerContent: any): any => {
    return (
      <div className="awsui">
        <Box padding={{ top: "xxxl" }}>
          <Grid
            gridDefinition={[
              {
                colspan: { default: 10, m: 4 },
                push: { default: 1, m: 4 }
              }
            ]}
          >
            <div>{innerContent}</div>
          </Grid>
        </Box>
      </div>
    )
  }

  render() {
    const {
      challenge,
      successfullyLoggedIn,
      signUp,
      resetPassword,
      resetPasswordRequired,
      userConfirmationRequired,
      loading,
      login: { email }
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    if (loading) {
      return (
        <div className="awsui text-center">
          <StatusIndicator type="loading">
            {formatMessage(commonMessages.loading)}
          </StatusIndicator>
        </div>
      )
    }

    if (successfullyLoggedIn) {
      return <Redirect to="/" />
    }

    if (resetPassword) {
      return <Redirect push to={`/${Constants.PathName.ResetPassword}`} />
    }

    if (signUp) {
      return <Redirect push to={`/${Constants.PathName.SignUp}`} />
    }

    if (resetPasswordRequired) {
      return (
        <Redirect
          push
          to={{
            pathname: `/${Constants.PathName.ResetPassword}`,
            state: { codeSent: true, userName: email }
          }}
        />
      )
    }

    if (userConfirmationRequired) {
      return (
        <Redirect
          push
          to={{
            pathname: `/${Constants.PathName.SignUp}`,
            state: { verifyEmail: true, userName: email }
          }}
        />
      )
    }

    switch (challenge) {
      case "NONE":
        return (
          <>
            {this.wrapInAwsUiGrid(this.getLoginContainer())}
            {Config.Features.MidwayAuthentication
              ? this.wrapInAwsUiGrid(this.getFederateLoginContainer())
              : null}
          </>
        )
      case "SOFTWARE_TOKEN_MFA":
        return this.wrapInAwsUiGrid(this.getVerifyMfaContainer())
      default:
        return this.wrapInAwsUiGrid(
          <div>{challenge} currently not supported</div>
        )
    }
  }
}

export default injectIntl(withRouter(Login))
