import { defineMessages } from "react-intl"

const messages = defineMessages({
  loginDocumentTitle: {
    id: "login.documentTitle",
    defaultMessage: "Sign in"
  },
  loginHeader: {
    id: "login.header",
    defaultMessage: "Migration Evaluator (formerly TSO Logic) - sign in"
  },
  loginResetPassword: {
    id: "login.resetPassword",
    defaultMessage: "Forgot password?"
  },
  loginEmail: {
    id: "login.email",
    defaultMessage: "Email"
  },
  loginEmailErrorRequired: {
    id: "login.emailErrorRequired",
    defaultMessage: "Email cannot be empty"
  },
  loginEmailErrorInvalid: {
    id: "login.emailErrorInvalid",
    defaultMessage: "Not a valid email"
  },
  loginPassword: {
    id: "login.password",
    defaultMessage: "Password"
  },
  loginPasswordRequired: {
    id: "login.passwordErrorRequired",
    defaultMessage: "Password cannot be empty"
  },
  loginLogin: {
    id: "login.login",
    defaultMessage: "Sign in"
  },
  loginSignUp: {
    id: "login.signUp",
    defaultMessage: "Don't have an account? Sign up"
  },
  federateLoginHeader: {
    id: "login.federatelogin",
    defaultMessage: "Amazon employees - sign in"
  },
  MidwayLogin: {
    id: "login.midwayLogin",
    defaultMessage: "Log in using Midway"
  },
  MidwayLoginAmazonEmployeeHint: {
    id: "login.midwayAmazonEmployeeHint",
    defaultMessage: "Amazon employees may use Midway for sign in"
  },
  loginErrorNotAuthorized: {
    id: "login.error.notAuthorized",
    defaultMessage: "Incorrect username or password"
  },
  loginMfaHeader: {
    id: "login.mfaHeader",
    defaultMessage: "Multi-factor authentication"
  },
  loginMfaToken: {
    id: "login.mfaToken",
    defaultMessage: "MFA token"
  },
  loginMfaTokenDescription: {
    id: "login.mfaTokenDescription",
    defaultMessage: "Enter one-time password"
  },
  loginErrorInvalidMfaToken: {
    id: "login.error.invalidMfaToken",
    defaultMessage: "Invalid MFA token; try again."
  },
  loginSuccessfullySignedUp: {
    id: "login.successfullySignedUp",
    defaultMessage:
      "Sign up was successful; sign in with your email and password."
  }
})

export default messages
