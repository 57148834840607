import { defineMessages } from "react-intl"

const messages = defineMessages({
  privacy: {
    id: "footer.privacy",
    defaultMessage: "Privacy"
  },
  siteTerms: {
    id: "footer.siteTerms",
    defaultMessage: "Site terms"
  },
  cookiePreferences: {
    id: "footer.cookiePreferences",
    defaultMessage: "Cookie preferences"
  },
  copyrightNotice: {
    id: "footer.copyrightNotice",
    defaultMessage:
      "© {currentYear}, Amazon Web Services, Inc. or its affiliates. All rights reserved."
  }
})

export default messages
