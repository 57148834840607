import { defineMessages } from "react-intl"

const messages = defineMessages({
  signUpDocumentTitle: {
    id: "signUp.documentTitle",
    defaultMessage: "Sign up"
  },
  signUpHeader: {
    id: "signUp.header",
    defaultMessage: "Migration Evaluator (formerly TSO Logic) - sign up"
  },
  signUpEmail: {
    id: "signUp.email",
    defaultMessage: "Sign up with your email"
  },
  signUpEmailDescription: {
    id: "signUp.emailDescription",
    defaultMessage:
      "Enter your email address on which you received invitation to sign up"
  },
  signUpEmailErrorRequired: {
    id: "signUp.emailErrorRequired",
    defaultMessage: "Email cannot be empty"
  },
  signUpEmailVerificationErrorRequired: {
    id: "signUp.emailVerificationErrorRequired",
    defaultMessage: "Email verification code cannot be empty"
  },
  signUpEmailErrorInvalid: {
    id: "signUp.emailErrorInvalid",
    defaultMessage: "Not a valid email"
  },
  signUpPassword: {
    id: "signUp.password",
    defaultMessage: "Choose a password"
  },
  signUpShowPassword: {
    id: "signUp.showPassword",
    defaultMessage: "Show password"
  },
  signUpPasswordDescription: {
    id: "signUp.passwordDescription",
    defaultMessage:
      "Must be at least 8 characters long and contain at least 1: uppercase, lowercase, number, and special character"
  },
  signUpPasswordRequired: {
    id: "signUp.passwordErrorRequired",
    defaultMessage: "Password cannot be empty"
  },
  signUpTermsAndConditions: {
    id: "signUp.termsAndConditions",
    defaultMessage:
      "Click here to indicate that you have read and agree to the terms of the"
  },
  signUpTermsAndConditionsLink: {
    id: "signUp.termsAndConditionsLink",
    defaultMessage: "AWS customer agreement"
  },
  signUpSignIn: {
    id: "signUp.signIn",
    defaultMessage: "Already registered? Sign in"
  },
  signUpSignUp: {
    id: "signUp.signUp",
    defaultMessage: "Sign up"
  },
  signUpResendCode: {
    id: "signUp.resendCode",
    defaultMessage: "Resend code"
  },
  signUpEmailVerificationHeader: {
    id: "signUp.emailVerificationHeader",
    defaultMessage: "Verify email"
  },
  signUpEmailVerificationCodeSent: {
    id: "signUp.emailVerificationCodeSent",
    defaultMessage: "An email with verification code has been sent to {email}"
  },
  signUpEmailVerificationEmailMayTakeTime: {
    id: "signUp.emailVerificationEmailMayTakeTime",
    defaultMessage: "NOTE: it may take up to 30 minutes to receive the email."
  },
  signUpEmailVerificationCode: {
    id: "signUp.emailVerificationCode",
    defaultMessage: "Verification code"
  },
  signUpEmailVerificationCodeDescription: {
    id: "signUp.emailVerificationCodeDescription",
    defaultMessage:
      "Enter the verification code that you received in your email"
  },
  signUpErrorInvalidVerificationCode: {
    id: "signUp.error.invalidVerificationCode",
    defaultMessage: "Invalid verification code."
  },
  signUpErrorInvalidPassword: {
    id: "signUp.error.invalidPassword",
    defaultMessage: "Password does not meet complexity requirements"
  },
  signUpErrorUserWithEmailAlreadyExists: {
    id: "signUp.error.userWithEmailAlreadyExists",
    defaultMessage:
      "A user with this email already exists. If you've deleted your account, reach out to your account manager to request an account restoration."
  },
  signUpErrorEmailNotPermitted: {
    id: "signUp.error.emailNotPermitted",
    defaultMessage:
      "This email is not permitted to sign up. You need an invitation to be able to sign up."
  },
  signUpMessagePreventUserFromLeavingWihtoutVerifyingEmail: {
    id: "signUp.messagePreventUserFromLeavingWithoutVerifyingEmail",
    defaultMessage:
      "Are you sure you want to leave without verifying email? You will not be able to login or reset your password with unverified email."
  },
  signUpDisclaimer: {
    id: "signUp.disclaimer",
    defaultMessage:
      "By signing up, you are authorizing an AWS solutions architect to access your data in order to provide the service to you."
  }
})

export default messages
