import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextContent,
  Alert,
  Icon,
  Spinner
} from "@amzn/awsui-components-react/polaris"
import { faServer } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { API, graphqlOperation } from "aws-amplify"
import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom"

import Config from "../../Config"
import Constants from "../../Constants"
import commonMessages from "../Common.messages"
import { getInstallersQuery } from "./graphql/queries"
import messages from "./OnPremCollectorInstaller.messages"

const AppConfig = Config.Config

interface IOnPremCollectorInstallerProps {
  intl: IntlShape
}
interface IOnPremCollectorInstallerState {
  redirectToOnPremDataCollectors: boolean
  collectorInstallers: ICollectorInstallers
  errorExist: boolean
}

interface ICollectorInstallers {
  InstallerVersion: string
  Installer: string
  Bootstrapper: string
  CollectorInstallGuide: string
  CollectorOpenSourceSoftwareList: string
}

export class OnPremCollectorInstaller extends React.Component<
  RouteComponentProps & IOnPremCollectorInstallerProps,
  IOnPremCollectorInstallerState
> {
  willUnmount: boolean = false

  constructor(props: RouteComponentProps & IOnPremCollectorInstallerProps) {
    super(props)
    this.state = {
      redirectToOnPremDataCollectors: false,
      collectorInstallers: {
        InstallerVersion: "",
        Installer: "",
        Bootstrapper: "",
        CollectorInstallGuide: "",
        CollectorOpenSourceSoftwareList: ""
      },
      errorExist: false
    }
  }

  async componentDidMount() {
    await this.getInstallerLinks()
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  getInstallerLinks = async () => {
    try {
      this.setState({ errorExist: false })
      const response = await API.graphql(graphqlOperation(getInstallersQuery))

      const responseData = response as {
        data: { getInstallers: ICollectorInstallers }
      }
      if (!this.willUnmount) {
        this.setState({
          collectorInstallers: responseData.data.getInstallers
        })
      }
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      this.setState({ errorExist: true })
    }
  }

  redirectToOnPremDataCollectors = () => {
    this.setState({ redirectToOnPremDataCollectors: true })
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      redirectToOnPremDataCollectors,
      collectorInstallers: {
        InstallerVersion,
        Bootstrapper,
        Installer,
        CollectorInstallGuide,
        CollectorOpenSourceSoftwareList
      },
      errorExist
    } = this.state

    if (redirectToOnPremDataCollectors) {
      return <Redirect push to={Constants.PathName.DiscoverCollectors} />
    }

    let MEDocumentationPageLink = (
      <Box variant="span" color="text-status-inactive">
        {formatMessage(
          messages.onPremiseDataCollectorDownloadAdditionalLanguagesInstallGuide
        )}
        &nbsp;
        <Icon
          id="MEDocumentationPageLinkIconDisabled"
          variant="disabled"
          name="external"
        />
      </Box>
    )
    if (CollectorInstallGuide !== "") {
      MEDocumentationPageLink = (
        <Link
          data-testid="collectorAdditionalLanguagesInstallGuide"
          href={AppConfig.Support.MigrationEvaluatorDocumentationPageAddress}
          target="_blank"
          rel="noopener noreferrer"
        >
          {formatMessage(
            messages.onPremiseDataCollectorDownloadAdditionalLanguagesInstallGuide
          )}
          &nbsp;
          <Icon
            id="MEDocumentationPageLinkIconEnabled"
            variant="link"
            name="external"
          />
        </Link>
      )
    }

    let ThirdPartyLicensesLink = (
      <Box variant="span" color="text-status-inactive">
        {formatMessage(messages.onPremiseDataCollectorOpenSourceSoftwareLink)}
        &nbsp;
        <Icon
          id="thirdPartyLicenseLinkIconDisabled"
          variant="disabled"
          name="external"
        />
      </Box>
    )
    if (CollectorOpenSourceSoftwareList !== "") {
      ThirdPartyLicensesLink = (
        <Link
          data-testid="openSourceSoftwareList"
          href={CollectorOpenSourceSoftwareList}
          target="_blank"
          rel="noopener noreferrer"
        >
          {formatMessage(messages.onPremiseDataCollectorOpenSourceSoftwareLink)}
          &nbsp;
          <Icon
            id="thirdPartyLicenseLinkIconEnabled"
            variant="link"
            name="external"
          />
        </Link>
      )
    }

    return (
      <>
        <Alert
          id="serverError"
          header={formatMessage(commonMessages.errorHeader)}
          type="error"
          dismissible
          visible={errorExist}
          onDismiss={() => this.setState({ errorExist: false })}
          dismissAriaLabel="Close alert"
        >
          {formatMessage(commonMessages.error)}
        </Alert>
        <Container
          header={
            <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
              <Box fontSize="heading-m">
                <FontAwesomeIcon size="lg" icon={faServer} />
                &nbsp; {formatMessage(messages.onPremiseDataCollectorTitle)}
              </Box>
              <Box textAlign="right">
                {InstallerVersion === "" ? (
                  <Spinner id="installersLoading" />
                ) : (
                  <Box
                    data-testid="installerVersion"
                    fontSize="body-m"
                    color="text-status-inactive"
                  >
                    {InstallerVersion}
                  </Box>
                )}
              </Box>
            </Grid>
          }
          footer={
            <div>
              {formatMessage(
                messages.onPremiseDataCollectorOpenSourceSoftwareDescription
              )}
              &nbsp;
              {ThirdPartyLicensesLink}
            </div>
          }
        >
          <div>
            <Box variant="p" padding={{ vertical: "xl" }}>
              {formatMessage(
                messages.onPremiseDataCollectorInstallationInstructionsSummary
              )}
            </Box>
            <TextContent>
              <ol>
                <li>
                  <Button
                    data-testid="collectorInstallGuide"
                    className={
                      CollectorInstallGuide !== "" ? "button-link" : ""
                    }
                    variant="link"
                    iconName="download"
                    disabled={CollectorInstallGuide === ""}
                    target="_blank"
                    href={CollectorInstallGuide}
                  >
                    {formatMessage(
                      messages.onPremiseDataCollectorDownloadInstallGuide
                    )}
                  </Button>
                  <Box margin={{ left: "m" }}>
                    {formatMessage(
                      messages.onPremiseDataCollectorDownloadAdditionalLanguagesInstallGuideDescription
                    )}
                    {MEDocumentationPageLink}
                  </Box>
                </li>
                <li>
                  <Button
                    data-testid="collectorBootstrapper"
                    className={Bootstrapper !== "" ? "button-link" : ""}
                    variant="link"
                    iconName="download"
                    disabled={Bootstrapper === ""}
                    target="_blank"
                    href={Bootstrapper}
                  >
                    {formatMessage(
                      messages.onPremiseDataCollectorDownload3rdPartySoftwareInstaller
                    )}
                  </Button>
                </li>
                <li>
                  <Button
                    data-testid="collectorInstaller"
                    className={Installer !== "" ? "button-link" : ""}
                    variant="link"
                    iconName="download"
                    disabled={Installer === ""}
                    target="_blank"
                    href={Installer}
                  >
                    {formatMessage(
                      messages.onPremiseDataCollectorDownloadCollector
                    )}
                  </Button>
                </li>
                <li>
                  <Box margin={{ left: "m" }}>
                    {formatMessage(messages.onPremiseDataCollectorCreateVm)}
                  </Box>
                </li>
                <li>
                  <Box margin={{ left: "m" }}>
                    {formatMessage(
                      messages.onPremiseDataCollectorFollowInstallGuide
                    )}
                  </Box>
                </li>
              </ol>
            </TextContent>
          </div>
          <Box padding={{ vertical: "xl" }} />
          <Grid gridDefinition={[{ colspan: { xxs: 6 } }]}>
            <Box float="left" padding={{ bottom: "xl" }}>
              <Button
                data-testid="redirectOnPremDataCollectorsButton"
                variant="primary"
                onClick={this.redirectToOnPremDataCollectors}
              >
                {formatMessage(
                  messages.onPremiseDataCollectorViewAndConfigureCollector
                )}
              </Button>
            </Box>
            <br />
          </Grid>
        </Container>
      </>
    )
  }
}

export default injectIntl(withRouter(OnPremCollectorInstaller))
