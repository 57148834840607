/* eslint react/jsx-props-no-spreading: "off" */

import React from "react"
import { Route, RouteProps, Redirect } from "react-router-dom"
import {
  Box,
  StatusIndicator,
  Spinner
} from "@amzn/awsui-components-react/polaris"
import { Auth } from "aws-amplify"
import Constants from "../../Constants"

interface IProtectedRouteProps {
  enabled: boolean
}
export class ProtectedRoute extends Route<IProtectedRouteProps & RouteProps> {
  constructor(props: IProtectedRouteProps & RouteProps) {
    super(props)
    this.state = {
      loading: true,
      isAuthenticated: false
    }
  }

  willUnmount: boolean = false

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        if (!this.willUnmount) {
          this.setState({
            loading: false,
            isAuthenticated: true
          })
        }
      })
      .catch(() => {
        if (!this.willUnmount) {
          this.setState({
            loading: false,
            isAuthenticated: false
          })
        }
      })
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  render() {
    const { enabled } = this.props
    const { loading, isAuthenticated } = this.state

    if (enabled === false) {
      return (
        <Box
          padding="xxxl"
          margin="xxxl"
          color="text-status-inactive"
          textAlign="center"
        >
          Feature not available
        </Box>
      )
    }
    if (loading) {
      return (
        <Box textAlign="center" padding="xxxl" margin="xxxl">
          <span>
            <Spinner />
            <StatusIndicator type="loading">Loading</StatusIndicator>
          </span>
        </Box>
      )
    }

    if (isAuthenticated) {
      return <Route {...this.props} />
    }
    return <Redirect to={{ pathname: `/${Constants.PathName.SignIn}` }} />
  }
}

export default ProtectedRoute
