import { Auth } from "aws-amplify"
import AppConfig from "../../Config"

const { Config } = AppConfig

export class AuthConfigureCommon {
  static useNewPool() {
    Auth.configure({
      region: Config.Cognito.Region,
      userPoolId: Config.Cognito.UserPoolId,
      userPoolWebClientId: Config.Cognito.AppClientId,
      oauth: {
        domain: Config.Cognito.Oauth.domain,
        scope: Config.Cognito.Oauth.scope,
        redirectSignIn: Config.Cognito.Oauth.redirectSignIn,
        redirectSignOut: Config.Cognito.Oauth.redirectSignOut,
        responseType: Config.Cognito.Oauth.responseType
      }
    })
  }

  static useOldPool() {
    Auth.configure({
      region: Config.CognitoOld.Region,
      userPoolId: Config.CognitoOld.UserPoolId,
      userPoolWebClientId: Config.CognitoOld.AppClientId,
      oauth: {
        domain: Config.CognitoOld.Oauth.domain,
        scope: Config.CognitoOld.Oauth.scope,
        redirectSignIn: Config.CognitoOld.Oauth.redirectSignIn,
        redirectSignOut: Config.CognitoOld.Oauth.redirectSignOut,
        responseType: Config.CognitoOld.Oauth.responseType
      }
    })
  }
}

export default AuthConfigureCommon
