/* eslint import/named: "off" */

import { config as faConfig } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { Amplify, Auth } from "aws-amplify"
import React from "react"
import { IntlProvider } from "react-intl"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom"
import DynamicConfig from "../Config"
import Constants from "../Constants"
import enMessages from "../i18n/locales/en.json"
import {
  polyfillNumberFormat,
  polyfillPluralRules,
  polyfillRelativeTimeFormat
} from "../lib/intl/IntlPolyfillsHelper"
import "./App.css"
import Login from "./auth/Login"
import ProtectedRoute from "./auth/ProtectedRoute"
import ResetPassword from "./auth/ResetPassword"
import SignUp from "./auth/SignUp"
import SingleSignOnCallbackHandler from "./auth/SingleSignOnCallbackHandler"
import Footer from "./footer/Footer"
import Header from "./header/Header"
import EmsAppLayout from "./layout/EmsAppLayout"
import "./Shortbread.css"
import "@amzn/awsui-global-styles/polaris.css"

faConfig.autoAddCss = false
const AppConfig = DynamicConfig.Config

// https://aws-amplify.github.io/docs/js/authentication
Amplify.configure({
  Auth: {
    region: AppConfig.Cognito.Region,
    userPoolId: AppConfig.Cognito.UserPoolId,
    userPoolWebClientId: AppConfig.Cognito.AppClientId,
    oauth: {
      domain: AppConfig.Cognito.Oauth.domain,
      scope: AppConfig.Cognito.Oauth.scope,
      redirectSignIn: AppConfig.Cognito.Oauth.redirectSignIn,
      redirectSignOut: AppConfig.Cognito.Oauth.redirectSignOut,
      responseType: AppConfig.Cognito.Oauth.responseType
    }
  },
  API: {
    aws_appsync_graphqlEndpoint: AppConfig.AppSync.GraphQLEndPoint,
    aws_appsync_region: AppConfig.AppSync.Region,
    aws_appsync_authenticationType: AppConfig.AppSync.AuthenticationType,
    graphql_headers: async () => ({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
    })
  }
})

const messages = {
  en: enMessages
  //  'es': esMessages
}

// const language = navigator.language.split(/[-_]/)[0];
const language = "en"

const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

polyfillPluralRules()
polyfillRelativeTimeFormat()
polyfillNumberFormat()

const App: React.FC = () => (
  <IntlProvider
    locale={language}
    messages={messages[language]}
    timeZone={timeZone}
  >
    <Router>
      <Switch>
        <Route path={`/${Constants.PathName.SignIn}`}>
          <Login />
        </Route>
        <Route path={`/${Constants.PathName.SingleSignOnCallback}`}>
          <SingleSignOnCallbackHandler />
        </Route>
        <Route path={`/${Constants.PathName.SignUp}`}>
          <SignUp />
        </Route>
        <Route path={`/${Constants.PathName.ResetPassword}`}>
          <ResetPassword />
        </Route>
        <ProtectedRoute
          enabled
          path={[
            `/${Constants.PathName.Discover}`,
            `/${Constants.PathName.Dashboard}`
          ]}
        >
          <Header />
          <EmsAppLayout />
          <Footer />
        </ProtectedRoute>
        <Route path="/">
          {AppConfig.Features.Dashboard ? (
            <Redirect to={`/${Constants.PathName.Dashboard}`} />
          ) : (
            <Redirect to={`/${Constants.PathName.Discover}`} />
          )}
        </Route>
      </Switch>
    </Router>
  </IntlProvider>
)

export default App
