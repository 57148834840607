import { defineMessages } from "react-intl"

const messages = defineMessages({
  header: {
    id: "summaryReport.header",
    defaultMessage: "Quick Insights - {engagementName}"
  },
  reportTitle: {
    id: "summaryReport.reportTitle",
    defaultMessage: "Quick Insights"
  },
  engagementSubtitle: {
    id: "summaryReport.engagementSubtitle",
    defaultMessage: "Engagement: {engagementName}"
  },
  dateSubtitle: {
    id: "summaryReport.dateSubtitle",
    defaultMessage: "Generated: {date}"
  },
  directMatchCostText: {
    id: "summaryReport.directMatchCostText",
    defaultMessage:
      "The migration of workloads to AWS, as provisioned on-premises, would result in an estimated annual operational cost of {annualCost}. Once more than 48 hours of monitoring has completed, a project cost factoring in the actual usage patterns will be presented."
  },
  rightSizedCostText: {
    id: "summaryReport.rightSizedCostText",
    defaultMessage:
      "Rightsizing workloads on AWS would result in an estimated annual cost of ##{annualCost} *## for Amazon Elastic Cloud Compute (EC2) and Elastic Block Storage (EBS)."
  },
  rightSizedCostWithLicenseText: {
    id: "summaryReport.rightSizedCostWithLicenseText",
    defaultMessage:
      "Rightsizing workloads on AWS would result in an estimated annual cost of ##{annualCost} *## for Amazon Elastic Cloud Compute (EC2), Elastic Block Storage (EBS) and corresponding operating system licenses."
  },
  rightSizedSavingText: {
    id: "summaryReport.rightSizedSavingText",
    defaultMessage:
      "Based on your reported CPU and memory utilization, you could realize a ##{percentSavings}% savings **## compared to directly mapping your on-premises servers and storage."
  },
  microsoftLicensingRightSizedCostText: {
    id: "summaryReport.microsoftLicensingCostText",
    defaultMessage:
      "Electing to repurchase nonoptimized operating system licensing from AWS would add ##{licenseAnnualCost} *## to the Amazon EC2 and EBS costs shown above."
  },
  rightSizedBenefitText: {
    id: "summaryReport.rightSizeBenefitText",
    defaultMessage:
      "With AWS, you have access to more instances in every imaginable shape and size than you'll find elsewhere and we continue to add more so you can always find the right size based on your current needs."
  },
  nextStepsWithUtilityText: {
    id: "summaryReport.nextStepsWithUtilityText",
    defaultMessage:
      "If you would like to learn more about migrating workloads to AWS including software license optimization and exploring managed services, please contact your ##AWS account team## or email ##migration-evaluator@amazon.com##."
  },
  nextStepsWithoutUtilityText: {
    id: "summaryReport.nextStepsWithoutUtilityText",
    defaultMessage:
      "Come back in a few days once your utilization patterns have been captured and your projected rightsizing on AWS has been calculated."
  },
  microsoftLicensingDirectMatchCostText: {
    id: "summaryReport.microsoftLicensingDirectMatchCostText",
    defaultMessage:
      "Microsoft licensing accounts for {percentCost}% of projected direct match costs."
  },
  microsoftLicensingCostText: {
    id: "summaryReport.microsoftLicensingDirectMatchCostText",
    defaultMessage:
      "Microsoft licensing accounts for {percentCost}% of projected direct match costs."
  },
  additionalCostReductionAnalysisText: {
    id: "summaryReport.additionalCostReductionAnalysisText",
    defaultMessage:
      "With supplementary information on existing software license entitlements and production versus non-production workloads, a detailed analysis can be completed. This would include: retiring workloads no longer needed, reusing existing software licenses on either physical or virtual cores, leveraging different AWS regions, and using on-demand products for non-production workloads."
  },
  annualCostLabel: {
    id: "summaryReport.annualCostLabel",
    defaultMessage: "Annual cost ($)"
  },
  directMatchLabel: {
    id: "summaryReport.directMatchLabel",
    defaultMessage: "Direct match"
  },
  rightSizedLabel: {
    id: "summaryReport.rightSizedLabel",
    defaultMessage: "Rightsized"
  },
  awsEc2Label: {
    id: "summaryReport.awsEc2Label",
    defaultMessage: "Amazon EC2"
  },
  awsEbsLabel: {
    id: "summaryReport.awsEbsLabel",
    defaultMessage: "Amazon EBS"
  },
  microsoftLicensesLabel: {
    id: "summaryReport.microsoftLicensesLabel",
    defaultMessage: "OS licenses"
  },
  aboutReportTitle: {
    id: "summaryReport.aboutReportTitle",
    defaultMessage: "About this report"
  },
  analysisTimePeriodText: {
    id: "summaryReport.analysisTimePeriodText",
    defaultMessage:
      "The analysis is based on infrastructure, software licenses and utilization discovered from {startDate} to {endDate}."
  },
  analysisTimePointText: {
    id: "summaryReport.analysisTimePointText",
    defaultMessage:
      "The analysis is based on infrastructure, software licenses and utilization provided."
  },
  servers: {
    id: "summaryReport.servers",
    defaultMessage: "Servers"
  },
  licensing: {
    id: "summaryReport.licensing",
    defaultMessage: "Licensing"
  },
  storage: {
    id: "summaryReport.storage",
    defaultMessage: "Storage"
  },
  utilization: {
    id: "summaryReport.utilization",
    defaultMessage: "Utilization"
  },
  virtualMachines: {
    id: "summaryReport.virtualMachines",
    defaultMessage: "{count} virtual machines"
  },
  physicalServers: {
    id: "summaryReport.physicalServers",
    defaultMessage: "{count} physical servers"
  },
  windowsServers: {
    id: "summaryReport.windowsServers",
    defaultMessage: "{count} servers running Windows Server"
  },
  operatingSystemServers: {
    id: "summaryReport.operatingSystemServers",
    defaultMessage: "{totalCount} servers {components}"
  },
  sqlServers: {
    id: "summaryReport.sqlServers",
    defaultMessage: "{totalCount} servers running SQL Server {components}"
  },
  attachedBlockStorage: {
    id: "summaryReport.attachedBlockStorage",
    defaultMessage: "{size} of attached block storage"
  },
  peakCpuUtilization: {
    id: "summaryReport.peakCpuUtilization",
    defaultMessage: "{percent} peak CPU utilization***"
  },
  peakMemoryUtilization: {
    id: "summaryReport.peakMemoryUtilization",
    defaultMessage: "{percent} peak memory utilization***"
  },
  projectedCostsFooter: {
    id: "summaryReport.projectedCostsFooter",
    defaultMessage:
      "* Projected AWS costs based on public {purchaseType} Instance Savings Plan USD pricing for Amazon EC2 and Amazon EBS running in {region} with bring your own SQL Server licenses {hasSoftwareAssurance, select, true {with} other {without}} active Software Assurance. This report provides an estimate of fees and savings based on certain information you provide. Fee estimates do not include any taxes that might apply. Your actual fees and savings depend on a variety of factors, including your actual usage of AWS services, which may vary from the estimates provided in this report. Additional configurations are available on request."
  },
  projectCollectorHintFooter: {
    id: "summaryReport.projectCollectorHintFooter",
    defaultMessage:
      "** Projected savings based on utilization data available to date compared to a like-for-like match of on-premises CPU and RAM specifications. A longer collection period will improve rightsizing confidence."
  },
  projectCollectorHintNonTimeSeriesFooter: {
    id: "summaryReport.projectCollectorHintNonTimeSeriesFooter",
    defaultMessage:
      "** Projected savings based on utilization data available to date compared to a like-for-like match of on-premises CPU and RAM specifications. Leveraging an on-premises collector will improve rightsizing confidence."
  },
  projectCollectorHintTimeSeriesNoUtilizationFooter: {
    id: "summaryReport.projectCollectorHintTimeSeriesNoUtilizationFooter",
    defaultMessage:
      "** On average, AWS customers see a projected savings of 36% through utilization based rightsizing."
  },
  utilizationHintFooter: {
    id: "summaryReport.utilizationHintFooter",
    defaultMessage: "*** The average P95 utilization value from all servers."
  },
  engagementNameFooter: {
    id: "summaryReport.engagementNameFooter",
    defaultMessage: "Engagement: {engagementName}"
  },
  copyRightFooter: {
    id: "summaryReport.copyRightFooter",
    defaultMessage:
      "\u00A9 {currentYear}, Amazon Web Services, Inc. or its affiliates."
  },
  featureIntroPart1: {
    id: "summaryReport.featureIntro1",
    defaultMessage:
      "Migration Evaluator Quick Insights projects the cost of running existing workloads in the AWS cloud. The report summarizes the servers included in the analysis, the potential saving by rightsizing to better match measured usage patterns, and projects costs by infrastructure versus software licenses."
  },
  featureIntroPart2: {
    id: "summaryReport.featureIntro2",
    defaultMessage:
      "For customers leveraging Migration Evaluator Collector and have enabled synchronization via Amazon S3, the report will be updated daily. For customers manually providing exports from 3rd party tooling or the Migration Evaluator Collector, the report will be updated once the data has been successfully imported."
  },
  featureFooter1: {
    id: "summaryReport.featureFooter1",
    defaultMessage:
      "Quick Insights provides an estimate of fees and savings based on certain information you provide. Fee estimates do not include any taxes that might apply. Your actual fees and savings depend on a variety of factors, including your actual usage of AWS services, which may vary from the estimates provided in this report."
  },
  downloadPdf: {
    id: "summaryReport.downloadPdf",
    defaultMessage: "Download summary (PDF)"
  },
  downloadDetailedReport: {
    id: "summaryReport.downloadDetailedReport",
    defaultMessage: "Download details"
  },
  noReportHint: {
    id: "summaryReport.noReportHint",
    defaultMessage: "You do not have a report yet"
  },
  lastUpdateHint: {
    id: "summaryReport.lastUpdateHint",
    defaultMessage: "Last updated on {date}"
  },
  detailedExportStandardFormatLabel: {
    id: "summaryReport.detailedExportStandardFormatLabel",
    defaultMessage: "Standard format"
  },
  detailedExportMpaFormatLabel: {
    id: "summaryReport.detailedExportMpaFormatLabel",
    defaultMessage: "For use with MPA"
  },
  detailedExportPhysicalFormatLabel: {
    id: "summaryReport.detailedExportPhysicalFormatLabel",
    defaultMessage: "Physical server report"
  },
  quickInsightsDetailedStandardHint: {
    id: "summaryReport.quickInsightsStandardHint",
    defaultMessage:
      "The {bolded} report contains all data collected and imported."
  },
  quickInsightsDetailedMpaHint: {
    id: "summaryReport.quickInsightsMpaHint",
    defaultMessage:
      "The Migration Portfolio Assessment ({bolded}) report contains all data collected and imported, formatted specifically for use with the ProServe MPA tool to be used by AWS and partners."
  },
  quickInsightsSupportHint: {
    id: "summaryReport.quickInsightsSupportHint",
    defaultMessage:
      "If a report is not available, please contact support at {supportContact}."
  }
})

export default messages
