import { defineMessages } from "react-intl"

const messages = defineMessages({
  resetPasswordDocumentTitle: {
    id: "resetPassword.documentTitle",
    defaultMessage: "Reset password"
  },
  resetPasswordHeader: {
    id: "resetPassword.header",
    defaultMessage: "Migration Evaluator (formerly TSO Logic) - reset password"
  },
  resetPasswordEmail: {
    id: "resetPassword.email",
    defaultMessage: "Enter your email"
  },
  resetPasswordEmailDescription: {
    id: "resetPassword.emailDescription",
    defaultMessage: "We will send you a verification code in email"
  },
  resetPasswordResendCode: {
    id: "resetPassword.resendCode",
    defaultMessage: "Resend code"
  },
  resetPasswordVerificationCode: {
    id: "resetPassword.verificationCode",
    defaultMessage: "Verification code"
  },
  resetPasswordVerificationCodeDescription: {
    id: "resetPassword.verificationCodeDescription",
    defaultMessage: "Enter the verification code from your email here"
  },
  resetPasswordNewPassword: {
    id: "resetPassword.newPassword",
    defaultMessage: "New password"
  },
  resetPasswordNewPasswordDescription: {
    id: "resetPassword.newPasswordDescription",
    defaultMessage:
      "Must be at least 8 characters long and contain at least 1: uppercase, lowercase, number, and special character"
  },
  resetPasswordRetypePassword: {
    id: "resetPassword.retypePassword",
    defaultMessage: "Retype password"
  },
  resetPasswordEmailRequired: {
    id: "resetPassword.emailRequired",
    defaultMessage: "Email cannot be empty"
  },
  resetPasswordEmailInvalid: {
    id: "resetPassword.emailInvalid",
    defaultMessage: "Enter a valid email"
  },
  resetPasswordCodeRequired: {
    id: "resetPassword.codeRequired",
    defaultMessage: "Verification code cannot be empty"
  },
  resetPasswordPasswordRequired: {
    id: "resetPassword.passwordRequired",
    defaultMessage: "Password cannot be empty"
  },
  resetPasswordPasswordInvalid: {
    id: "resetPassword.passwordInvalid",
    defaultMessage: "Password invalid; must be at least 8 characters long"
  },
  resetPasswordPasswordsDoNotMatch: {
    id: "resetPassword.passwordsDoNotMatch",
    defaultMessage: "Passwords do not match"
  },
  resetPasswordInvalidCodeError: {
    id: "resetPassword.invalidCodeError",
    defaultMessage: "Invalid verification code provided; please try again."
  },
  resetPasswordExpiredCodeError: {
    id: "resetPassword.expiredCodeError",
    defaultMessage: "Verification code is expired; please request a code again."
  },
  resetPasswordInvalidPasswordError: {
    id: "resetPassword.invalidPasswordError",
    defaultMessage: "Password does not meet complexity requirement."
  },
  resetPasswordVerificationCodeTooManyRequestsError: {
    id: "resetPassword.verificationCodeTooManyRequestsError",
    defaultMessage:
      "Too many requests; wait for a few minutes before trying again. Verification code email may take up to 5 minutes."
  },
  resetPasswordVerificationCodeUserNotFoundError: {
    id: "resetPassword.verificationCodeTooUserNotFoundError",
    defaultMessage: "We could not find a user with matching email."
  },
  resetPasswordUserEmailNotVerifiedError: {
    id: "resetPassword.userEmailNotVerifiedError",
    defaultMessage:
      "Your email is not verified; please verify your email when prompted after signing in. If you have forgotten your password, please contact support."
  }
})

export default messages
