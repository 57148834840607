import { defineMessages } from "react-intl"

const messages = defineMessages({
  redirectAuthDocumentTitle: {
    id: "redirectAuth.documentTitle",
    defaultMessage: "Loading"
  },
  userNotAuthorized: {
    id: "redirectAuth.userNotAuthorized",
    defaultMessage: "You are not authorized to sign in to this webportal"
  }
})

export default messages
