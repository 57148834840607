import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { Grid } from "@amzn/awsui-components-react/polaris"
import { withRouter, RouteComponentProps } from "react-router-dom"
import messages from "./Tools.messages"
import OnPremCollectorInstaller from "../../components/tools/OnPremCollectorInstaller"
import CustomerReportedFilesImport from "../../components/tools/CustomerReportedFilesImport"
import AppConfig from "../../Config"

const { Config } = AppConfig

interface IToolsProps {
  intl: IntlShape
  addBreadCrumb(text: string, href: string): void
  removeBreadCrumb(href: string): void
}
interface IToolsState {}

export class Tools extends React.Component<
  RouteComponentProps & IToolsProps,
  IToolsState
> {
  componentDidMount() {
    const {
      addBreadCrumb,
      match,
      intl: { formatMessage }
    } = this.props
    addBreadCrumb(formatMessage(messages.toolsBreadCrumb), match.url)
    document.title = formatMessage(messages.toolsDocumentTitle)
  }

  componentWillUnmount() {
    const { removeBreadCrumb, match } = this.props
    removeBreadCrumb(match.url)
  }

  render() {
    return (
      <div>
        <Grid
          gridDefinition={[
            { colspan: { m: 12, l: 6 } },
            { colspan: { m: 12, l: 6 } }
          ]}
        >
          {Config.Features.DiscoverCollectors ? (
            <div>
              <OnPremCollectorInstaller />
            </div>
          ) : null}
          {Config.Features.DiscoverSelfReportedFiles ? (
            <div>
              <CustomerReportedFilesImport />
            </div>
          ) : null}
        </Grid>
      </div>
    )
  }
}

export default injectIntl(withRouter(Tools))
