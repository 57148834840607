import moment from "moment"

// https://stackoverflow.com/a/43855794
export const tick = () => {
  return new Promise(resolve => {
    setTimeout(resolve, 0)
  })
}

export const itif = (condition: boolean) => (condition ? it : it.skip)

export const getAbsoluteTimestamp = (dateString: string) => {
  const datetime = moment(dateString)
  let absoluteTimestamp = datetime.format("MMMM DD, YYYY, HH:mm (UTCZ)")

  // remove leading 0 in timezone offset
  const offsetDirectionRegExp = /UTC([-+])/
  const offsetDirection = offsetDirectionRegExp.exec(absoluteTimestamp)
  if (offsetDirection) {
    absoluteTimestamp = absoluteTimestamp.replace(/([+-]0)/, offsetDirection[1])
  }

  return absoluteTimestamp
}
