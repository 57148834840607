import { defineMessages } from "react-intl"

const messages = defineMessages({
  header: {
    id: "accountDetails.header",
    defaultMessage: "Account details"
  },
  downloadAuditLogButtonText: {
    id: "accountDetails.downloadAuditLogButtonText",
    defaultMessage: "Download audit log"
  },
  getCloseAccountButtonText: {
    id: "accountDetails.getCloseAccountButtonText",
    defaultMessage: "Close account"
  },
  downloadAccessKeysButtonText: {
    id: "accountDetails.downloadAccessKeysButtonText",
    defaultMessage: "Download access keys"
  },
  goToAccessKeysButtonText: {
    id: "accountDetails.goToAccessKeysButtonText",
    defaultMessage: "Go to access keys"
  },
  goToMigrationHubButtonText: {
    id: "accountDetails.goToMirationHubButtonText",
    defaultMessage: "Go to Migration Hub"
  },
  createAccountButtonText: {
    id: "accountDetails.createAccountButtonText",
    defaultMessage: "Create account"
  },
  actionsButtonText: {
    id: "accountDetails.actionsButtonText",
    defaultMessage: "Actions"
  },
  accountId: {
    id: "accountDetails.accountId",
    defaultMessage: "Account Id"
  },
  accountStatus: {
    id: "accountDetails.accountStatus",
    defaultMessage: "Status"
  },
  accountCreationDate: {
    id: "accountDetails.accountCreateDate",
    defaultMessage: "Creation date"
  },
  accountClosureDate: {
    id: "accountDetails.accountClosureDate",
    defaultMessage: "Closed date"
  },
  noAccountHeader: {
    id: "accountDetails.noAccountHeader",
    defaultMessage: "No account"
  },
  noAccountDescription: {
    id: "accountDetails.noAccountDescription",
    defaultMessage:
      "This customer does not have a managed account. You can only create one account per engagement."
  },
  activeStatus: {
    id: "accountDetails.activeStatus",
    defaultMessage: "Active"
  },
  failedStatus: {
    id: "accountDetails.failedStatus",
    defaultMessage: "Failed"
  },
  inactiveStatus: {
    id: "accountDetails.inactiveStatus",
    defaultMessage: "Inactive"
  },
  closedStatus: {
    id: "accountDetails.closedStatus",
    defaultMessage: "Closed"
  },
  pendingStatus: {
    id: "accountDetails.pendingStatus",
    defaultMessage: "Pending"
  },
  accountClosureHeader: {
    id: "accountClosureModal.Header",
    defaultMessage: 'Close managed account "{awsAccountId}"?'
  },
  accountClosureContent: {
    id: "accountClosureModal.Content",
    defaultMessage:
      "You will no longer be able to access the account after closing. " +
      "However, there is a 90 day Post-Closure Period. " +
      "During the Post-Closure Period, AWS might retain any content that you didn't delete and any AWS services that you didn't terminate before you closed your AWS account. " +
      "You can access any remaining content or AWS services only by reopening your account during the Post-Closure Period. " +
      "If you choose to reopen your account, you can access the content that you didn't delete and AWS services that you didn't terminate before closing your account. " +
      "After the Post-Closure Period, we permanently close your AWS account, and you can't reopen it. " +
      "Any content that you didn't delete is deleted, and any AWS services that you didn't terminate are terminated. " +
      "If you wish to reopen your account during the Post-Closure Period, please contact the development team."
  },
  accountClosureLabel: {
    id: "accountClosureModal.Label",
    defaultMessage:
      "To confirm closure, enter the ID of the managed account in the text input field."
  },
  accountClosureConfirm: {
    id: "accountClosureModal.Confirm",
    defaultMessage: "Close managed account"
  },
  accountClosureCloseAriaLabel: {
    id: "accountClosureModal.CloseAriaLabel",
    defaultMessage: "Close modal"
  }
})

export default messages
