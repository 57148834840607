/* eslint-disable react/destructuring-assignment */
import React from "react"
import {
  AppLayout,
  BreadcrumbGroupProps,
  Grid,
  SideNavigationProps
} from "@amzn/awsui-components-react/polaris"
import {
  Switch,
  RouteComponentProps,
  withRouter,
  Redirect
} from "react-router-dom"
import { injectIntl, IntlShape } from "react-intl"
import { AWSCShortbread } from "@amzn/shortbread"
import EmsSideNavigation from "./EmsSideNavigation"
import messages from "./EmsAppLayout.messages"
import EmsBreadcrumbs from "../breadcrumbs/EmsBreadcrumbs"
import ProtectedRoute from "../auth/ProtectedRoute"
import Constants from "../../Constants"
import Tools from "../../pages/discover/Tools"
import Discover from "../../pages/discover/Discover"
import SelfReportedFiles from "../../pages/discover/SelfReportedFiles"
import OnPremCollectors from "../../pages/discover/OnPremCollectors"
import OptInConsentModal from "../engagements/OptInConsentModal"
import MyEngagements from "../../pages/dashboard/MyEngagements"
import AppConfig from "../../Config"
import { IEngagement } from "../engagements/Engagements"
import AccountManagement from "../../pages/discover/AccountManagement"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"

const { Config } = AppConfig

interface IEmsAppProps {
  intl: IntlShape
}

interface IEmsAppState {
  breadCrumbItems: BreadcrumbGroupProps.Item[]
  selectedEngagement: IEngagement | null
}

export class EmsAppLayout extends React.Component<
  RouteComponentProps & IEmsAppProps,
  IEmsAppState
> {
  private historyLocationUnsubscribe: any

  private rootBreadCrumbs: BreadcrumbGroupProps.Item[]

  private shortbread: any

  private mounted: boolean

  constructor(props: RouteComponentProps & IEmsAppProps) {
    super(props)

    const {
      intl: { formatMessage }
    } = this.props

    this.rootBreadCrumbs = [
      {
        text: formatMessage(messages.emsNavSectionDiscover),
        href: `#/${Constants.PathName.Discover}`
      },
      {
        text: formatMessage(messages.emsNavSectionDashboard),
        href: `#/${Constants.PathName.Dashboard}`
      }
    ]

    this.mounted = false

    this.state = {
      breadCrumbItems: [],
      selectedEngagement: null
    }
  }

  async componentDidMount() {
    this.mounted = true
    this.historyLocationUnsubscribe = this.props.history.listen(location => {
      this.updateRootBreadCrumb(location)
    })
    this.updateRootBreadCrumb(this.props.history.location)
    this.configureShortbread()
    await this.checkUserPermissionLevel()
  }

  componentWillUnmount() {
    this.mounted = false
    this.historyLocationUnsubscribe()
  }

  getSelectedEngagement = (): IEngagement | null => {
    return this.state.selectedEngagement
  }

  setSelectedEngagement = (newSelectedEngagement: IEngagement | null) => {
    this.setState({
      selectedEngagement: newSelectedEngagement
    })
  }

  addBreadCrumb = (text: string, href: string) => {
    this.setState(state => ({
      breadCrumbItems: [...state.breadCrumbItems, { text, href: `#${href}` }]
    }))
  }

  removeBreadCrumb = (href: string) => {
    this.setState(state => ({
      breadCrumbItems: [...state.breadCrumbItems].filter(
        item => item.href !== `#${href}`
      )
    }))
  }

  private checkUserPermissionLevel = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()
  }

  updateRootBreadCrumb = (location: any) => {
    this.setState(state => {
      if (this.locationPathMatchesBreadcrumbs(location.pathname)) {
        return { breadCrumbItems: state.breadCrumbItems }
      }
      if (this.breadCrumbsContainsARootBreadcrumb()) {
        const { breadCrumbItems } = state
        breadCrumbItems.shift()
        return {
          breadCrumbItems: [
            this.getMatchingRootBreadCrumb(location.pathname),
            ...state.breadCrumbItems
          ]
        }
      }
      return {
        breadCrumbItems: [
          this.getMatchingRootBreadCrumb(location.pathname),
          ...state.breadCrumbItems
        ]
      }
    })
    this.forceUpdate()
  }

  private configureShortbread = () => {
    const domainName = window.location.hostname
    this.shortbread = AWSCShortbread({
      parent: document.body,
      domain: ".".concat(domainName)
    })
    this.shortbread.checkForCookieConsent()
  }

  private breadCrumbsContainsARootBreadcrumb = (): boolean => {
    const { breadCrumbItems } = this.state

    if (breadCrumbItems.length === 0) return false

    return (
      this.rootBreadCrumbs.findIndex(
        item => item.href === breadCrumbItems[0].href
      ) >= 0
    )
  }

  private locationPathMatchesBreadcrumbs = (path: string): boolean => {
    const { breadCrumbItems } = this.state
    if (breadCrumbItems.length > 0) {
      return `#${path}`.startsWith(breadCrumbItems[0].href)
    }
    return false
  }

  private getMatchingRootBreadCrumb = (
    path: string
  ): BreadcrumbGroupProps.Item => {
    if (path) {
      const matching = this.rootBreadCrumbs.filter(item => {
        return `#${path}`.startsWith(item.href)
      })
      if (matching.length > 0) {
        return matching[0]
      }
    }
    return { href: "", text: "" }
  }

  protected getNavItems = () => {
    const {
      intl: { formatMessage }
    } = this.props

    const navItems: SideNavigationProps.Item[] = []

    if (Config.Features.Dashboard) {
      const navDashboardSectionItems: any[] = []
      navItems.push({
        type: "expandable-link-group",
        defaultExpanded: true,
        text: formatMessage(messages.emsNavSectionDashboard),
        href: `#/${Constants.PathName.Dashboard}`,
        items: navDashboardSectionItems
      })

      navDashboardSectionItems.push({
        type: "link",
        text: formatMessage(messages.emsNavItemMyEngagements),
        href: `#/${Constants.PathName.Dashboard}/${Constants.PathName.DashboardEngagements}`
      })
    }

    if (Config.Features.Discover) {
      const navDiscoverSectionItems: any[] = []

      navItems.push({
        type: "expandable-link-group",
        defaultExpanded: true,
        text: formatMessage(messages.emsNavSectionDiscover),
        href: `#/${Constants.PathName.Discover}`,
        items: navDiscoverSectionItems
      })

      if (Config.Features.DiscoverCollectors) {
        navDiscoverSectionItems.push({
          type: "link",
          text: formatMessage(messages.emsNavItemOnPremDataCollectors),
          href: `#/${Constants.PathName.Discover}/${Constants.PathName.DiscoverCollectors}`
        })
      }

      navDiscoverSectionItems.push({
        type: "link",
        text: formatMessage(messages.emsNavItemTools),
        href: `#/${Constants.PathName.Discover}/${Constants.PathName.DiscoverTools}`
      })

      if (Config.Features.DiscoverSelfReportedFiles) {
        navDiscoverSectionItems.push({
          type: "link",
          text: formatMessage(messages.emsNavItemSelfReportedFiles),
          href: `#/${Constants.PathName.Discover}/${Constants.PathName.DiscoverSelfReportedFiles}`
        })
      }

      if (Config.Features.ManagedAccountsPhase1) {
        navDiscoverSectionItems.push({
          type: "link",
          text: formatMessage(messages.emsNavItemAccountManagement),
          href: `#/${Constants.PathName.Discover}/${Constants.PathName.DiscoverAccountManagement}`
        })
      }
    }
    return navItems
  }

  private getContent = () => {
    const { selectedEngagement } = this.state
    return (
      <div>
        <Switch>
          <ProtectedRoute
            enabled={
              Config.Features.Discover && Config.Features.DiscoverCollectors
            }
            path={`/${Constants.PathName.Discover}/${Constants.PathName.DiscoverCollectors}`}
          >
            <OnPremCollectors
              addBreadCrumb={this.addBreadCrumb}
              removeBreadCrumb={this.removeBreadCrumb}
              setSelectedEngagement={this.setSelectedEngagement}
              selectedEngagement={selectedEngagement}
            />
          </ProtectedRoute>
          <ProtectedRoute
            enabled={
              Config.Features.Discover &&
              Config.Features.DiscoverSelfReportedFiles
            }
            path={`/${Constants.PathName.Discover}/${Constants.PathName.DiscoverSelfReportedFiles}`}
          >
            <SelfReportedFiles
              addBreadCrumb={this.addBreadCrumb}
              removeBreadCrumb={this.removeBreadCrumb}
              setSelectedEngagement={this.setSelectedEngagement}
              selectedEngagement={selectedEngagement}
            />
          </ProtectedRoute>
          <ProtectedRoute
            enabled={Config.Features.ManagedAccountsPhase1}
            path={`/${Constants.PathName.Discover}/${Constants.PathName.DiscoverAccountManagement}`}
          >
            <AccountManagement
              addBreadCrumb={this.addBreadCrumb}
              removeBreadCrumb={this.removeBreadCrumb}
              setSelectedEngagement={this.setSelectedEngagement}
              selectedEngagement={selectedEngagement}
            />
          </ProtectedRoute>
          <ProtectedRoute
            enabled={Config.Features.Discover}
            path={`/${Constants.PathName.Discover}/${Constants.PathName.DiscoverTools}`}
          >
            <Tools
              addBreadCrumb={this.addBreadCrumb}
              removeBreadCrumb={this.removeBreadCrumb}
            />
          </ProtectedRoute>
          <ProtectedRoute
            enabled={Config.Features.Discover}
            path={`/${Constants.PathName.Discover}`}
          >
            <Discover />
          </ProtectedRoute>

          <ProtectedRoute
            enabled={Config.Features.Dashboard}
            path={`/${Constants.PathName.Dashboard}/${Constants.PathName.DashboardEngagements}`}
          >
            <MyEngagements
              addBreadCrumb={this.addBreadCrumb}
              removeBreadCrumb={this.removeBreadCrumb}
              setSelectedEngagement={this.setSelectedEngagement}
              selectedEngagement={selectedEngagement}
            />
          </ProtectedRoute>
          <ProtectedRoute
            enabled={Config.Features.Dashboard}
            path={`/${Constants.PathName.Dashboard}`}
          >
            <Redirect
              to={`/${Constants.PathName.Dashboard}/${Constants.PathName.DashboardEngagements}`}
            />
          </ProtectedRoute>
        </Switch>
        {Config.Features.Dashboard ? (
          <Grid gridDefinition={[{ colspan: 12 }]}>
            <OptInConsentModal />
          </Grid>
        ) : null}
      </div>
    )
  }

  render() {
    const { breadCrumbItems } = this.state
    return (
      <div className="awsui">
        <AppLayout
          className="fullscreen"
          content={this.getContent()}
          navigation={<EmsSideNavigation navItems={this.getNavItems()} />}
          navigationOpen
          navigationWidth={280}
          onNavigationChange={this.updateRootBreadCrumb}
          tools={<div />}
          toolsHide
          breadcrumbs={<EmsBreadcrumbs items={breadCrumbItems} />}
          notifications={<div />}
          // for a custom header like ours, need to provide its css selector to prevent content overlap
          headerSelector="#app-header"
        />
      </div>
    )
  }
}

export default injectIntl(withRouter(EmsAppLayout))
