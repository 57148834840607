import React from "react"
import { injectIntl, IntlShape, MessageDescriptor } from "react-intl"
import { withRouter, RouteComponentProps } from "react-router-dom"
import {
  Box,
  Button,
  CollectionPreferencesProps,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator,
  Alert,
  ColumnLayout,
  TableProps,
  PaginationProps
} from "@amzn/awsui-components-react/polaris"
import { API, graphqlOperation } from "aws-amplify"
import CopyButton from "../utility/CopyButton"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"
import messages from "./Engagements.messages"
import common from "../Common.messages"
import { listEngagements } from "./graphql/queries"
import TableWrapper from "../utility/TableWrapper"
import ConfirmationModal from "../utility/ConfirmationModal"
import { deleteEngagement } from "./graphql/mutations"

export interface IEngagement {
  Id: string
  Name: string
  Customer?: string
  CollectorId?: string | null
}

export interface IListEngagementData {
  listEngagements: {
    Engagements: IEngagement[]
    NextToken: string | null
  }
}

interface IEngagementsProps {
  intl: IntlShape
  setSelectedEngagement(newSelectedEngagement: IEngagement | null): void
  selectedEngagement: IEngagement | null
  isOnEngagementsPage?: boolean
  collectorsOnly?: boolean
}

interface IEngagementsState {
  engagements: IEngagement[]
  loading: boolean
  pageSize: number
  errorExist: boolean
  isDeleteEngagementModalVisible: boolean
  isExternalCustomer: boolean
}

export class Engagements extends React.Component<
  RouteComponentProps & IEngagementsProps,
  IEngagementsState
> {
  static readonly ENGAGEMENT_COLUMN_NAME: string = "Name"

  static readonly ENGAGEMENT_COLUMN_CUSTOMER: string = "Customer"

  static readonly ENGAGEMENT_COLUMN_STATUS: string = "Status"

  static readonly LIST_ENGAGEMENTS_LIMIT: number = 1000

  private formatMsg: Function

  private paginationLabels: PaginationProps.Labels

  private PAGE_SELECTOR_OPTIONS: CollectionPreferencesProps.PageSizeOption[]

  private mounted: boolean

  constructor(props: RouteComponentProps & IEngagementsProps) {
    super(props)
    const {
      intl: { formatMessage }
    } = this.props
    this.formatMsg = formatMessage
    this.state = {
      loading: true,
      engagements: [],
      errorExist: false,
      isDeleteEngagementModalVisible: false,
      isExternalCustomer: true,
      pageSize: 10
    }
    this.paginationLabels = {
      nextPageLabel: this.formatMsg(messages.engagementNextPage),
      previousPageLabel: this.formatMsg(messages.engagementNextPage),
      pageLabel: (pageNumber: number) =>
        this.formatMsg(messages.engagementPageLabel, { pageNumber })
    }
    this.PAGE_SELECTOR_OPTIONS = []
    const pageSizes = [10, 30, 50]
    for (let i = 0; i < pageSizes.length; i += 1) {
      this.PAGE_SELECTOR_OPTIONS[i] = {
        value: pageSizes[i],
        label: this.formatMsg(messages.engagementPageSize, {
          pageSize: pageSizes[i]
        })
      }
    }
    this.mounted = false
    this.handleEngagementChange = this.handleEngagementChange.bind(this)
    this.handlePreferenceUpdate = this.handlePreferenceUpdate.bind(this)
  }

  async componentDidMount() {
    this.mounted = true

    await this.loadEngagements()
    await this.checkUserPermissionLevel()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleEngagementChange(
    detail: TableProps.SelectionChangeDetail<IEngagement>
  ) {
    const { setSelectedEngagement: onEngagementSelectionChange } = this.props

    // When we change pages, it looks like the table sets it's selection to null
    // but we don't want this to impact our selectedEngagement persistence
    if (!detail.selectedItems[0]) {
      return
    }

    onEngagementSelectionChange(detail.selectedItems[0])
  }

  handlePreferenceUpdate(detail: CollectionPreferencesProps.Preferences) {
    this.setState({
      pageSize: detail.pageSize!
    })
  }

  private getColumnDefinition(): TableProps.ColumnDefinition<IEngagement>[] {
    const { setSelectedEngagement } = this.props
    return [
      {
        id: Engagements.ENGAGEMENT_COLUMN_NAME,
        cell: (item: IEngagement) => {
          return (
            <Button
              variant="link"
              className="button-link"
              onClick={() => setSelectedEngagement(item)}
            >
              {item.Name}
            </Button>
          )
        },
        header: this.formatMsg(messages.engagementNameListHeader),
        minWidth: "160px",
        width: "50%",
        sortingField: Engagements.ENGAGEMENT_COLUMN_NAME
      },
      {
        id: Engagements.ENGAGEMENT_COLUMN_CUSTOMER,
        cell: (item: IEngagement) => item.Customer,
        header: this.formatMsg(messages.engagementCustomerListHeader),
        minWidth: "160px",
        sortingField: Engagements.ENGAGEMENT_COLUMN_CUSTOMER
      }
    ]
  }

  handleSelectedEngagement = () => {
    const {
      setSelectedEngagement: onEngagementSelectionChange,
      selectedEngagement,
      collectorsOnly
    } = this.props
    const { engagements } = this.state

    // Only select engagement if:
    // - The persisted one is in state.engagements
    // - There's only one engagement present and the persisted one is null AND you're not on the Collectors Page
    if (!selectedEngagement && engagements.length === 1 && !collectorsOnly) {
      onEngagementSelectionChange(engagements[0])
    } else if (
      engagements.filter(
        engagement =>
          engagement.Id.toLowerCase() === selectedEngagement?.Id.toLowerCase()
      ).length === 0
    ) {
      onEngagementSelectionChange(null)
    }

    // If onEngagementSelectionChange wasn't called, then props.selectedEngagement will fall through
    // to the render() function where it's passed into the Table component
  }

  private loadEngagements = async (engagementIdsToExclude?: string[]) => {
    const { result } = await this.fetchEngagements()

    if (this.mounted && result) {
      const { errorExist } = this.state
      if (!errorExist) {
        this.setState({
          loading: false,
          engagements: result.filter(
            engagement => !engagementIdsToExclude?.includes(engagement.Id)
          )
        })
        this.handleSelectedEngagement()
      }
    }
  }

  private deleteEngagement = async () => {
    const { selectedEngagement } = this.props

    this.setState({
      isDeleteEngagementModalVisible: false,
      loading: true
    })

    try {
      await API.graphql(
        graphqlOperation(deleteEngagement, {
          id: selectedEngagement!.Id
        })
      )
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      this.displayError()
    }

    await this.loadEngagements([selectedEngagement!.Id])
  }

  private openDeleteModalDialog = () => {
    this.setState({
      isDeleteEngagementModalVisible: true
    })
  }

  private checkUserPermissionLevel = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()

    if (this.mounted) {
      this.setState({
        isExternalCustomer: UserPermissionLevel.getInstance().getIsExternalCustomer()
      })
    }
  }

  private displayError = () => {
    if (this.mounted) {
      this.setState({
        loading: false,
        errorExist: true
      })
    }
  }

  private getTableHeader = () => {
    const { isOnEngagementsPage, selectedEngagement } = this.props
    const { engagements, isExternalCustomer, loading } = this.state

    const isEngagementDeletionAllowed =
      isOnEngagementsPage && isExternalCustomer

    const isDeleteButtonDisabled =
      !selectedEngagement || loading || engagements.length === 0

    const deleteEngagementBoxDisplay = isEngagementDeletionAllowed
      ? undefined
      : "none"
    const deleteEngagementBoxFloat = isEngagementDeletionAllowed
      ? "right"
      : undefined

    return (
      <Header
        counter={` (${engagements.length})`}
        actions={
          <Box
            display={deleteEngagementBoxDisplay}
            float={deleteEngagementBoxFloat}
            data-testid="deleteEngagementBox"
            data-testdisplay={deleteEngagementBoxDisplay}
            data-testfloat={deleteEngagementBoxFloat}
          >
            <Button
              data-testid="deleteEngagementButton"
              variant="normal"
              disabled={isDeleteButtonDisabled}
              onClick={this.openDeleteModalDialog}
            >
              {this.formatMsg(messages.engagementDeleteButtonContent)}
            </Button>
          </Box>
        }
      >
        <Box fontSize="heading-m" display="inline">
          {this.formatMsg(messages.engagementListTitle)}
        </Box>
      </Header>
    )
  }

  // helper to fetch all pages of engagements
  private async fetchAllEngagements(
    nextToken: string | null = null
  ): Promise<IEngagement[]> {
    const response = (await API.graphql(
      graphqlOperation(listEngagements, {
        nextToken,
        limit: Engagements.LIST_ENGAGEMENTS_LIMIT
      })
    )) as { data: IListEngagementData }
    let result = response.data.listEngagements.Engagements

    if (response.data.listEngagements.NextToken) {
      result = result.concat(
        await this.fetchAllEngagements(response.data.listEngagements.NextToken)
      )
    }

    return result
  }

  private async fetchEngagements(): Promise<{
    result: IEngagement[]
  }> {
    let result: IEngagement[] = []
    try {
      this.setState({ errorExist: false })

      result = await this.fetchAllEngagements()

      const { collectorsOnly } = this.props
      if (collectorsOnly) {
        result = result.filter(e => e.CollectorId != null)
      }
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      this.displayError()
    }

    return { result }
  }

  private engagementDetail(
    message: MessageDescriptor,
    value: string | undefined
  ) {
    return (
      <div>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">{this.formatMsg(message)}</Box>
            <div>
              <CopyButton value={value || ""} variant="inline-icon" />
              {value}
            </div>
          </div>
        </SpaceBetween>
      </div>
    )
  }

  render() {
    const { collectorsOnly, selectedEngagement } = this.props

    const {
      engagements,
      loading,
      errorExist,
      isDeleteEngagementModalVisible,
      pageSize
    } = this.state
    const selectedTableItems = selectedEngagement ? [selectedEngagement] : []

    const columnDefinitions = this.getColumnDefinition()

    return (
      <Box margin={{ vertical: "s" }}>
        <div>
          <Alert
            id="serverError"
            header={this.formatMsg(common.errorHeader)}
            type="error"
            dismissible
            visible={errorExist}
            onDismiss={() => this.setState({ errorExist: false })}
            dismissAriaLabel="Close alert"
          >
            {this.formatMsg(common.error)}
          </Alert>
        </div>
        <div>
          {selectedEngagement && (
            <ConfirmationModal
              onDismiss={() =>
                this.setState({ isDeleteEngagementModalVisible: false })
              }
              visible={isDeleteEngagementModalVisible}
              closeAriaLabel={this.formatMsg(
                messages.engagementDeletionCloseAriaLabel
              )}
              onClickCancel={() =>
                this.setState({ isDeleteEngagementModalVisible: false })
              }
              onClickConfirm={this.deleteEngagement}
              confirm={this.formatMsg(messages.engagementDeletionConfirm)}
              header={this.formatMsg(messages.engagementDeletionHeader, {
                engagementName: selectedEngagement.Name
              })}
              content={this.formatMsg(messages.engagementDeletionContent)}
              label={this.formatMsg(messages.engagementDeletionLabel)}
              description={undefined}
              placeholder={selectedEngagement.Name}
              modalId="engagementDeletionModal"
              cancelId="engagementDeletionCancel"
              confirmId="engagementDeletionConfirm"
              inputId="engagementDeletionInput"
            />
          )}
        </div>
        <Box margin={{ vertical: "s" }} />
        <div>
          <TableWrapper
            data-testid="engagementList"
            header={this.getTableHeader()}
            loading={loading}
            loadingText={this.formatMsg(common.loading)}
            empty={this.formatMsg(
              collectorsOnly
                ? messages.engagementsWithCollectorNotFound
                : messages.engagementsNotFound
            )}
            items={engagements}
            columnDefinitions={columnDefinitions}
            useCollectionOptions={{
              sorting: {
                defaultState: {
                  sortingColumn: columnDefinitions[0]
                }
              },
              selection: {
                keepSelection: true,
                trackBy: "Id"
              },
              filtering: {
                fields: [
                  Engagements.ENGAGEMENT_COLUMN_NAME,
                  Engagements.ENGAGEMENT_COLUMN_CUSTOMER
                ]
              },
              pagination: {
                pageSize
              }
            }}
            selectionProps={{
              selectionType: "single",
              selectedItems: selectedTableItems,
              onSelectionChange: ({ detail }) =>
                this.handleEngagementChange(detail)
            }}
            filterProps={{
              filteringPlaceholder: this.formatMsg(common.search)
            }}
            paginationProps={{
              ariaLabels: this.paginationLabels
            }}
            preferenceProps={{
              preferenceTitle: this.formatMsg(
                messages.engagementPreferencesTitle
              ),
              preferenceConfirmLabel: this.formatMsg(common.confirm),
              preferenceCancelLabel: this.formatMsg(common.cancel),
              preferenceDisabled: loading,
              pageSizePreference: {
                title: this.formatMsg(messages.engagementPageSizeTitle),
                options: this.PAGE_SELECTOR_OPTIONS
              },
              currentPreferences: { pageSize },
              preferenceOnConfirm: ({ detail }) =>
                this.handlePreferenceUpdate(detail)
            }}
          />
        </div>
        <Box margin={{ vertical: "l" }} />
        <div>
          <Container
            header={
              <Header variant="h2">
                {this.formatMsg(messages.engagementDetails)}
              </Header>
            }
          >
            {!selectedEngagement ? (
              <StatusIndicator type="pending" data-testid="noEngagementMsg">
                {this.formatMsg(messages.engagementNotSelected)}
              </StatusIndicator>
            ) : (
              <ColumnLayout
                columns={3}
                variant="text-grid"
                data-testid="engagementDetailsSection"
              >
                {this.engagementDetail(
                  messages.engagementNameListHeader,
                  selectedEngagement.Name
                )}
                {this.engagementDetail(
                  messages.engagementCustomerListHeader,
                  selectedEngagement.Customer
                )}
                {this.engagementDetail(
                  messages.engagementId,
                  selectedEngagement.Id
                )}
              </ColumnLayout>
            )}
          </Container>
        </div>
      </Box>
    )
  }
}

export default injectIntl(withRouter(Engagements))
