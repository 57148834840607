import { defineMessages } from "react-intl"

const messages = defineMessages({
  accountManagementDocumentTitle: {
    id: "accountManagement.documentTitle",
    defaultMessage: "Account management"
  },
  accountManagementBreadCrumb: {
    id: "accountManagement.breadcrumb",
    defaultMessage: "Account management"
  }
})

export default messages
