import {
  Button,
  Container,
  Header,
  Link,
  Box,
  Alert,
  ColumnLayout,
  FormField,
  Toggle,
  ToggleProps,
  Icon,
  Form,
  Spinner,
  Modal
} from "@amzn/awsui-components-react/polaris"
import { API, graphqlOperation } from "aws-amplify"
import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { RouteComponentProps, withRouter } from "react-router-dom"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"
import messages from "./CustomerDataShareConsentOptions.messages"
import commonMessages from "../Common.messages"
import { modifyCustomerDataShareConsent } from "./graphql/mutations"
import { getEngagement } from "./graphql/queries"
import Constants from "../../Constants"
import Config from "../../Config"

const AppConfig = Config.Config

interface IEngagementDetail {
  getEngagement: {
    Id: string
    Name: string
    Customer: string
    DataShareConsentStatus: string
  }
}

interface ICustomerDataShareConsentOptionsProp {
  intl: IntlShape
  engagementId: string
}

interface ICustomerDataShareConsentOptionsState {
  toggleLoading: boolean
  engagement: IEngagementDetail
  foundError: boolean
  externalCustomer: boolean
  showOptOutConfirmationModal: boolean
  customerDataShareConsentToggle: boolean
  showConsentStatusUpdateFailure: boolean
  loading: boolean
}

export class CustomerDataShareConsentOptions extends React.Component<
  RouteComponentProps & ICustomerDataShareConsentOptionsProp,
  ICustomerDataShareConsentOptionsState
> {
  private mounted: boolean

  constructor(
    props: RouteComponentProps & ICustomerDataShareConsentOptionsProp
  ) {
    super(props)
    this.state = {
      toggleLoading: true,
      loading: false,
      foundError: false,
      engagement: {} as IEngagementDetail,
      externalCustomer: false,
      showOptOutConfirmationModal: false,
      customerDataShareConsentToggle: false,
      showConsentStatusUpdateFailure: false
    }
    this.mounted = false
  }

  async componentDidMount() {
    this.mounted = true
    await this.refreshEngagementData()
    await this.checkUserPermissionLevel()
  }

  // This method should always keep the setState within the condition
  // otherwise it will end up into infinite loop
  async componentDidUpdate(
    prevProps: RouteComponentProps & ICustomerDataShareConsentOptionsProp
  ) {
    const { engagementId } = this.props
    if (engagementId !== prevProps.engagementId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: true
      })
      await this.refreshEngagementData()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  checkUserPermissionLevel = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()

    if (this.mounted) {
      this.setState({
        externalCustomer: UserPermissionLevel.getInstance().getIsExternalCustomer()
      })
    }
  }

  private refreshEngagementDataAfter = async (milliseconds: number) => {
    setTimeout(this.refreshEngagementData, milliseconds)
  }

  private refreshEngagementData = async () => {
    const { engagementId } = this.props

    try {
      const response = await API.graphql(
        graphqlOperation(getEngagement, { id: engagementId })
      )
      const engagementData = response as {
        data: IEngagementDetail
      }
      if (engagementData.data) {
        this.updateState(engagementData.data)
      } else {
        this.setState({
          toggleLoading: true,
          loading: false,
          foundError: true
        })
      }
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }

  private updateCustomerDataShareConsentStatus = async (
    id: string,
    consentStatus: string
  ) => {
    try {
      this.setState({
        toggleLoading: true
      })
      await API.graphql(
        graphqlOperation(modifyCustomerDataShareConsent, {
          id,
          customerDataShareConsentStatus: consentStatus
        })
      )
      this.setState({
        toggleLoading: false,
        showConsentStatusUpdateFailure: false
      })
      this.refreshEngagementDataAfter(5000)
    } catch (err: any) { // eslint-disable-line
      if (err.errors && err.errors[0].errorType === "MappingTemplate") {
        const { engagementId } = this.props

        this.setState({
          toggleLoading: false,
          showConsentStatusUpdateFailure: false
        })

        // eslint-disable-next-line no-console
        console.error(`Already opted in for engagement ${engagementId}`)
      } else {
        this.setState({
          toggleLoading: false,
          showConsentStatusUpdateFailure: true
        })
        // eslint-disable-next-line no-console
        console.error(err)
      }
      this.refreshEngagementDataAfter(1000)
    }
  }

  private confirmaAndOptOutCustomerDataShareConsent = async () => {
    const { engagement } = this.state
    this.setState({
      showOptOutConfirmationModal: false
    })
    this.updateCustomerDataShareConsentStatus(
      engagement.getEngagement.Id,
      Constants.CustomerDataShareConsentStatus.OptedOut
    )
  }

  private cancelConfirmation = () => {
    this.setState({
      showOptOutConfirmationModal: false,
      customerDataShareConsentToggle: true,
      showConsentStatusUpdateFailure: false
    })
  }

  private handleConsentToggleUpdate = (detail: ToggleProps.ChangeDetail) => {
    this.setState({
      customerDataShareConsentToggle: detail.checked
    })
    const { engagement } = this.state
    if (detail.checked) {
      this.updateCustomerDataShareConsentStatus(
        engagement.getEngagement.Id,
        Constants.CustomerDataShareConsentStatus.OptedIn
      )
    } else {
      this.setState({
        showOptOutConfirmationModal: true
      })
    }
  }

  updateState(currentEngagement: IEngagementDetail) {
    const { engagementId } = this.props
    if (this.mounted && engagementId === currentEngagement.getEngagement.Id) {
      this.setState({
        toggleLoading: false,
        loading: false,
        engagement: currentEngagement,
        customerDataShareConsentToggle:
          currentEngagement.getEngagement.DataShareConsentStatus ===
          Constants.CustomerDataShareConsentStatus.OptedIn
      })
    }
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      toggleLoading,
      loading,
      engagement,
      foundError,
      externalCustomer,
      showOptOutConfirmationModal: confirmModal,
      customerDataShareConsentToggle,
      showConsentStatusUpdateFailure
    } = this.state
    if (loading || engagement.getEngagement === undefined) {
      return (
        <Container
          header={
            <Header variant="h2">
              {formatMessage(messages.dataShareConsentOptionsHeader)}
            </Header>
          }
        >
          <div className="awsui text-center">
            <span className="v-center">
              <Spinner size="big" variant="disabled" />
            </span>
          </div>
        </Container>
      )
    }
    return (
      <>
        {" "}
        <div>
          <Alert
            id="serverError"
            header={formatMessage(commonMessages.errorHeader)}
            type="error"
            dismissible
            visible={showConsentStatusUpdateFailure}
            onDismiss={() =>
              this.setState({ showConsentStatusUpdateFailure: false })
            }
            dismissAriaLabel="Close alert"
          >
            {formatMessage(commonMessages.error)}
          </Alert>
        </div>
        <Container
          id="EMAOptInContainer"
          header={
            <Header variant="h2">
              {formatMessage(messages.dataShareConsentOptionsHeader)}: &nbsp;
              {engagement.getEngagement.Name}
            </Header>
          }
          footer={
            <Link
              href={AppConfig.Support.TermsAndConditionsLinkAddress}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage(messages.termsAndConditionsLinkDescription)}
              &nbsp;
              <Icon variant="link" name="external" />
            </Link>
          }
        >
          <Form>
            <ColumnLayout columns={1} id="configuration">
              <FormField
                id="dataShareConsentDecisionField"
                label={
                  <span>
                    {formatMessage(messages.meaDescription)}
                    &nbsp; {foundError} {externalCustomer} &nbsp;
                  </span>
                }
              >
                {toggleLoading ? <Spinner /> : <br />}
                <Toggle
                  data-testid="customerDataShareConsentToggle"
                  checked={customerDataShareConsentToggle}
                  onChange={({ detail }) =>
                    this.handleConsentToggleUpdate(detail)
                  }
                  disabled={!externalCustomer || toggleLoading}
                >
                  {formatMessage(messages.enhancedMigrationAssistance)}
                </Toggle>
              </FormField>

              <Modal
                data-testid="consentConfirmationModal"
                onDismiss={this.cancelConfirmation}
                visible={confirmModal}
                size="medium"
                footer={
                  <>
                    <Box float="left">
                      <Button
                        data-testid="consentConfirmationCancelButton"
                        variant="link"
                        onClick={this.cancelConfirmation}
                      >
                        {formatMessage(commonMessages.cancel)}
                      </Button>
                    </Box>
                    <Box float="right">
                      <Button
                        data-testid="consentConfirmationOkButton"
                        variant="primary"
                        onClick={this.confirmaAndOptOutCustomerDataShareConsent}
                      >
                        {formatMessage(commonMessages.confirm)}
                      </Button>
                    </Box>
                  </>
                }
                header={formatMessage(messages.consentModalConfirmationTitle)}
              >
                {formatMessage(messages.confirmOptOutHint)}
              </Modal>
            </ColumnLayout>
          </Form>
        </Container>
      </>
    )
  }
}

export default injectIntl(withRouter(CustomerDataShareConsentOptions))
