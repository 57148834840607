import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Box, Link, Alert, Icon } from "@amzn/awsui-components-react/polaris"
import { injectIntl, IntlShape } from "react-intl"
import messages from "./ToolsSurvey.messages"
import Config from "../../Config"

const AppConfig = Config.Config

interface IToolsSurveyProps {
  intl: IntlShape
}
interface IToolsSurveyState {}

export class ToolsSurvey extends React.PureComponent<
  RouteComponentProps & IToolsSurveyProps,
  IToolsSurveyState
> {
  render() {
    const {
      intl: { formatMessage }
    } = this.props
    return (
      <div>
        <Alert header={formatMessage(messages.title)} type="info">
          <Box variant="p">{formatMessage(messages.description)}</Box>
          <Link
            href={AppConfig.Support.ToolsSurveyLinkAddress}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage(messages.surveyLinkText)}
            &nbsp;
            <Icon variant="link" name="external" />
          </Link>
        </Alert>
      </div>
    )
  }
}

export default injectIntl(withRouter(ToolsSurvey))
