import merge from "lodash/merge"

export interface IAppConfig {
  Stage: string
  Cognito: ICognitoConfig
  CognitoOld: ICognitoConfig
  AppSync: IAppSyncConfig
  Features: IFeatureFlags
  CustomerReportedFiles: ICustomerReportedFilesConfig
  CustomerReportedFile: ICustomerReportedFileConfig
  Support: ISupport
}

export interface IFeatureFlags {
  Discover: boolean
  DiscoverCollectors: boolean
  DiscoverSelfReportedFiles: boolean
  MidwayAuthentication: boolean
  Dashboard: boolean
  SummaryReport: boolean
  EngagementSummaryReportGeneralAvailability: boolean
  RenderSummaryReportHtml: boolean
  MultiPoolLogin: boolean
  ManagedAccountsPhase1: boolean
  ManagedAccountsCloseAccount: boolean
  EnableOsCostForSummaryReport: boolean
}

export interface ISupport {
  EmailAddress: string
  ToolsSurveyLinkAddress: string
  PrivacyLinkAddress: string
  TermsAndConditionsLinkAddress: string
  CustomerAgreementLinkAddress: string
  AssessmentCentralLinkAddress: string
  HowToEngageWikiLinkAddress: string
  TrainingWikiLinkAddress: string
  OfficeHoursLinkAddress: string
  MeCollectorExportHelpLinkAddress: string
  MigrationEvaluatorDocumentationPageAddress: string
  AdsCollectorExportHelpLinkAddress: string
}

export interface IAppSyncConfig {
  GraphQLEndPoint: string
  Region: string
  AuthenticationType: string
}

export interface ICognitoConfig {
  Region: string
  UserPoolId: string
  AppClientId: string
  Oauth: IOauth
}
export interface ICustomerReportedFilesConfig {
  UploadDirectoryPath: string
  AllowedFileTypes: string[]
  MaxFileSizeBytes: number
}
export interface ICustomerReportedFileConfig {
  AllowedFileTypes: string[]
  MaxFileSizeBytes: number
}

export interface IOauth {
  domain: string
  scope: string[]
  redirectSignIn: string
  redirectSignOut: string
  responseType: string
}

export const stages = ["local", "beta", "gamma", "prod", undefined]
type stagetypes = typeof stages[number]

class DynamicAppConfig {
  public readonly StageConfigOverrides = [
    {
      Stage: "local",
      Cognito: {
        UserPoolId: "us-east-1_6zPFLWLpv",
        AppClientId: "54o1olbi83s3ssp8unula413ce",
        Oauth: {
          domain: "tso-authn-users-beta.auth.us-east-1.amazoncognito.com",
          redirectSignIn: "https://beta.console.tsologic.com/sso-callback",
          redirectSignOut: "https://beta.console.tsologic.com/"
        }
      },
      CognitoOld: {
        UserPoolId: "us-east-1_ttyGr8RdQ",
        AppClientId: "24snc5grg83qccumc430co39mm",
        Oauth: {
          domain: "tso-users-beta.auth.us-east-1.amazoncognito.com",
          redirectSignIn: "https://beta.console.tsologic.com/sso-callback",
          redirectSignOut: "https://beta.console.tsologic.com/"
        }
      },
      Features: {
        ManagedAccountsCloseAccount: true,
        EnableOsCostForSummaryReport: false
      },
      AppSync: {
        GraphQLEndPoint: "https://beta.graphql.console.tsologic.com"
      }
    },
    {
      Stage: "alpha",
      Cognito: {
        UserPoolId: "us-east-1_ttyGr8RdQ",
        AppClientId: "24snc5grg83qccumc430co39mm",
        Oauth: {
          domain: "tso-authn-users-alpha.auth.us-east-1.amazoncognito.com",
          redirectSignIn: "https://alpha.console.tsologic.com/sso-callback",
          redirectSignOut: "https://alpha.console.tsologic.com/"
        }
      },
      Features: {},
      AppSync: {
        GraphQLEndPoint:
          "https://3dbjdubdavfg3lhggalz7w4efy.appsync-api.us-east-1.amazonaws.com/graphql"
      }
    },
    {
      Stage: "beta",
      Cognito: {
        UserPoolId: "us-east-1_6zPFLWLpv",
        AppClientId: "54o1olbi83s3ssp8unula413ce",
        Oauth: {
          domain: "tso-authn-users-beta.auth.us-east-1.amazoncognito.com",
          redirectSignIn: "https://beta.console.tsologic.com/sso-callback",
          redirectSignOut: "https://beta.console.tsologic.com/"
        }
      },
      CognitoOld: {
        UserPoolId: "us-east-1_ttyGr8RdQ",
        AppClientId: "24snc5grg83qccumc430co39mm",
        Oauth: {
          domain: "tso-users-beta.auth.us-east-1.amazoncognito.com",
          redirectSignIn: "https://beta.console.tsologic.com/sso-callback",
          redirectSignOut: "https://beta.console.tsologic.com/"
        }
      },
      Features: {
        ManagedAccountsCloseAccount: true,
        EnableOsCostForSummaryReport: false
      },
      AppSync: {
        GraphQLEndPoint: "https://beta.graphql.console.tsologic.com"
      }
    },
    {
      Stage: "gamma",
      Cognito: {
        UserPoolId: "us-east-1_AjsAOEqJ0",
        AppClientId: "ocfmsoqemafjigoeq72fq775g",
        Oauth: {
          domain: "tso-authn-users-gamma.auth.us-east-1.amazoncognito.com",
          redirectSignIn: "https://gamma.console.tsologic.com/sso-callback",
          redirectSignOut: "https://gamma.console.tsologic.com/"
        }
      },
      CognitoOld: {
        UserPoolId: "us-east-1_zXpmj7A44",
        AppClientId: "6ueknm38pmd4vldrqfjbcle2k3",
        Oauth: {
          domain: "tso-users-gamma.auth.us-east-1.amazoncognito.com",
          redirectSignIn: "https://gamma.console.tsologic.com/sso-callback",
          redirectSignOut: "https://gamma.console.tsologic.com/"
        }
      },
      Features: {
        ManagedAccountsCloseAccount: true,
        EnableOsCostForSummaryReport: false
      },
      AppSync: {
        GraphQLEndPoint: "https://gamma.graphql.console.tsologic.com"
      }
    },
    {
      Stage: "prod",
      Cognito: {
        UserPoolId: "us-east-1_sIRSftiXk",
        AppClientId: "3u4793acnf2m9dqviqbg4snedv",
        Oauth: {
          domain: "tso-authn-users.auth.us-east-1.amazoncognito.com",
          redirectSignIn: "https://console.tsologic.com/sso-callback",
          redirectSignOut: "https://console.tsologic.com/"
        }
      },
      CognitoOld: {
        UserPoolId: "us-east-1_Kqr2GAeMT",
        AppClientId: "6vsh017s9mjpor9sopqea2705r",
        Oauth: {
          domain: "tso-users.auth.us-east-1.amazoncognito.com",
          redirectSignIn: "https://console.tsologic.com/sso-callback",
          redirectSignOut: "https://console.tsologic.com/"
        }
      },
      Features: {
        ManagedAccountsCloseAccount: true,
        EnableOsCostForSummaryReport: true
      },
      AppSync: {
        GraphQLEndPoint: "https://graphql.console.tsologic.com"
      }
    }
  ]

  public readonly DefaultConfig: IAppConfig = {
    Stage: "DEFAULT",
    Cognito: {
      Region: "us-east-1",
      UserPoolId: "",
      AppClientId: "",
      Oauth: {
        domain: "",
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: "",
        redirectSignOut: "",
        responseType: "code"
      }
    },
    CognitoOld: {
      Region: "us-east-1",
      UserPoolId: "",
      AppClientId: "",
      Oauth: {
        domain: "",
        scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: "",
        redirectSignOut: "",
        responseType: "code"
      }
    },
    AppSync: {
      Region: "us-east-1",
      GraphQLEndPoint: "",
      AuthenticationType: "AMAZON_COGNITO_USER_POOLS"
    },
    Features: {
      Discover: true,
      DiscoverCollectors: true,
      DiscoverSelfReportedFiles: true,
      MidwayAuthentication: true,
      Dashboard: true,
      SummaryReport: true,
      EngagementSummaryReportGeneralAvailability: false,
      RenderSummaryReportHtml: false,
      MultiPoolLogin: true,
      ManagedAccountsPhase1: true,
      EnableOsCostForSummaryReport: true,
      ManagedAccountsCloseAccount: false
    },
    CustomerReportedFiles: {
      MaxFileSizeBytes: 500 * 1024 * 1024, // 500MB
      UploadDirectoryPath: "Upload/",
      AllowedFileTypes: [".csv", ".xls", ".xlsx", ".zip"]
    },
    CustomerReportedFile: {
      MaxFileSizeBytes: 500 * 1024 * 1024, // 500MB
      AllowedFileTypes: [".csv", ".xls", ".xlsx", ".zip"]
    },
    Support: {
      EmailAddress: "migration-evaluator@amazon.com ",
      ToolsSurveyLinkAddress:
        "https://amazonmr.au1.qualtrics.com/jfe/form/SV_d45t6q93EXrJCwB",
      PrivacyLinkAddress: "https://aws.amazon.com/privacy/",
      TermsAndConditionsLinkAddress:
        "https://aws.amazon.com/service-terms/#69._Migration_Evaluator",
      CustomerAgreementLinkAddress: "https://aws.amazon.com/agreement/",
      AssessmentCentralLinkAddress:
        "https://assessmentcentral.migration-services.aws.dev/",
      HowToEngageWikiLinkAddress:
        "https://w.amazon.com/bin/view/TSO_Logic/HowToEngage/",
      TrainingWikiLinkAddress:
        "https://w.amazon.com/bin/view/TSO_Logic/Training/",
      OfficeHoursLinkAddress:
        "https://w.amazon.com/bin/view/TSO_Logic/OfficeHours/",
      MeCollectorExportHelpLinkAddress:
        "https://d1.awsstatic.com/migration-evaluator-resources/collector_Install-Guide.pdf",
      MigrationEvaluatorDocumentationPageAddress:
        "https://aws.amazon.com/migration-evaluator/resources/#Documentation",
      AdsCollectorExportHelpLinkAddress:
        "https://docs.aws.amazon.com/application-discovery/latest/userguide/view-and-export.html"
    }
  }

  private domain: string | undefined = undefined

  private domainMap: Map<string, stagetypes> = new Map([
    ["d184q09w8x0gef.cloudfront.net", "beta"],
    ["beta.console.tsologic.com", "beta"],
    ["d1jb4yepzhjixg.cloudfront.net", "gamma"],
    ["gamma.console.tsologic.com", "gamma"],
    ["d3tlmv2de7tkwr.cloudfront.net", "prod"],
    ["prod.console.tsologic.com", "prod"],
    ["console.tsologic.com", "prod"]
  ])

  setDomain = (currentDomain: string) => {
    this.domain = currentDomain
  }

  private getStageFromDomain(): stagetypes {
    if (this.domain === undefined) {
      this.setDomain(window.location.hostname)
    }
    if (this.domain === undefined) return undefined
    return this.domainMap.get(this.domain)
  }

  private getStageFromEnv(): string | undefined {
    return this.StageConfigOverrides.findIndex(
      config => config.Stage === process.env.REACT_APP_STAGE
    ) > 0
      ? process.env.REACT_APP_STAGE
      : undefined
  }

  get Config(): IAppConfig {
    let stage = this.getStageFromEnv()
    if (stage === undefined) stage = this.getStageFromDomain()
    if (stage === undefined) stage = "local"

    const stageConfig = this.StageConfigOverrides.find(
      config => config.Stage === stage
    )

    return merge(this.DefaultConfig, stageConfig) as IAppConfig
  }
}

export default new DynamicAppConfig()
