/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */

import "@formatjs/intl-unified-numberformat/polyfill"
// NOTE: manually add locale-data for each supported locale
// Webpack needs to be able to determine module name for supported locales at compile time
// If it is unable to determine the supported locales at compile time it will try to package modules for all locales that results in a bundle size > 4 mb

/**
 * Summary: Checks and polyfills intl plural rules if not available in the browser
 * Description: https://github.com/formatjs/react-intl/blob/master/docs/Getting-Started.md#runtime-requirements
 */
const polyfillPluralRules = () => {
  if (!Intl.PluralRules) {
    require("@formatjs/intl-pluralrules/polyfill")

    // locale: en
    require("@formatjs/intl-pluralrules/dist/locale-data/en")
  }
}

/**
 * Summary: Checks and polyfills intl relative time format rules if not available in the browser
 * Description: https://github.com/formatjs/react-intl/blob/master/docs/Getting-Started.md#runtime-requirements
 */
const polyfillRelativeTimeFormat = () => {
  if (!Intl.RelativeTimeFormat) {
    require("@formatjs/intl-relativetimeformat/polyfill")

    // locale: en
    require("@formatjs/intl-relativetimeformat/dist/locale-data/en")
  }
}

/**
 * Summary: Checks and polyfills intl number format rules if not available in the browser
 * Description: https://github.com/formatjs/formatjs/tree/master/packages/intl-unified-numberformat
 */
const polyfillNumberFormat = () => {
  if (typeof Intl.NumberFormat.__addLocaleData === "function") {
    // locale: en
    Intl.NumberFormat.__addLocaleData(
      require("@formatjs/intl-unified-numberformat/dist/locale-data/en.json")
    )
  }
}

export { polyfillPluralRules, polyfillRelativeTimeFormat, polyfillNumberFormat }
