import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import {
  Box,
  Button,
  Container,
  Header,
  Grid,
  Link,
  StatusIndicator,
  ColumnLayout,
  Checkbox,
  CheckboxProps,
  Flashbar,
  FormField,
  Form,
  Input,
  Toggle,
  Icon,
  InputProps,
  ToggleProps
} from "@amzn/awsui-components-react/polaris"
import { Auth } from "aws-amplify"
import {
  Redirect,
  Prompt,
  withRouter,
  RouteComponentProps
} from "react-router-dom"
import messages from "./SignUp.messages"
import commonMessages from "../Common.messages"
import Constants from "../../Constants"
import { AuthConfigureCommon } from "./AuthConfigureCommon"
import AppConfig from "../../Config"

const { Config } = AppConfig

interface ISignUpProps {
  intl: IntlShape
}
interface ISignUpState {
  redirectToSignIn: boolean
  userNeedsToVerifyEmail: boolean
  successfullySignedUp: boolean
  loading: boolean
  signUp: ISignUpFormState
  emailVerification: IEmailVerificationFormState
  user: any
  showVerificationCodeSentMessage: boolean
}

interface ISignUpFormState {
  email: string
  emailError: string
  password: string
  showPassword: boolean
  passwordError: string
  serverSideError: string
  termsAndConditionsAccepted: boolean
  validateOnChange: boolean
}
interface IEmailVerificationFormState {
  verificationCode: string
  verificationCodeError: string
  serverSideError: string
}

export class SignUp extends React.PureComponent<
  ISignUpProps & RouteComponentProps,
  ISignUpState
> {
  constructor(props: ISignUpProps & RouteComponentProps) {
    super(props)
    this.state = {
      loading: false,
      redirectToSignIn: false,
      userNeedsToVerifyEmail: false,
      successfullySignedUp: false,
      showVerificationCodeSentMessage: false,
      signUp: {
        email: "",
        emailError: "",
        password: "",
        passwordError: "",
        serverSideError: "",
        showPassword: false,
        termsAndConditionsAccepted: false,
        validateOnChange: false
      },
      emailVerification: {
        verificationCode: "",
        verificationCodeError: "",
        serverSideError: ""
      },
      user: undefined
    }
  }

  async componentDidMount() {
    const {
      intl: { formatMessage },
      location: { state }
    } = this.props
    document.title = formatMessage(messages.signUpDocumentTitle)

    try {
      const user = await Auth.currentAuthenticatedUser()

      if (user) {
        this.setState({
          successfullySignedUp: true,
          loading: false
        })
      }
    } catch (err: any) { // eslint-disable-line
      // suppress
    }

    const locationState = state as any
    if (locationState) {
      if (locationState.verifyEmail && locationState.userName) {
        this.resendSignUpCode(locationState.userName)
        this.setState({
          userNeedsToVerifyEmail: true,
          successfullySignedUp: false,
          loading: false,
          user: { getUsername: () => locationState.userName }
        })
      }
    }
  }

  componentDidUpdate = () => {
    const { userNeedsToVerifyEmail } = this.state

    if (userNeedsToVerifyEmail) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = null
    }
  }

  onEmailChange = (detail: InputProps.ChangeDetail) => {
    const {
      signUp: { validateOnChange }
    } = this.state
    this.setState(state => {
      return {
        signUp: { ...state.signUp, email: detail.value }
      }
    })
    if (validateOnChange) {
      this.validateSignUpInputs()
    }
  }

  onPasswordChange = (detail: InputProps.ChangeDetail) => {
    const {
      signUp: { validateOnChange }
    } = this.state
    this.setState(state => {
      return {
        signUp: { ...state.signUp, password: detail.value }
      }
    })

    if (validateOnChange) {
      this.validateSignUpInputs()
    }
  }

  onTermsAndConditionsAcceptedChange = (detail: CheckboxProps.ChangeDetail) => {
    this.setState(state => {
      return {
        signUp: {
          ...state.signUp,
          termsAndConditionsAccepted: detail.checked
        }
      }
    })
  }

  onVerificationCodeChange = (detail: InputProps.ChangeDetail) => {
    this.setState(state => {
      return {
        emailVerification: {
          ...state.emailVerification,
          verificationCode: detail.value
        }
      }
    })
  }

  validateVerificationCodeInput = (): boolean => {
    const {
      emailVerification: { verificationCode }
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    let verificationCodeError: string = ""
    if (!verificationCode || verificationCode === "") {
      verificationCodeError = formatMessage(
        messages.signUpEmailVerificationErrorRequired
      )
    }

    this.setState(state => {
      return {
        emailVerification: { ...state.emailVerification, verificationCodeError }
      }
    })

    return verificationCodeError === ""
  }

  validateSignUpInputs = (): boolean => {
    const {
      signUp: { email, password, termsAndConditionsAccepted }
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    let emailError: string = ""
    if (!email || email === "") {
      emailError = formatMessage(messages.signUpEmailErrorRequired)
    } else if (
      !email.match(/^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.)+([a-zA-Z]{2,})$/i)
    ) {
      emailError = formatMessage(messages.signUpEmailErrorInvalid)
    }

    let passwordError: string = ""
    if (!password) {
      passwordError = formatMessage(messages.signUpPasswordRequired)
    }

    this.setState(state => {
      return {
        signUp: { ...state.signUp, emailError, passwordError }
      }
    })

    return (
      emailError === "" && passwordError === "" && termsAndConditionsAccepted
    )
  }

  signUpOnReturn = (event: CustomEvent<InputProps.KeyDetail>) => {
    const {
      detail: { keyCode }
    } = event
    if (keyCode === Constants.ReturnKeyCode) {
      this.onSignUp()
    }
  }

  resendSignUpCode = async (username: string) => {
    try {
      this.setState(state => {
        return {
          showVerificationCodeSentMessage: false,
          emailVerification: {
            ...state.emailVerification,
            serverSideError: ""
          }
        }
      })

      // eslint-disable-next-line react-hooks/rules-of-hooks
      AuthConfigureCommon.useNewPool()
      await Auth.resendSignUp(username.toLowerCase())
      this.setState({ showVerificationCodeSentMessage: true })
    } catch (err: any) { // eslint-disable-line
      if (err.code && err.message)
        this.setState(state => {
          return {
            emailVerification: {
              ...state.emailVerification,
              serverSideError: `${err.code}: ${err.message}`
            }
          }
        })
      // eslint-disable-next-line
      console.log(err)
    }
  }

  onResendCode = () => {
    const { user } = this.state
    this.resendSignUpCode(user.getUsername())
  }

  onSignIn = () => {
    this.setState({
      redirectToSignIn: true
    })
  }

  onSignUp = async () => {
    this.setState(state => {
      return {
        successfullySignedUp: false,
        loading: false,
        signUp: { ...state.signUp, validateOnChange: true }
      }
    })

    if (this.validateSignUpInputs()) {
      this.setState({
        successfullySignedUp: false,
        loading: true
      })

      const {
        signUp: { email, password }
      } = this.state

      const {
        intl: { formatMessage }
      } = this.props

      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        AuthConfigureCommon.useNewPool()
        const signUpResult = await Auth.signUp(email.toLowerCase(), password)
        this.setState({
          user: signUpResult.user
        })
        if (signUpResult.userConfirmed) {
          this.setState({
            successfullySignedUp: true,
            loading: false
          })
        } else {
          this.setState({
            userNeedsToVerifyEmail: true,
            successfullySignedUp: false,
            loading: false,
            showVerificationCodeSentMessage: true
          })
        }
      } catch (err: any) { // eslint-disable-line
        let errorMessage: string
        switch (err.code) {
          case "InvalidParameterException":
          case "InvalidPasswordException":
            errorMessage = formatMessage(messages.signUpErrorInvalidPassword)
            break
          case "UsernameExistsException":
            errorMessage = formatMessage(
              messages.signUpErrorUserWithEmailAlreadyExists
            )
            break
          case Constants.Cognito.PreSignUpLambdaEmailNotAllowedException:
            if (
              err.message &&
              (err.message as string).includes(
                Constants.Cognito
                  .PreSignUpLambdaEmailNotAllowedExceptionPartialMessage
              )
            )
              errorMessage = formatMessage(
                messages.signUpErrorEmailNotPermitted
              )
            break
          default:
            errorMessage = `${err.code}: ${err.message}`
            // eslint-disable-next-line
              console.error(err)
            break
        }

        this.setState(state => {
          return {
            successfullySignedUp: false,
            loading: false,
            signUp: { ...state.signUp, serverSideError: errorMessage }
          }
        })
      }
    }
  }

  submitVerificationCodeOnReturn = (
    event: CustomEvent<InputProps.KeyDetail>
  ) => {
    const {
      detail: { keyCode }
    } = event
    if (keyCode === Constants.ReturnKeyCode) {
      this.onSubmitVerificationCode()
    }
  }

  onSubmitVerificationCode = async () => {
    const {
      intl: { formatMessage }
    } = this.props
    const {
      user,
      emailVerification: { verificationCode }
    } = this.state

    if (this.validateVerificationCodeInput()) {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        AuthConfigureCommon.useNewPool()
        const result = await Auth.confirmSignUp(
          user.getUsername(),
          verificationCode
        )

        this.setState({
          user: result,
          successfullySignedUp: true,
          userNeedsToVerifyEmail: false,
          loading: false
        })
      } catch (err: any) { // eslint-disable-line
        let errorMessage: string
        switch (err.code) {
          case "CodeMismatchException":
            errorMessage = formatMessage(
              messages.signUpErrorInvalidVerificationCode
            )
            break
          default:
            errorMessage = err.code
            // eslint-disable-next-line
              console.error(err)
            break
        }
        this.setState(state => {
          return {
            loading: false,
            emailVerification: {
              ...state.emailVerification,
              serverSideError: errorMessage
            }
          }
        })
      }
    }
  }

  getEmailVerificationContainer = () => {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      showVerificationCodeSentMessage,
      userNeedsToVerifyEmail,
      user,
      emailVerification: {
        verificationCode,
        verificationCodeError,
        serverSideError
      }
    } = this.state

    return (
      <div>
        {showVerificationCodeSentMessage ? (
          <Flashbar
            id="verificationCodeSentMessageFlash"
            className="margin-b-20"
            items={[
              {
                type: "info",
                content: (
                  <Box variant="p">
                    {formatMessage(messages.signUpEmailVerificationCodeSent, {
                      email: user.getUsername()
                    })}
                    <br />
                    {formatMessage(
                      messages.signUpEmailVerificationEmailMayTakeTime
                    )}
                  </Box>
                ),
                dismissible: true,
                onDismiss: () =>
                  this.setState({ showVerificationCodeSentMessage: false })
              }
            ]}
          />
        ) : null}
        <Container
          header={
            <Header variant="h2">
              {formatMessage(messages.signUpEmailVerificationHeader)}
            </Header>
          }
          footer={
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <Box>
                <Button
                  id="resendVerificationCodeButton"
                  variant="link"
                  onClick={this.onResendCode}
                >
                  {formatMessage(messages.signUpResendCode)}
                </Button>
              </Box>
              <Box float="right">
                <Button
                  id="verifyEmailSubmitButton"
                  variant="primary"
                  onClick={this.onSubmitVerificationCode}
                >
                  {formatMessage(commonMessages.submit)}
                </Button>
              </Box>
            </Grid>
          }
        >
          <div>
            <Form id="verifyEmailForm" errorText={serverSideError}>
              <ColumnLayout>
                <FormField
                  id="verifyEmailTokenFormField"
                  stretch
                  label={formatMessage(messages.signUpEmailVerificationCode)}
                  description={formatMessage(
                    messages.signUpEmailVerificationCodeDescription
                  )}
                  errorText={verificationCodeError}
                >
                  <Input
                    id="verifyEmailTokenInput"
                    type="text"
                    value={verificationCode}
                    onChange={({ detail }) =>
                      this.onVerificationCodeChange(detail)
                    }
                    onKeyDown={this.submitVerificationCodeOnReturn}
                    ariaLabelledby={formatMessage(
                      messages.signUpEmailVerificationCode
                    )}
                  />
                </FormField>
                <Box padding={{ bottom: "xs" }} />
              </ColumnLayout>
            </Form>
          </div>
          <Prompt
            when={userNeedsToVerifyEmail}
            message={formatMessage(
              messages.signUpMessagePreventUserFromLeavingWihtoutVerifyingEmail
            )}
          />
        </Container>
      </div>
    )
  }

  getSignUpContainer = () => {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      signUp: {
        email,
        emailError,
        password,
        passwordError,
        showPassword,
        serverSideError,
        termsAndConditionsAccepted
      }
    } = this.state

    return (
      <Container
        header={
          <Header variant="h2">{formatMessage(messages.signUpHeader)}</Header>
        }
        footer={
          <Grid
            gridDefinition={[
              { colspan: 4, push: 8 },
              { colspan: 8, pull: 4 }
            ]}
          >
            <Box float="right">
              <Button
                id="signUpSubmitButton"
                variant="primary"
                onClick={this.onSignUp}
                disabled={!termsAndConditionsAccepted}
              >
                {formatMessage(messages.signUpSignUp)}
              </Button>
            </Box>
            <Box float="left">
              <Button id="signUpButton" variant="link" onClick={this.onSignIn}>
                {formatMessage(messages.signUpSignIn)}
              </Button>
            </Box>
          </Grid>
        }
      >
        <div>
          <Form id="signUpForm" errorText={serverSideError}>
            <ColumnLayout>
              <FormField
                id="signUpEmailFormField"
                stretch
                errorText={emailError}
                label={formatMessage(messages.signUpEmail)}
                description={formatMessage(messages.signUpEmailDescription)}
              >
                <Input
                  id="signUpEmailInput"
                  type="email"
                  autoFocus
                  value={email}
                  onChange={({ detail }) => this.onEmailChange(detail)}
                  ariaLabelledby={formatMessage(messages.signUpEmail)}
                />
              </FormField>
              <FormField
                id="signUpPasswordFormField"
                stretch
                errorText={passwordError}
                label={formatMessage(messages.signUpPassword)}
                description={formatMessage(messages.signUpPasswordDescription)}
              >
                <Input
                  id="signUpPasswordInput"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={({ detail }) => this.onPasswordChange(detail)}
                  onKeyDown={this.signUpOnReturn}
                  ariaLabelledby={formatMessage(messages.signUpPassword)}
                />
                <Toggle
                  checked={showPassword}
                  onChange={({ detail }) => this.toggleShowPassword(detail)}
                >
                  {formatMessage(messages.signUpShowPassword)}
                </Toggle>
              </FormField>
              <FormField id="termsAndConditionsFormField" stretch>
                <Checkbox
                  id="termsAndConditionsCheckbox"
                  checked={termsAndConditionsAccepted}
                  onChange={({ detail }) =>
                    this.onTermsAndConditionsAcceptedChange(detail)
                  }
                >
                  {formatMessage(messages.signUpTermsAndConditions)}&nbsp;
                  <Link
                    href={Config.Support.CustomerAgreementLinkAddress}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage(messages.signUpTermsAndConditionsLink)}
                    &nbsp;
                    <Icon variant="link" name="external" />
                  </Link>
                </Checkbox>
              </FormField>
              <Box data-testid="signUpDisclaimer">
                {formatMessage(messages.signUpDisclaimer)}
              </Box>
            </ColumnLayout>
          </Form>
        </div>
      </Container>
    )
  }

  wrapInAwsUiGrid = (innerContent: any): any => {
    return (
      <div className="awsui">
        <Grid
          gridDefinition={[
            { colspan: 12 },
            { colspan: { m: 4, xxs: 10 }, push: { m: 4, xxs: 1 } }
          ]}
        >
          <Box padding={{ top: "xl" }} />
          <div> {innerContent} </div>
        </Grid>
      </div>
    )
  }

  toggleShowPassword = (detail: ToggleProps.ChangeDetail) => {
    this.setState(state => {
      return {
        signUp: { ...state.signUp, showPassword: detail.checked }
      }
    })
  }

  render() {
    const {
      successfullySignedUp,
      redirectToSignIn,
      userNeedsToVerifyEmail,
      loading
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    if (loading) {
      return (
        <div className="awsui text-center">
          <StatusIndicator type="loading">
            {formatMessage(commonMessages.loading)}
          </StatusIndicator>
        </div>
      )
    }

    if (redirectToSignIn) {
      return <Redirect to={`/${Constants.PathName.SignIn}`} />
    }

    if (successfullySignedUp) {
      return (
        <Redirect
          to={{
            pathname: `/${Constants.PathName.SignIn}`,
            state: { successfullySignedUp: true }
          }}
        />
      )
    }

    if (userNeedsToVerifyEmail) {
      return this.wrapInAwsUiGrid(this.getEmailVerificationContainer())
    }

    return this.wrapInAwsUiGrid(this.getSignUpContainer())
  }
}

export default injectIntl(withRouter(SignUp))
