import { defineMessages } from "react-intl"

const messages = defineMessages({
  appName: {
    id: "app.name",
    defaultMessage: "Migration Evaluator, Formerly TSO Logic"
  },
  ok: {
    id: "common.ok",
    defaultMessage: "Ok"
  },
  confirm: {
    id: "common.confirm",
    defaultMessage: "Confirm"
  },
  cancel: {
    id: "common.cancel",
    defaultMessage: "Cancel"
  },
  loading: {
    id: "common.loading",
    defaultMessage: "Loading"
  },
  submit: {
    id: "common.submit",
    defaultMessage: "Submit"
  },
  search: {
    id: "common.search",
    defaultMessage: "Search"
  },
  error: {
    id: "common.server.error",
    defaultMessage:
      "An error occurred on the server while processing the request, please try again"
  },
  errorHeader: {
    id: "common.server.errorHeader",
    defaultMessage: "Failed to process"
  },
  hide: {
    id: "common.hide",
    defaultMessage: "Hide"
  },
  show: {
    id: "common.show",
    defaultMessage: "Show"
  },
  copiedToClipboard: {
    id: "common.copiedToClipboard",
    defaultMessage: "Copied to clipboard"
  },
  copy: {
    id: "common.copy",
    defaultMessage: "Copy"
  }
})

export default messages
