import { defineMessages } from "react-intl"

const messages = defineMessages({
  onPremiseDataCollectorTitle: {
    id: "onPremiseDataCollectorInstaller.onPremiseDataCollectorTitle",
    defaultMessage: "On-premises data collector"
  },
  onPremiseDataCollectorInstallationInstructionsSummary: {
    id: "onPremiseDataCollectorInstaller.installationInstructionsSummary",
    defaultMessage:
      "Deploy the agentless Migration Evaluator data collector to monitor VMware, Hyper-V and bare-metal infrastructure. Note that Migration Evaluator leverages TSO Logic technology as part of the assessment."
  },
  onPremiseDataCollectorDownloadInstallGuide: {
    id: "onPremiseDataCollectorInstaller.downloadInstallGuide",
    defaultMessage: "Download the installation guide"
  },
  onPremiseDataCollectorDownloadAdditionalLanguagesInstallGuideDescription: {
    id:
      "onPremiseDataCollectorInstaller.downloadAdditionalLanguagesInstallGuideDescription",
    defaultMessage: "Additional languages are available on the "
  },
  onPremiseDataCollectorDownloadAdditionalLanguagesInstallGuide: {
    id:
      "onPremiseDataCollectorInstaller.downloadAdditionalLanguagesInstallGuide",
    defaultMessage: "Migration Evaluator documentation page"
  },
  onPremiseDataCollectorCreateVm: {
    id: "onPremiseDataCollectorInstaller.createVm",
    defaultMessage:
      "Create a dedicated Windows VM in your data center based on the specifications in the installation guide"
  },
  onPremiseDataCollectorFollowInstallGuide: {
    id: "onPremiseDataCollectorInstaller.followInstallGuide",
    defaultMessage:
      "Follow the installation guide to install and configure your agentless collector."
  },
  onPremiseDataCollectorViewAndConfigureCollector: {
    id: "onPremiseDataCollectorInstaller.viewAndConfigureCollector",
    defaultMessage: "View and configure collectors"
  },
  onPremiseDataCollectorDownloadCollector: {
    id: "onPremiseDataCollectorInstaller.downloadCollector",
    defaultMessage: "Download the Migration Evaluator Collector software"
  },
  onPremiseDataCollectorDownload3rdPartySoftwareInstaller: {
    id: "onPremiseDataCollectorInstaller.download3rdPartySoftwareInstaller",
    defaultMessage: "Download the Migration Evaluator bootstrapper"
  },
  onPremiseDataCollectorOpenSourceSoftwareDescription: {
    id: "onPremiseDataCollectorInstaller.openSourceSoftwareDescription",
    defaultMessage: "For relevant licenses of all dependent third party tools,"
  },
  onPremiseDataCollectorOpenSourceSoftwareLink: {
    id: "onPremiseDataCollectorInstaller.openSourceSoftwareLink",
    defaultMessage: "see third party licenses"
  }
})

export default messages
