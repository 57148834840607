import { defineMessages } from "react-intl"

const messages = defineMessages({
  selfReportedFilesDocumentTitle: {
    id: "selfReportedFiles.documentTitle",
    defaultMessage: "Self-reported files"
  },
  selfReportedFilesBreadCrumb: {
    id: "selfReportedFiles.breadcrumb",
    defaultMessage: "Self-reported files"
  }
})

export default messages
