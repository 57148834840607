import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { injectIntl, IntlShape } from "react-intl"
import {
  BreadcrumbGroup,
  BreadcrumbGroupProps
} from "@amzn/awsui-components-react/polaris"

interface IEmsBreadcrumbsProps {
  intl: IntlShape
  items: BreadcrumbGroupProps.Item[]
}
interface IEmsBreadcrumbsState {}

export class EmsBreadcrumbs extends React.Component<
  RouteComponentProps & IEmsBreadcrumbsProps,
  IEmsBreadcrumbsState
> {
  onFollowHandler = (ev: CustomEvent<BreadcrumbGroupProps.ClickDetail>) => {
    const { history } = this.props
    ev.preventDefault()
    if (ev.detail.href) {
      // https://sage.amazon.com/questions/509636#512958
      // push the href in the browser history so that users can use browser's back and forward functionality
      // the links in polaris components start with an extra #
      // to set the href properly get rid of the first character in the href, hence ev.detail.href.substring(1)
      history.push(ev.detail.href.substring(1))
    }
  }

  render() {
    const { items } = this.props
    return (
      <BreadcrumbGroup
        ariaLabel="Breadcrumbs"
        items={items}
        onFollow={this.onFollowHandler}
      />
    )
  }
}

export default injectIntl(withRouter(EmsBreadcrumbs))
