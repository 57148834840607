import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

// eslint-disable-next-line spaced-comment
/// <reference types="aws-sdk" />

import React from "react"
import ReactDOM from "react-dom"
import App from "./components/App"

ReactDOM.render(<App />, document.getElementById("root"))
