import { defineMessages } from "react-intl"

const messages = defineMessages({
  EMAOptInInformation: {
    id: "consentModal.optInInfo",
    defaultMessage:
      "The business case for AWS is strongest when your account manager or partner can bring in experts from other AWS service teams. You may choose to opt out of Enhanced Migration Assistance via [Dashboard > My engagements] and your inventory and utilization details will not be shared with your AWS account team"
  },
  EMAHeader: {
    id: "consentModal.header",
    defaultMessage: "Enhanced Migration Assistance"
  }
})

export default messages
