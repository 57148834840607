import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { injectIntl, IntlShape } from "react-intl"
import {
  SideNavigation,
  SideNavigationProps
} from "@amzn/awsui-components-react/polaris"
import messages from "./EmsSideNavigation.messages"

interface IEmsSideNavigationProps {
  intl: IntlShape
  navItems: SideNavigationProps.Item[]
}
interface IEmsSideNavigationState {}

export class EmsSideNavigation extends React.Component<
  RouteComponentProps & IEmsSideNavigationProps,
  IEmsSideNavigationState
> {
  onFollowHandler = (ev: CustomEvent<SideNavigationProps.FollowDetail>) => {
    const { history } = this.props
    ev.preventDefault()
    if (ev.detail.href) {
      // https://sage.amazon.com/questions/509636#512958
      // push the href in the browser history so that users can use browser's back and forward functionality
      // the links in polaris components start with an extra #
      // to set the href properly get rid of the first character in the href, hence ev.detail.href.substring(1)
      history.push(ev.detail.href.substring(1))
    }
  }

  render() {
    const {
      navItems,
      location,
      intl: { formatMessage }
    } = this.props
    return (
      <SideNavigation
        data-testid="sideNavigation"
        header={{ text: formatMessage(messages.navHeader), href: "/" }}
        items={navItems}
        activeHref={`#${location.pathname}`}
        onFollow={this.onFollowHandler}
      />
    )
  }
}

export default injectIntl(withRouter(EmsSideNavigation))
