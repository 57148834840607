import { defineMessages } from "react-intl"

const messages = defineMessages({
  serviceMessageTechnicalIssues: {
    id: "serviceMessageBanner.technicalIssues",
    defaultMessage: "Migration Evaluator is experiencing increased error rates"
  },
  serviceMessageServiceMaintenance: {
    id: "serviceMessageBanner.serviceMaintenance",
    defaultMessage: "Migration Evaluator is undergoing maintenance"
  }
})

export default messages
