import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom"
import {
  Box,
  Button,
  Container,
  TextContent,
  Alert,
  Spinner
} from "@amzn/awsui-components-react/polaris"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { API, graphqlOperation } from "aws-amplify"
import messages from "./CustomerReportedFilesImport.messages"
import commonMessages from "../Common.messages"
import { getSelfReportedDataTemplateQuery } from "./graphql/queries"
import Constants from "../../Constants"

interface ICustomerReportedFilesImportProps {
  intl: IntlShape
}

interface ICustomerReportedFilesImportState {
  redirectToCustomerReportedFiles: boolean
  template: ICustomerReportedFilesImportTemplate
  errorExist: boolean
}

interface ICustomerReportedFilesImportTemplate {
  CustomerSelfReportedDataTemplate: string
}

export class CustomerReportedFilesImport extends React.Component<
  RouteComponentProps & ICustomerReportedFilesImportProps,
  ICustomerReportedFilesImportState
> {
  willUnmount: boolean = false

  constructor(props: RouteComponentProps & ICustomerReportedFilesImportProps) {
    super(props)
    this.state = {
      redirectToCustomerReportedFiles: false,
      template: {
        CustomerSelfReportedDataTemplate: ""
      },
      errorExist: false
    }
  }

  async componentDidMount() {
    await this.getImportTemplate()
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  getImportTemplate = async () => {
    try {
      this.setState({ errorExist: false })
      const response = await API.graphql(
        graphqlOperation(getSelfReportedDataTemplateQuery)
      )

      const responseData = response as {
        data: { getInstallers: ICustomerReportedFilesImportTemplate }
      }
      if (!this.willUnmount) {
        this.setState({
          template: responseData.data.getInstallers
        })
      }
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      this.setState({ errorExist: true })
    }
  }

  redirectToCustomerReportedFiles = () => {
    this.setState({ redirectToCustomerReportedFiles: true })
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      redirectToCustomerReportedFiles,
      template: { CustomerSelfReportedDataTemplate },
      errorExist
    } = this.state

    if (redirectToCustomerReportedFiles) {
      return <Redirect push to={Constants.PathName.DiscoverSelfReportedFiles} />
    }

    return (
      <>
        <div>
          <Alert
            id="serverError"
            header={formatMessage(commonMessages.errorHeader)}
            type="error"
            dismissible
            visible={errorExist}
            onDismiss={() => this.setState({ errorExist: false })}
            dismissAriaLabel="Close alert"
          >
            {formatMessage(commonMessages.error)}
          </Alert>
        </div>
        <Container
          header={
            <>
              <Box float="left" fontSize="heading-m">
                <FontAwesomeIcon size="lg" icon={faUpload} />
                &nbsp; {formatMessage(messages.title)}
              </Box>
              {!CustomerSelfReportedDataTemplate && (
                <Box float="right">
                  <Spinner id="customerSelfReportedDataTemplateLoading" />
                </Box>
              )}
            </>
          }
        >
          <div>
            <Box padding={{ vertical: "xl" }} variant="p">
              {formatMessage(messages.summary)}
            </Box>
            <TextContent>
              <ol>
                <li>
                  <Box margin={{ left: "m" }}>
                    {formatMessage(messages.downloadTemplateInstructions)}
                  </Box>
                  <Box padding={{ top: "s" }}>
                    <Button
                      data-testid="importTemplate"
                      className={
                        CustomerSelfReportedDataTemplate !== ""
                          ? "button-link"
                          : ""
                      }
                      variant="link"
                      iconName="download"
                      disabled={CustomerSelfReportedDataTemplate === ""}
                      target="_blank"
                      href={CustomerSelfReportedDataTemplate}
                    >
                      {formatMessage(messages.downloadTemplateLink)}
                    </Button>
                  </Box>
                </li>
                <li>
                  <Box margin={{ left: "m" }}>
                    {formatMessage(messages.fillInRequiredFieldsInstructions)}
                  </Box>
                </li>
                <li>
                  <Box margin={{ left: "m" }}>
                    {formatMessage(messages.uploadFileInstructions)}
                  </Box>
                </li>
              </ol>
            </TextContent>
          </div>
          <Box padding={{ top: "xxxl" }} margin={{ top: "l" }}>
            <Button
              data-testid="redirectCustomerReportedFilesButton"
              variant="primary"
              onClick={this.redirectToCustomerReportedFiles}
            >
              {formatMessage(messages.viewAndImportFiles)}
            </Button>
          </Box>
        </Container>
      </>
    )
  }
}

export default injectIntl(withRouter(CustomerReportedFilesImport))
