import { defineMessages } from "react-intl"

const messages = defineMessages({
  engagementsDocumentTitle: {
    id: "engagements.documentTitle",
    defaultMessage: "On-premises data collectors"
  },
  engagementsContent: {
    id: "engagements.content",
    defaultMessage: "On-premises data collectors"
  },
  engagementsBreadCrumb: {
    id: "engagements.breadcrumb",
    defaultMessage: "On-premises data collectors"
  },
  engagementsNotFound: {
    id: "engagements.notFound",
    defaultMessage: "No engagements found."
  },
  engagementsWithCollectorNotFound: {
    id: "engagements.collectorNotFound",
    defaultMessage: "No engagements with collectors found."
  },
  engagementListTitle: {
    id: "engagements.ListTitle",
    defaultMessage: "My engagements"
  },
  engagementNameListHeader: {
    id: "engagements.NameHeader",
    defaultMessage: "Name"
  },
  engagementId: {
    id: "engagements.Id",
    defaultMessage: "Id"
  },
  engagementCustomerListHeader: {
    id: "engagements.CustomerHeader",
    defaultMessage: "Company"
  },
  engagementStatusListHeader: {
    id: "engagements.StatusHeader",
    defaultMessage: "Status"
  },
  engagementDeleteButtonContent: {
    id: "engagements.DeleteButtonContent",
    defaultMessage: "Delete"
  },
  engagementPreferencesTitle: {
    id: "engagements.PreferencesTitle",
    defaultMessage: "Preferences"
  },
  engagementPageSizeTitle: {
    id: "engagements.PageSizeTitle",
    defaultMessage: "Page size"
  },
  engagementNextPage: {
    id: "engagements.NextPage",
    defaultMessage: "Next page"
  },
  engagementPreviousPage: {
    id: "engagements.PreviousPage",
    defaultMessage: "Previous page"
  },
  engagementPageLabel: {
    id: "engagements.PageLabel",
    defaultMessage: "Page {pageNumber} of all pages"
  },
  engagementPageSize: {
    id: "engagements.PageSize",
    defaultMessage: "{pageSize} engagements"
  },
  engagementDetails: {
    id: "engagements.Details",
    defaultMessage: "Engagement details"
  },
  engagementNotSelected: {
    id: "engagements.NotSelected",
    defaultMessage: "No engagement selected"
  },
  engagementDeletionHeader: {
    id: "engagementDeletionModal.Header",
    defaultMessage: 'Delete engagement "{engagementName}"?'
  },
  engagementDeletionContent: {
    id: "engagementDeletionModal.Content",
    defaultMessage:
      "Deleting an engagement will delete it for all Migration Evaluator users. This action will not impact data replicated to Application Discovery Service or AWS Migration Hub, unless it is in a managed account created for this engagement."
  },
  engagementDeletionLabel: {
    id: "engagementDeletionModal.Label",
    defaultMessage:
      "To confirm deletion, enter the name of the engagement in the text input field."
  },
  engagementDeletionConfirm: {
    id: "engagementDeletionModal.Confirm",
    defaultMessage: "Delete engagement"
  },
  engagementDeletionCloseAriaLabel: {
    id: "engagementDeletionModal.CloseAriaLabel",
    defaultMessage: "Close modal"
  }
})

export default messages
