import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Grid } from "@amzn/awsui-components-react/polaris"
import messages from "./OnPremCollectors.messages"
import Engagements, {
  IEngagement
} from "../../components/engagements/Engagements"
import EngagementConfiguration from "../../components/engagements/EngagementConfiguration"
import FrequentlyAskedQuestions from "../../components/help/FrequentlyAskedQuestions"
import ToolsSurvey from "../../components/help/ToolsSurvey"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"

interface IOnPremCollectorsProps {
  intl: IntlShape
  addBreadCrumb(text: string, href: string): void
  removeBreadCrumb(href: string): void
  setSelectedEngagement(newSelectedEngagement: IEngagement | null): void
  selectedEngagement: IEngagement | null
}
interface IOnPremCollectorsState {
  externalCustomer: boolean
}

export class OnPremCollectors extends React.Component<
  RouteComponentProps & IOnPremCollectorsProps,
  IOnPremCollectorsState
> {
  constructor(props: RouteComponentProps & IOnPremCollectorsProps) {
    super(props)
    this.state = {
      externalCustomer: true
    }
  }

  async componentDidMount() {
    const {
      addBreadCrumb,
      match,
      intl: { formatMessage }
    } = this.props
    addBreadCrumb(formatMessage(messages.onPremCollectorsBreadCrumb), match.url)
    document.title = formatMessage(messages.onPremCollectorsDocumentTitle)
    await this.checkUserPermissionLevel()
  }

  componentWillUnmount() {
    const { removeBreadCrumb, match } = this.props
    removeBreadCrumb(match.url)
  }

  checkUserPermissionLevel = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()
    this.setState({
      externalCustomer: UserPermissionLevel.getInstance().getIsExternalCustomer()
    })
  }

  render() {
    const { selectedEngagement, setSelectedEngagement } = this.props
    const { externalCustomer } = this.state
    const selectedEngagementId = selectedEngagement?.CollectorId
      ? selectedEngagement.Id
      : null

    return (
      <div>
        <Grid gridDefinition={[{ colspan: 12 }]}>
          <Engagements
            collectorsOnly
            setSelectedEngagement={setSelectedEngagement}
            selectedEngagement={selectedEngagement}
          />
        </Grid>
        <Grid gridDefinition={[{ colspan: 12 }]}>
          {selectedEngagementId != null ? (
            <EngagementConfiguration engagementId={selectedEngagementId} />
          ) : null}
        </Grid>
        <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}>
          <ToolsSurvey />
          <FrequentlyAskedQuestions externalCustomer={externalCustomer} />
        </Grid>
      </div>
    )
  }
}

export default injectIntl(withRouter(OnPremCollectors))
