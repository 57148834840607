import { defineMessages } from "react-intl"

const messages = defineMessages({
  toolsDocumentTitle: {
    id: "tools.documentTitle",
    defaultMessage: "Tools"
  },
  toolsContent: {
    id: "tools.content",
    defaultMessage: "TOOLS"
  },
  toolsBreadCrumb: {
    id: "tools.breadcrumb",
    defaultMessage: "Tools"
  }
})

export default messages
