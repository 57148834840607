import React from "react"
import {
  Box,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  StatusIndicator
} from "@amzn/awsui-components-react/polaris"
import { API, graphqlOperation } from "aws-amplify"
import { injectIntl, IntlShape, MessageDescriptor } from "react-intl"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { isEqual } from "underscore"
import moment from "moment"
import Constants from "../../Constants"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"
import FeatureAccessControl from "../../lib/auth/FeatureAccessControl"
import commonMessages from "../Common.messages"
import { IEngagement } from "../engagements/Engagements"
import { getAbsoluteTimestamp } from "../utility/HelperFunctions"
import {
  AlertType,
  PollingConfigurations,
  Status,
  UNAVAILABLE_FIELD,
  FederationPages,
  ManagedAccountRoleName,
  FEDERATION_DEFAULT_PAGE_REGEX,
  ACCOUNT_ALREADY_EXISTS_EXCPETION_MESSAGE,
  ErrorMessage,
  ManagedAccountsAction
} from "./AccountDetails.constants"
import messages from "./AccountDetails.messages"
import {
  getAccountDetailsQuery,
  createAccountQuery,
  getFederationLinkQuery,
  getAuditLogQuery,
  createAccessKeysQuery,
  updateAccountsQuery
} from "./graphql/queries"
import AccountDetailsAlert from "./AccountDetailsAlert"
import AppConfig from "../../Config"
import ConfirmationModal from "../utility/ConfirmationModal"

const { Config } = AppConfig

interface IAccountDetails {
  OwnershipId: string
  Status: string
  DataType: string
  CreatedOn: string
  ClosedOn: string
  Id: string
  RequestId: string
  ContainerAccountId: string
  AccountName: string
  AccountEmail: string
}

interface IAccountDetailsProps {
  intl: IntlShape
  selectedEngagement: IEngagement | null
}

interface IAccountDetailsState {
  externalCustomer: boolean
  managedAccounts: IAccountDetails[]
  fetchingAccounts: boolean
  mutationInProgress: boolean
  fetchingFederationLink: boolean
  fetchingAuditLog: boolean
  fetchingAccessKeys: boolean
  accountClosureModalIsVisible: boolean
  alertType: AlertType
  canCreateManagedAccount: boolean
  canCloseManagedAccount: boolean
  canCreateAccessKeyForManagedAccount: boolean
  canFederateIntoManagedAccount: boolean
  canRetrieveAuditLogForManagedAccount: boolean
}

// exported for unit test
export function downloadAccessKeys(
  accessKeyId: string,
  secretAccessKey: string,
  accountId: string
) {
  const link = document.createElement("a")
  link.setAttribute(
    "href",
    `data:text/plain;charset=utf-8,${encodeURIComponent(
      `Access Key ID: ${accessKeyId}\nSecret Access Key: ${secretAccessKey}`
    )}`
  )
  link.setAttribute("download", `${accountId}.txt`)
  link.style.display = "none"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export class AccountDetails extends React.Component<
  RouteComponentProps & IAccountDetailsProps,
  IAccountDetailsState
> {
  private mounted: boolean

  private formatMessage: Function

  pollingInterval?: NodeJS.Timeout

  constructor(props: RouteComponentProps & IAccountDetailsProps) {
    super(props)
    this.state = {
      externalCustomer: true,
      managedAccounts: [],
      // true when making a call to get accounts
      fetchingAccounts: true,
      // true when the latest account has not changed after creating or closing
      // an account to hide potentially outdated account right after user
      // presses Create or Close
      mutationInProgress: false,
      fetchingFederationLink: false,
      fetchingAuditLog: false,
      fetchingAccessKeys: false,
      accountClosureModalIsVisible: false,
      alertType: AlertType.None,
      canCreateManagedAccount: false,
      canCloseManagedAccount: false,
      canCreateAccessKeyForManagedAccount: false,
      canFederateIntoManagedAccount: false,
      canRetrieveAuditLogForManagedAccount: false
    }
    const {
      intl: { formatMessage }
    } = this.props
    this.formatMessage = formatMessage
    this.mounted = false
  }

  async componentDidMount() {
    const { selectedEngagement } = this.props
    this.mounted = true
    await this.checkUserPermissionLevel()
    await this.getFeatureStatus()
    this.refreshComponent(selectedEngagement)
  }

  async componentDidUpdate(prevProps: any) {
    const { selectedEngagement } = this.props
    if (prevProps.selectedEngagement !== selectedEngagement) {
      this.refreshComponent(selectedEngagement)
    }
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval)
    }
  }

  checkUserPermissionLevel = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()
    if (this.mounted) {
      this.setState({
        externalCustomer: UserPermissionLevel.getInstance().getIsExternalCustomer()
      })
    }
  }

  getFeatureStatus = async () => {
    if (this.mounted) {
      this.setState({
        canCreateManagedAccount: await FeatureAccessControl.isFeatureOn(
          Constants.Features.CanCreateManagedAccount
        ),
        canCloseManagedAccount: await FeatureAccessControl.isFeatureOn(
          Constants.Features.CanCloseManagedAccount
        ),
        canCreateAccessKeyForManagedAccount: await FeatureAccessControl.isFeatureOn(
          Constants.Features.CanCreateAccessKeyForManagedAccount
        ),
        canFederateIntoManagedAccount: await FeatureAccessControl.isFeatureOn(
          Constants.Features.CanFederateIntoManagedAccount
        ),
        canRetrieveAuditLogForManagedAccount: await FeatureAccessControl.isFeatureOn(
          Constants.Features.CanRetrieveAuditLogForManagedAccount
        )
      })
    }
  }

  getAndUpdateAccountDetails = async (engagement: IEngagement | null) => {
    if (!engagement) {
      return []
    }

    const engagementId = engagement.Id
    try {
      const response = await API.graphql(
        graphqlOperation(getAccountDetailsQuery, { engagementId })
      )

      const {
        data: {
          getManagedAccountsByOwnershipId: {
            ManagedAccounts: managedAccountsResponse
          }
        }
      } = response as {
        data: {
          getManagedAccountsByOwnershipId: {
            ManagedAccounts: IAccountDetails[]
          }
        }
      }

      const managedAccounts = this.getCurrentManagedAccountsByAccountDetails(
        managedAccountsResponse
      )

      // the request can take some time and the user could have switched to another engagement
      // in which case we do not want to update with the accounts from the prev engagement
      const { selectedEngagement: currentSelectedEngagement } = this.props
      if (engagementId === currentSelectedEngagement!.Id) {
        this.setState({
          managedAccounts,
          fetchingAccounts: false
        })
        return managedAccounts
      }
      return []
    } catch (err: any) { // eslint-disable-line
      if (this.mounted) {
        this.setState({ managedAccounts: [], fetchingAccounts: false })
      }
      // eslint-disable-next-line no-console
      console.error(err)

      return []
    }
  }

  getCurrentManagedAccountsByAccountDetails = (
    managedAccounts: IAccountDetails[]
  ) => {
    return managedAccounts.sort(
      (a, b) =>
        new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime()
    )
  }

  private refreshComponent = async (selectedEngagement: IEngagement | null) => {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval)
    }

    this.setState({
      fetchingAccounts: true,
      mutationInProgress: false,
      alertType: AlertType.None,
      managedAccounts: []
    })

    const managedAccounts = await this.getAndUpdateAccountDetails(
      selectedEngagement
    )

    this.handleStatusAlerts(
      selectedEngagement,
      managedAccounts[0],
      [Status.Active, Status.Inactive, Status.Closed, Status.NotExist],
      [Status.Failed]
    )
  }

  /**
   * Set the alert state based on the given account's current status,
   * successStatuses, and errorStatuses.
   * If the current status does not appear in either status arrays, then
   * depending on the creation timestamp of the given account, either set
   * the alert to timeout state or start polling the latest account status.
   * @param engagement
   * @param account
   * @param successStatuses
   * @param errorStatuses
   * @returns
   */
  private handleStatusAlerts = async (
    engagement: IEngagement | null,
    account: IAccountDetails | undefined,
    successStatuses: Status[],
    errorStatuses: Status[]
  ) => {
    const accountStatus: Status = account
      ? Status[account.Status as keyof typeof Status]
      : Status.NotExist

    if (successStatuses.includes(accountStatus)) {
      return
    }

    if (errorStatuses.includes(accountStatus)) {
      this.setState({ alertType: AlertType.AccountCreationError })
      return
    }

    if (
      account &&
      new Date(account.CreatedOn).getTime() + PollingConfigurations.MaxWaitMS <
        new Date().getTime()
    ) {
      this.setState({ alertType: AlertType.AccountCreationTimeout })
      return
    }

    this.pollAccountStatus(
      engagement,
      account,
      successStatuses,
      errorStatuses,
      AlertType.AccountCreationError,
      AlertType.AccountCreationTimeout
    )
  }

  /**
   * Periodically poll account status for engagement for some time and set alerts
   * accordingly.
   * Return a promise that resolves when the latest account becomes different
   * from prevAccount, or when polling times out.
   * @param engagement
   * @param prevAccount
   * @param successStatuses
   * @param errorStatuses
   * @param error
   * @param timeout
   * @returns
   */
  private pollAccountStatus = (
    engagement: IEngagement | null,
    prevAccount: IAccountDetails | undefined,
    successStatuses: Status[],
    errorStatuses: Status[],
    error: AlertType,
    timeout: AlertType
  ) => {
    let retryCount = 0
    const promise = new Promise(resolve => {
      this.pollingInterval = setInterval(async () => {
        const accountDetails = await this.getAndUpdateAccountDetails(engagement)
        const account = accountDetails[0]

        // retry if there's no update
        if (
          isEqual(account, prevAccount) &&
          retryCount <= PollingConfigurations.MaxRetry
        ) {
          retryCount += 1
          return
        }

        // no update, but reached max retries
        if (isEqual(account, prevAccount)) {
          this.setState({
            alertType: timeout
          })
          clearInterval(this.pollingInterval!)
          resolve(null)
          return
        }

        // account has changed, resolve
        resolve(null)
        const accountStatus: Status = account
          ? Status[accountDetails[0].Status as keyof typeof Status]
          : Status.NotExist

        if (successStatuses.includes(accountStatus)) {
          clearInterval(this.pollingInterval!)
          return
        }

        if (errorStatuses.includes(accountStatus)) {
          this.setState({ alertType: error })
          clearInterval(this.pollingInterval!)
          return
        }

        // if no account, use the retry counter to determine timeout
        // if an account exists, use the creation timestamp instead
        if (
          (!account && retryCount > PollingConfigurations.MaxRetry) ||
          (account &&
            new Date(account.CreatedOn).getTime() +
              PollingConfigurations.MaxWaitMS <
              new Date().getTime())
        ) {
          this.setState({
            alertType: timeout
          })
          clearInterval(this.pollingInterval!)
          return
        }

        retryCount += 1
      }, PollingConfigurations.PollIntervalMS)
    })

    return promise
  }

  refreshDetails = async () => {
    const { selectedEngagement } = this.props
    this.refreshComponent(selectedEngagement)
  }

  getAuditLog = async () => {
    const { managedAccounts } = this.state
    const { selectedEngagement } = this.props

    if (!selectedEngagement || managedAccounts.length === 0) {
      return
    }

    const engagementId = selectedEngagement.Id
    const awsAccountId = managedAccounts[0].Id
    this.setState({ fetchingAuditLog: true })

    let response
    let auditLogErrorAlert = {}
    try {
      response = await API.graphql(
        graphqlOperation(getAuditLogQuery, {
          engagementId,
          awsAccountId
        })
      )

      const {
        data: {
          getManagedAccountAuditLog: { Location: getAuditLogResponse }
        }
      } = response as {
        data: {
          getManagedAccountAuditLog: {
            Location: string
          }
        }
      }
      window.open(getAuditLogResponse)
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.log(err)
      auditLogErrorAlert = { alertType: AlertType.AuditLogRetrievalError }
    } finally {
      this.setState({ fetchingAuditLog: false, ...auditLogErrorAlert })
    }
  }

  createAccessKeys = async () => {
    const { managedAccounts } = this.state
    const { selectedEngagement } = this.props

    if (!selectedEngagement || managedAccounts.length === 0) {
      return
    }

    const engagementId = selectedEngagement.Id
    const awsAccountId = managedAccounts[0].Id
    this.setState({ fetchingAccessKeys: true })

    let response
    let accessKeysErrorAlert = {}
    try {
      response = await API.graphql(
        graphqlOperation(createAccessKeysQuery, {
          engagementId,
          awsAccountId
        })
      )

      const {
        data: {
          createManagedAccountAccessKey: {
            AccessKeyId: accessKeyId,
            SecretAccessKey: secretAccessKey
          }
        }
      } = response as {
        data: {
          createManagedAccountAccessKey: {
            AccessKeyId: string
            SecretAccessKey: string
          }
        }
      }
      downloadAccessKeys(accessKeyId, secretAccessKey, awsAccountId)
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.log(err)

      const { errors } = err as { errors: any[] }
      accessKeysErrorAlert = { alertType: this.getAlertTypeFromError(errors) }
    } finally {
      this.setState({ fetchingAccessKeys: false, ...accessKeysErrorAlert })
    }
  }

  getAlertTypeFromError = (errors: any[]) => {
    if (
      errors?.some(error => {
        return error.message.includes(ErrorMessage.ACCESS_KEY_LIMIT_EXCEEDED)
      })
    ) {
      return AlertType.AccessKeysLimitExceeded
    }
    return AlertType.AccessKeysRetrievalError
  }

  createAccount = async () => {
    const { selectedEngagement } = this.props
    const { managedAccounts } = this.state

    this.setState({
      mutationInProgress: true,
      alertType: AlertType.None
    })

    try {
      await API.graphql(
        graphqlOperation(createAccountQuery, {
          engagementId: selectedEngagement!.Id
        })
      )
    } catch (e) {
      // Only catch the error if it was caused by trying to create an account when one already exists
      if (!this.isAccountAlreadyExistsException(e as any)) {
        this.setState({
          alertType: AlertType.AccountCreationError,
          mutationInProgress: false
        })
        throw e
      }
    }
    await this.pollAccountStatus(
      selectedEngagement!,
      managedAccounts[0],
      [Status.Active],
      [Status.Failed, Status.NotExist, Status.Inactive, Status.Closed],
      AlertType.AccountCreationError,
      AlertType.AccountCreationTimeout
    )
    this.setState({ mutationInProgress: false })
  }

  closeAccount = async () => {
    const { selectedEngagement } = this.props
    const { managedAccounts } = this.state

    const engagementId = selectedEngagement?.Id

    this.setState({
      accountClosureModalIsVisible: false,
      mutationInProgress: true
    })

    try {
      await API.graphql(
        graphqlOperation(updateAccountsQuery, {
          engagementId,
          action: ManagedAccountsAction.SCHEDULE_CLOSURE_ALL,
          scheduledClosureDate: moment
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]")
        })
      )
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      this.setState({
        alertType: AlertType.AccountClosureError,
        mutationInProgress: false
      })
      throw err
    }

    await this.pollAccountStatus(
      selectedEngagement!,
      managedAccounts[0],
      [Status.Inactive],
      [
        Status.Closed,
        Status.Completed,
        Status.Failed,
        Status.NotExist,
        Status.Pending
      ],
      AlertType.AccountClosureError,
      AlertType.AccountClosureTimeout
    )

    this.setState({ mutationInProgress: false })
  }

  private getAccountDetailCol = (
    message: MessageDescriptor,
    value: JSX.Element | string | undefined
  ) => {
    return (
      <Box>
        <Box variant="awsui-key-label" data-testid="accountDetailsHeaderField">
          {this.formatMessage(message)}
        </Box>
        <Box data-testid="accountDetailsField">
          {value ?? UNAVAILABLE_FIELD}
        </Box>
      </Box>
    )
  }

  private getAccountDetailIdField = (
    accountDetails: IAccountDetails
  ): string => {
    if (
      !accountDetails.Id ||
      ![Status.Active, Status.Inactive, Status.Closed].includes(
        accountDetails.Status as Status
      )
    ) {
      return UNAVAILABLE_FIELD
    }
    return accountDetails.Id
  }

  private getFederationLink = async (federationPage: FederationPages) => {
    const { managedAccounts, externalCustomer } = this.state
    const { selectedEngagement } = this.props

    if (
      !selectedEngagement ||
      managedAccounts.length === 0 ||
      managedAccounts[0].Status !== Status.Active
    ) {
      return
    }

    const engagementId = selectedEngagement.Id
    const awsAccountId = managedAccounts[0].Id
    this.setState({ fetchingFederationLink: true })

    let response
    let federationErrorAlert = {}
    try {
      const roleName = externalCustomer
        ? ManagedAccountRoleName.CustomerRole
        : ManagedAccountRoleName.SolutionArchitectRole
      response = await API.graphql(
        graphqlOperation(getFederationLinkQuery, {
          engagementId,
          awsAccountId,
          roleName
        })
      )

      const {
        data: {
          getManagedAccountFederationLink: {
            Location: getFederationLinkResponse
          }
        }
      } = response as {
        data: {
          getManagedAccountFederationLink: {
            Location: string
          }
        }
      }
      window.open(
        getFederationLinkResponse.replace(
          FEDERATION_DEFAULT_PAGE_REGEX,
          `$1${federationPage}`
        )
      )
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.log(err)
      federationErrorAlert = { alertType: AlertType.FederationError }
    } finally {
      this.setState({ fetchingFederationLink: false, ...federationErrorAlert })
    }
  }

  private canFederate = (managedAccounts: IAccountDetails[]) => {
    const { canFederateIntoManagedAccount, fetchingAccounts } = this.state

    return (
      canFederateIntoManagedAccount &&
      !fetchingAccounts &&
      managedAccounts.length > 0 &&
      Status.Active === (managedAccounts[0].Status as Status)
    )
  }

  private getStatusIndicator = (status: string) => {
    switch (status) {
      case Status.Active:
        return (
          <StatusIndicator type="success">
            {this.formatMessage(messages.activeStatus)}
          </StatusIndicator>
        )
      case Status.Failed:
        return (
          <StatusIndicator type="error">
            {this.formatMessage(messages.failedStatus)}
          </StatusIndicator>
        )
      case Status.Inactive:
        return (
          <StatusIndicator type="in-progress">
            {this.formatMessage(messages.inactiveStatus)}
          </StatusIndicator>
        )
      case Status.Closed:
        return (
          <StatusIndicator type="stopped">
            {this.formatMessage(messages.closedStatus)}
          </StatusIndicator>
        )
      case Status.Pending:
      case Status.Completed:
      default:
        return (
          <StatusIndicator type="pending">
            {this.formatMessage(messages.pendingStatus)}
          </StatusIndicator>
        )
    }
  }

  private getContainerContent = () => {
    const { fetchingAccounts, mutationInProgress, managedAccounts } = this.state

    if (fetchingAccounts || mutationInProgress) {
      return (
        <StatusIndicator type="loading">
          {this.formatMessage(commonMessages.loading)}
        </StatusIndicator>
      )
    }

    if (managedAccounts.length === 0) {
      return (
        <Box textAlign="center" color="inherit">
          <b data-testid="noAccountMessageHeader">
            {this.formatMessage(messages.noAccountHeader)}
          </b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
            data-testid="noAccountMessageBox"
          >
            {this.formatMessage(messages.noAccountDescription)}
          </Box>
          <Button
            data-testid="createAccountButton"
            disabled={!this.canCreateAccount()}
            onClick={this.createAccount}
            variant="primary"
          >
            {this.formatMessage(messages.createAccountButtonText)}
          </Button>
        </Box>
      )
    }

    const isAccountClosed = !!managedAccounts[0].ClosedOn

    return (
      <ColumnLayout
        columns={3}
        variant="text-grid"
        data-testid="accountDetailsColumns"
      >
        {this.getAccountDetailCol(
          messages.accountId,
          this.getAccountDetailIdField(managedAccounts[0])
        )}
        {this.getAccountDetailCol(
          messages.accountStatus,
          this.getStatusIndicator(managedAccounts[0].Status)
        )}
        {isAccountClosed
          ? this.getAccountDetailCol(
              messages.accountClosureDate,
              managedAccounts[0]?.ClosedOn
                ? getAbsoluteTimestamp(managedAccounts[0].ClosedOn)
                : UNAVAILABLE_FIELD
            )
          : this.getAccountDetailCol(
              messages.accountCreationDate,
              managedAccounts[0]?.CreatedOn
                ? getAbsoluteTimestamp(managedAccounts[0].CreatedOn)
                : UNAVAILABLE_FIELD
            )}
      </ColumnLayout>
    )
  }

  private canGetAuditLog = (account: IAccountDetails) => {
    const {
      fetchingAccounts,
      canRetrieveAuditLogForManagedAccount
    } = this.state

    return (
      canRetrieveAuditLogForManagedAccount &&
      !fetchingAccounts &&
      account &&
      account.Status !== Status.Pending
    )
  }

  private canCreateAccessKeys = (account: IAccountDetails) => {
    const { fetchingAccounts, canCreateAccessKeyForManagedAccount } = this.state

    return (
      canCreateAccessKeyForManagedAccount &&
      !fetchingAccounts &&
      account &&
      account.Status === Status.Active
    )
  }

  // private canFederateForAccessKeys = (account: IAccountDetails) => {
  //   const { fetchingAccounts, externalCustomer } = this.state

  //   return (
  //     !externalCustomer &&
  //     !fetchingAccounts &&
  //     account &&
  //     account.Status === Status.Active
  //   )
  // }

  private canCloseAccount = (account: IAccountDetails) => {
    const {
      fetchingAccounts,
      mutationInProgress,
      canCloseManagedAccount
    } = this.state
    return (
      canCloseManagedAccount &&
      !fetchingAccounts &&
      !mutationInProgress &&
      account &&
      [Status.Active].includes(account.Status as Status)
    )
  }

  private canCreateAccount = () => {
    const {
      fetchingAccounts,
      mutationInProgress,
      canCreateManagedAccount
    } = this.state

    return canCreateManagedAccount && !fetchingAccounts && !mutationInProgress
  }

  private dropDownItems = () => {
    const { fetchingAuditLog, fetchingAccessKeys, managedAccounts } = this.state

    const auditLogButton = {
      text: this.formatMessage(messages.downloadAuditLogButtonText),
      id: "auditLogButton",
      action: this.getAuditLog,
      disabled: !this.canGetAuditLog(managedAccounts[0]),
      loading: fetchingAuditLog
    }
    // const federateForAccessKeysButton = {
    //   text: this.formatMessage(messages.goToAccessKeysButtonText),
    //   id: "accessKeysButton",
    //   action: () => this.getFederationLink(FederationPages.AccessKeys),
    //   disabled: !this.canFederateForAccessKeys(managedAccounts[0]),
    //   external: true
    // }
    const accessKeysButton = {
      text: this.formatMessage(messages.downloadAccessKeysButtonText),
      id: "accessKeysButton",
      action: this.createAccessKeys,
      disabled: !this.canCreateAccessKeys(managedAccounts[0]),
      loading: fetchingAccessKeys
    }
    const closeAccountButton = {
      text: this.formatMessage(messages.getCloseAccountButtonText),
      id: "closeAccountButton",
      action: () => this.setState({ accountClosureModalIsVisible: true }),
      disabled:
        !Config.Features.ManagedAccountsCloseAccount ||
        !this.canCloseAccount(managedAccounts[0])
    }

    return {
      auditLogButton,
      accessKeysButton,
      closeAccountButton
    }
  }

  isAccountAlreadyExistsException = (exception: any) => {
    return (
      Array.isArray(exception.errors) &&
      exception.errors.length > 0 &&
      exception.errors[0].message.includes(
        ACCOUNT_ALREADY_EXISTS_EXCPETION_MESSAGE
      )
    )
  }

  render() {
    const {
      externalCustomer,
      managedAccounts,
      alertType,
      fetchingAuditLog,
      fetchingAccessKeys,
      fetchingFederationLink,
      accountClosureModalIsVisible
    } = this.state

    return (
      <>
        {alertType !== AlertType.None && (
          <AccountDetailsAlert
            alertType={alertType}
            externalCustomer={externalCustomer}
            dismissAlert={() => this.setState({ alertType: AlertType.None })}
          />
        )}
        {managedAccounts[0] && (
          <ConfirmationModal
            onDismiss={() =>
              this.setState({ accountClosureModalIsVisible: false })
            }
            visible={accountClosureModalIsVisible}
            closeAriaLabel={this.formatMessage(
              messages.accountClosureCloseAriaLabel
            )}
            onClickCancel={() =>
              this.setState({ accountClosureModalIsVisible: false })
            }
            onClickConfirm={this.closeAccount}
            confirm={this.formatMessage(messages.accountClosureConfirm)}
            header={this.formatMessage(messages.accountClosureHeader, {
              awsAccountId: managedAccounts[0].Id
            })}
            content={this.formatMessage(messages.accountClosureContent)}
            label={this.formatMessage(messages.accountClosureLabel)}
            description={undefined}
            placeholder={managedAccounts[0].Id}
            modalId="accountClosureModal"
            cancelId="accountClosureCancel"
            confirmId="accountClosureConfirm"
            inputId="accountClosureInput"
          />
        )}
        <Container
          header={
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <Button
                    data-testid="refreshAccountDetailsButton"
                    iconName="refresh"
                    variant="icon"
                    onClick={this.refreshDetails}
                  />
                  <ButtonDropdown
                    data-testid="actionsButtonDropdown"
                    items={
                      Object.values(
                        this.dropDownItems()
                      ) as ButtonDropdownProps.Items
                    }
                    onItemClick={event =>
                      (this.dropDownItems() as any)[event.detail.id].action()
                    }
                    disabled={false}
                    loading={
                      fetchingFederationLink ||
                      fetchingAuditLog ||
                      fetchingAccessKeys
                    }
                  >
                    {this.formatMessage(messages.actionsButtonText)}
                  </ButtonDropdown>
                  <Button
                    data-testid="federateButton"
                    ariaLabel="Go to Migration Hub"
                    onClick={() => {
                      this.getFederationLink(FederationPages.MigrationHub)
                    }}
                    disabled={!this.canFederate(managedAccounts)}
                    variant="primary"
                    loading={fetchingFederationLink}
                  >
                    {this.formatMessage(messages.goToMigrationHubButtonText)}
                  </Button>
                </SpaceBetween>
              }
            >
              {this.formatMessage(messages.header)}
            </Header>
          }
        >
          {this.getContainerContent()}
        </Container>
      </>
    )
  }
}

export default injectIntl(withRouter(AccountDetails))
