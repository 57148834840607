import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Grid } from "@amzn/awsui-components-react/polaris"
import messages from "./AccountManagement.messages"
import Engagements, {
  IEngagement
} from "../../components/engagements/Engagements"
import FrequentlyAskedQuestions from "../../components/help/FrequentlyAskedQuestions"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"
import AccountDetails from "../../components/managedAccounts/AccountDetails"

interface IAccountManagementProps {
  intl: IntlShape
  addBreadCrumb(text: string, href: string): void
  removeBreadCrumb(href: string): void
  setSelectedEngagement(newSelectedEngagement: IEngagement | null): void
  selectedEngagement: IEngagement | null
}
interface IAccountManagementState {
  externalCustomer: boolean
}

export class AccountManagement extends React.Component<
  RouteComponentProps & IAccountManagementProps,
  IAccountManagementState
> {
  private mounted: boolean

  constructor(props: RouteComponentProps & IAccountManagementProps) {
    super(props)
    this.state = { externalCustomer: true }
    this.mounted = false
  }

  async componentDidMount() {
    const {
      addBreadCrumb,
      match,
      intl: { formatMessage }
    } = this.props
    this.mounted = true

    addBreadCrumb(
      formatMessage(messages.accountManagementBreadCrumb),
      match.url
    )
    document.title = formatMessage(messages.accountManagementDocumentTitle)
    await this.checkUserPermissionLevel()
  }

  componentWillUnmount() {
    const { removeBreadCrumb, match } = this.props
    removeBreadCrumb(match.url)
    this.mounted = false
  }

  checkUserPermissionLevel = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()
    if (this.mounted) {
      this.setState({
        externalCustomer: UserPermissionLevel.getInstance().getIsExternalCustomer()
      })
    }
  }

  render() {
    const { selectedEngagement, setSelectedEngagement } = this.props
    const { externalCustomer } = this.state
    const selectedEngagementId = selectedEngagement?.Id

    return (
      <div>
        <Grid gridDefinition={[{ colspan: 12 }]}>
          <Engagements
            setSelectedEngagement={setSelectedEngagement}
            selectedEngagement={selectedEngagement}
          />
        </Grid>
        <Grid gridDefinition={[{ colspan: 12 }]}>
          {selectedEngagementId ? (
            <div>
              <AccountDetails selectedEngagement={selectedEngagement} />
            </div>
          ) : null}
        </Grid>
        <Grid gridDefinition={[{ colspan: 12 }]}>
          <FrequentlyAskedQuestions externalCustomer={externalCustomer} />
        </Grid>
      </div>
    )
  }
}

export default injectIntl(withRouter(AccountManagement))
