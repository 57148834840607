import React from "react"
import Link from "@amzn/awsui-components-react/polaris/link"
import { injectIntl, IntlShape } from "react-intl"
import "./Footer.css"
import { AWSCShortbread } from "@amzn/shortbread"
import messages from "./Footer.messages"
import AppConfig from "../../Config"

const { Config } = AppConfig

interface IFooterProps {
  intl: IntlShape
}
interface IFooterState {
  redirect: string | undefined
  userName: string
  user: any
  mfaEnabled: boolean
  showEnableMfaModal: boolean
  showDisableMfaModal: boolean
  externalCustomer: boolean
}

export class Footer extends React.PureComponent<IFooterProps, IFooterState> {
  onModifyCookiePreferences = () => {
    const domainName = window.location.hostname
    const shortbread = AWSCShortbread({
      parent: document.body,
      domain: ".".concat(domainName)
    })
    shortbread.customizeCookies()
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props

    const currentYear = new Date().getFullYear()

    return (
      <div id="app-footer">
        <footer id="page-footer">
          <div className="footer-link">
            <Link
              id="privacyLinkButton"
              href={Config.Support.PrivacyLinkAddress}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage(messages.privacy)}
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link
              id="termsAndConditionsLinkAddressButton"
              href={Config.Support.TermsAndConditionsLinkAddress}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage(messages.siteTerms)}
            </Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <button
              type="button"
              className="link-button"
              id="cookiePreferencesButton"
              onClick={this.onModifyCookiePreferences}
            >
              {formatMessage(messages.cookiePreferences)}
            </button>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {formatMessage(messages.copyrightNotice, { currentYear })}
          </div>
        </footer>
      </div>
    )
  }
}

export default injectIntl(Footer)
