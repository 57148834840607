export const getAccountDetailsQuery = `query GetAccountDetails($engagementId: ID!) {
  getManagedAccountsByOwnershipId(engagementId: $engagementId) {
    ManagedAccounts {
      OwnershipId
      DataType
      Id
      Status
      RequestId
      CreatedOn
      AccountName
      AccountEmail
    }
  }
}
`
export const createAccountQuery = `query CreateAccount($engagementId: ID!) {
  createManagedAccount(engagementId: $engagementId) {
    RequestId
  }
}
`

export const updateAccountsQuery = `query UpdateManagedAccounts($engagementId: ID!, $action: ManagedAccountsAction!, $scheduledClosureDate: AWSDateTime) {
  updateManagedAccounts(engagementId: $engagementId, action: $action, scheduledClosureDate: $scheduledClosureDate) {
    Success
  }
}`

export const getFederationLinkQuery = `query GetManagedAccountFederationLink($engagementId: ID!, $awsAccountId: String!, $roleName: ManagedAccountRoleName!) {
  getManagedAccountFederationLink(engagementId: $engagementId, awsAccountId: $awsAccountId, roleName: $roleName) { 
    Location
  }
}
`

export const getAuditLogQuery = `query GetManagedAccountAuditLog($engagementId: ID!, $awsAccountId: String!) {
  getManagedAccountAuditLog(engagementId: $engagementId, awsAccountId: $awsAccountId) {
    Location
  }
}
`

export const createAccessKeysQuery = `query CreateManagedAccountAccessKey($engagementId: ID!, $awsAccountId: String!) {
  createManagedAccountAccessKey(engagementId: $engagementId, awsAccountId: $awsAccountId) {
    AccessKeyId
    SecretAccessKey
  }
}
`
