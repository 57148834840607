import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import {
  Box,
  Alert,
  Modal,
  Input,
  InputProps,
  Button,
  SpaceBetween,
  FormField
} from "@amzn/awsui-components-react/polaris"
import { API, graphqlOperation } from "aws-amplify"
import messages from "./DeleteAccountModal.messages"
import commonMessages from "../Common.messages"
import { deleteUser } from "./graphql/mutations"

interface IDeleteAccountModalProps {
  intl: IntlShape
  visible: boolean
  onDismiss: () => void
  showInProgressFlashbar: (header: string, content: string) => void
  showSuccessFlashbarAndSignOut: (header: string) => void
  showErrorFlashbar: (header: string, content: string) => void
}
interface IDeleteAccountModalState {
  hasUserConfirmedAccountDeletion: boolean
  accountDeletionConfirmationString: string
}

export class DeleteAccountModal extends React.Component<
  IDeleteAccountModalProps,
  IDeleteAccountModalState
> {
  constructor(props: IDeleteAccountModalProps) {
    super(props)
    this.state = {
      hasUserConfirmedAccountDeletion: false,
      accountDeletionConfirmationString: ""
    }
  }

  componentWillUnmount() {
    this.resetAccountDeletionConfirmation()
  }

  onDeleteAccountModalDismiss = () => {
    const { onDismiss } = this.props

    this.resetAccountDeletionConfirmation()
    onDismiss()
  }

  private resetAccountDeletionConfirmation = () => {
    this.setState({
      hasUserConfirmedAccountDeletion: false,
      accountDeletionConfirmationString: ""
    })
  }

  private onDeleteAccountConfirmationInput = (
    detail: InputProps.ChangeDetail
  ) => {
    this.setState({ accountDeletionConfirmationString: detail.value })
    this.setState({
      hasUserConfirmedAccountDeletion: detail.value === "delete"
    })
  }

  private deleteAccount = async () => {
    const {
      intl: { formatMessage },
      showInProgressFlashbar,
      showSuccessFlashbarAndSignOut,
      showErrorFlashbar
    } = this.props

    this.onDeleteAccountModalDismiss()

    showInProgressFlashbar(
      formatMessage(messages.deletionInProgressFlashbarTitle),
      formatMessage(messages.deletionInProgressFlashbarContent)
    )

    try {
      await API.graphql(graphqlOperation(deleteUser))

      showSuccessFlashbarAndSignOut(
        formatMessage(messages.deletionSuccessFlashbarTitle)
      )
    } catch (err: any) { // eslint-disable-line
      showErrorFlashbar(
        formatMessage(messages.deletionErrorFlashbarTitle),
        this.getDeleteUserErrorMessage(err)
      )
    }
  }

  private getDeleteUserErrorMessage = (caughtError: any): string => {
    const {
      intl: { formatMessage }
    } = this.props

    const error = caughtError?.errors?.[0]

    if (error === undefined) {
      return (
        caughtError?.message ??
        formatMessage(messages.deletionErrorFlashbarContentUnknownServerError)
      )
    }

    try {
      const nestedError = JSON.parse(error.message)
      const exceptionName = nestedError?.ExceptionName
      const engagementNames = nestedError?.EngagementNames?.join(", ")

      if (
        engagementNames !== undefined &&
        exceptionName === "EngagementsWouldBeOrphanedException"
      ) {
        return formatMessage(
          messages.deletionErrorFlashbarContentPotentialOrphanedEngagements,
          {
            engagementsList: <Box variant="strong">{engagementNames}</Box>
          }
        ) as string
      }

      return (
        nestedError?.Message ??
        formatMessage(messages.deletionErrorFlashbarContentUnknownServerError)
      )
    } catch {
      return error.message
    }
  }

  render() {
    const {
      intl: { formatMessage },
      visible
    } = this.props
    const {
      hasUserConfirmedAccountDeletion,
      accountDeletionConfirmationString
    } = this.state

    return (
      <>
        <Modal
          id="deleteAccountModal"
          header={formatMessage(messages.title)}
          visible={visible}
          onDismiss={this.onDeleteAccountModalDismiss}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  data-testid="cancelDeleteAccountButton"
                  variant="normal"
                  onClick={this.onDeleteAccountModalDismiss}
                >
                  {formatMessage(commonMessages.cancel)}
                </Button>
                <Button
                  data-testid="confirmDeleteAccountButton"
                  disabled={!hasUserConfirmedAccountDeletion}
                  variant="primary"
                  onClick={async () => {
                    await this.deleteAccount()
                  }}
                >
                  {formatMessage(messages.deleteButtonContent)}
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <Alert type="warning">{formatMessage(messages.warningContent)}</Alert>
          <br />
          <FormField
            label={formatMessage(messages.confirmationInputLabel)}
            stretch
          >
            <Input
              data-testid="deleteAccountInput"
              value={accountDeletionConfirmationString}
              onChange={({ detail }) =>
                this.onDeleteAccountConfirmationInput(detail)
              }
              placeholder={formatMessage(messages.confirmationInputPlaceholder)}
            />
          </FormField>
        </Modal>
      </>
    )
  }
}

export default injectIntl(DeleteAccountModal)
