import React from "react"
import { Redirect } from "react-router-dom"
import { injectIntl, IntlShape } from "react-intl"
import {
  Link,
  ButtonDropdown,
  ButtonDropdownProps,
  Flashbar,
  FlashbarProps,
  Button,
  Modal,
  Icon
} from "@amzn/awsui-components-react/polaris"
import { Auth } from "aws-amplify"
import EnableMfa from "../auth/EnableMfa"
import messages from "./Header.messages"
import commonMessages from "../Common.messages"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"
import DeleteAccountModal from "./DeleteAccountModal"
import ServiceMessageBanner from "./ServiceMessageBanner"
import "./Header.css"
import AppConfig from "../../Config"

interface IHeaderProps {
  intl: IntlShape
}
interface IHeaderState {
  redirect: string | undefined
  userName: string
  user: any
  mfaEnabled: boolean
  showEnableMfaModal: boolean
  showDisableMfaModal: boolean
  showDeleteAccountModal: boolean
  externalCustomer: boolean
  flashbarItems: FlashbarProps.MessageDefinition[]
}

export class Header extends React.Component<IHeaderProps, IHeaderState> {
  willUnmount: boolean = false

  constructor(props: IHeaderProps) {
    super(props)
    this.state = {
      redirect: undefined,
      userName: "",
      user: undefined,
      mfaEnabled: false,
      showEnableMfaModal: false,
      showDisableMfaModal: false,
      showDeleteAccountModal: false,
      externalCustomer: false,
      flashbarItems: []
    }
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await this.checkUserPermissionLevel()
      let userName: string
      const {
        attributes: { email },
        username
      } = user
      if (email) {
        userName = email
      } else {
        userName = username
      }

      if (!this.willUnmount) {
        this.setState({ userName, user })
      }
    } catch (err: any) { // eslint-disable-line
      this.catchUnauthenticatedUserError(err)
    }

    await this.updateMfaState()
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  private signOut = async () => {
    const { externalCustomer } = this.state
    await Auth.signOut({ global: externalCustomer })
    this.setState({ redirect: "/" })
  }

  private showSuccessFlashbarAndSignOut = async (header: string) => {
    const {
      intl: { formatMessage }
    } = this.props

    let seconds = 5
    const interval = setInterval(async () => {
      this.setState({
        flashbarItems: [
          {
            header,
            content: formatMessage(
              messages.accountDeletionSuccessFlashbarContent,
              { seconds }
            ),
            type: "success",
            loading: false,
            dismissible: false,
            action: (
              <Button
                onClick={async () => {
                  await this.signOut()
                }}
              >
                {formatMessage(
                  messages.accountDeletionSuccessFlashbarSignoutButtonContent
                )}
              </Button>
            )
          }
        ]
      })

      if (seconds === 0) {
        clearInterval(interval)
        await this.signOut()
      }

      seconds -= 1
    }, 1000)
  }

  catchUnauthenticatedUserError = (error: any) => {
    if (
      error ||
      error.includes("No current user") ||
      error.includes("not authenticated")
    ) {
      if (!this.willUnmount) {
        this.setState({ redirect: "/" })
      }
    }
  }

  updateMfaState = async () => {
    try {
      const { user } = this.state
      await Auth.currentAuthenticatedUser()
      const preferredMfa = await Auth.getPreferredMFA(user)

      if (!this.willUnmount) {
        this.setState({
          mfaEnabled: preferredMfa === "SOFTWARE_TOKEN_MFA"
        })
      }
    } catch (err: any) { // eslint-disable-line
      this.catchUnauthenticatedUserError(err)
    }
  }

  onDropDownItemClick = async (
    ev: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ) => {
    try {
      switch (ev.detail.id) {
        case "signout":
          await this.signOut()
          break
        case "deleteAccount":
          this.showDeleteAccountModal()
          break
        case "enableMfa":
          this.showEnableMfaModal()
          break
        case "disableMfa":
          this.showDisableMfaModal()
          break

        default:
          break
      }
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line
    console.error(err)
    }
  }

  showDeleteAccountModal = () => {
    this.setState({ showDeleteAccountModal: true })
  }

  showEnableMfaModal = () => {
    this.setState({ showEnableMfaModal: true })
  }

  showDisableMfaModal = () => {
    this.setState({ showDisableMfaModal: true })
  }

  onConfirmDisableMfa = async () => {
    try {
      const { user } = this.state
      await Auth.currentSession()
      await Auth.setPreferredMFA(user, "NOMFA")
      this.setState({ mfaEnabled: false })
    } catch (err: any) { // eslint-disable-line
      this.catchUnauthenticatedUserError(err)
    }
  }

  onDeleteAccountModalDismiss = () => {
    this.setState({ showDeleteAccountModal: false })
  }

  onEnableMfaModalDismiss = () => {
    this.setState({ showEnableMfaModal: false })
    this.updateMfaState()
  }

  onDisableMfaModalDismiss = () => {
    this.setState({ showDisableMfaModal: false })
    this.updateMfaState()
  }

  checkUserPermissionLevel = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()
    this.setState({
      externalCustomer: UserPermissionLevel.getInstance().getIsExternalCustomer()
    })
  }

  private getDropdownItems = () => {
    const {
      intl: { formatMessage }
    } = this.props
    const { mfaEnabled, externalCustomer } = this.state
    const dropdownItems = []

    const signout = {
      text: formatMessage(messages.headerSignOut),
      id: "signout"
    }

    const deleteAccount = {
      text: formatMessage(messages.headerDeleteAccount),
      id: "deleteAccount"
    }

    const enableMfa = {
      text: formatMessage(messages.headerEnableMfa),
      id: "enableMfa"
    }

    const disableMfa = {
      text: formatMessage(messages.headerDisableMfa),
      id: "disableMfa"
    }

    const showMfa = externalCustomer

    if (showMfa) {
      if (mfaEnabled) {
        dropdownItems.push(disableMfa)
      } else {
        dropdownItems.push(enableMfa)
      }
    }

    if (externalCustomer) {
      dropdownItems.push(deleteAccount)
    }

    dropdownItems.push(signout)

    return dropdownItems
  }

  getDisableMfaModalContent = () => {
    const {
      intl: { formatMessage }
    } = this.props
    const { mfaEnabled } = this.state

    if (!mfaEnabled) {
      return (
        <Flashbar
          items={[
            {
              type: "success",
              content: formatMessage(messages.headerMfaDisabledMessage),
              dismissible: false
            }
          ]}
        />
      )
    }

    return (
      <Flashbar
        id="confirmDisableMfaFlash"
        items={[
          {
            type: "warning",
            content: formatMessage(
              messages.headerDisableMfaConfirmationMessage
            ),
            dismissible: false,
            action: (
              <Button onClick={this.onConfirmDisableMfa}>
                {formatMessage(commonMessages.confirm)}
              </Button>
            )
          }
        ]}
      />
    )
  }

  showMfaBaseOnUserRole = () => {
    const {
      intl: { formatMessage }
    } = this.props
    const {
      showEnableMfaModal,
      showDisableMfaModal,
      externalCustomer
    } = this.state
    const showMfa = externalCustomer
    if (showMfa) {
      return (
        <>
          <Modal
            id="enableMfaModal"
            header={formatMessage(messages.headerEnableMfaModalTitle)}
            visible={showEnableMfaModal}
            onDismiss={this.onEnableMfaModalDismiss}
          >
            <EnableMfa />
          </Modal>
          <Modal
            id="disableMfaModal"
            header={formatMessage(messages.headerDisableMfaModalTitle)}
            visible={showDisableMfaModal}
            onDismiss={this.onDisableMfaModalDismiss}
          >
            {this.getDisableMfaModalContent()}
          </Modal>
        </>
      )
    }
    return <></>
  }

  private showInProgressFlashbar = (header?: string, content?: string) => {
    this.setState({
      flashbarItems: [
        {
          header,
          content,
          type: "info",
          loading: true,
          dismissible: false
        }
      ]
    })
  }

  private showErrorFlashbar = (header?: string, content?: string) => {
    this.setState({
      flashbarItems: [
        {
          id: "header-error-flashbar",
          header,
          content,
          type: "error",
          loading: false,
          dismissible: true,
          onDismiss: () => this.setState({ flashbarItems: [] })
        }
      ]
    })
  }

  render() {
    const {
      redirect,
      userName,
      showDeleteAccountModal,
      flashbarItems,
      externalCustomer
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    if (redirect) {
      return <Redirect to={redirect} />
    }

    return (
      <div id="app-header" className="z2">
        <ServiceMessageBanner />
        <Flashbar items={flashbarItems} />
        <header id="page-header">
          <div className="header-logo-item">
            <Link href="/">
              <div className="header-logo" />
            </Link>
          </div>
          <div className="header-nav">
            {externalCustomer ? (
              <div className="header-nav-item">
                <Link
                  data-testid="headerSupportEmailLink"
                  href={`mailto:${AppConfig.Config.Support.EmailAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatMessage(messages.headerContactSupport)}&nbsp;
                  <Icon
                    variant="link"
                    name="envelope"
                    id="header-contact-support-icon"
                  />
                </Link>
              </div>
            ) : null}
            <div className="header-nav-item">
              <ButtonDropdown
                data-testid="headerUsernameDropDown"
                items={this.getDropdownItems()}
                onItemClick={this.onDropDownItemClick}
              >
                {userName}
              </ButtonDropdown>
            </div>
          </div>
        </header>
        <DeleteAccountModal
          visible={showDeleteAccountModal}
          onDismiss={this.onDeleteAccountModalDismiss}
          showInProgressFlashbar={this.showInProgressFlashbar}
          showSuccessFlashbarAndSignOut={this.showSuccessFlashbarAndSignOut}
          showErrorFlashbar={this.showErrorFlashbar}
        />
        {this.showMfaBaseOnUserRole()}
      </div>
    )
  }
}

export default injectIntl(Header)
