import { defineMessages } from "react-intl"

const messages = defineMessages({
  onPremCollectorsDocumentTitle: {
    id: "onPremCollectors.documentTitle",
    defaultMessage: "On-premises data collectors"
  },
  onPremCollectorsBreadCrumb: {
    id: "onPremCollectors.breadcrumb",
    defaultMessage: "On-premises data collectors"
  }
})

export default messages
