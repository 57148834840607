import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import {
  Box,
  Grid,
  Button,
  Modal,
  ModalProps
} from "@amzn/awsui-components-react/polaris"
import { injectIntl, IntlShape } from "react-intl"
import { API, graphqlOperation } from "aws-amplify"
import { GraphQLResult } from "@aws-amplify/api-graphql"
import messages from "./OptInConsentModal.messages"
import { modifyCustomerDataShareConsent } from "./graphql/mutations"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"
import { listEngagements } from "./graphql/queries"
import common from "../Common.messages"

interface IEngagementDetail {
  Id: string
  Name: string
  Customer: string
  DataShareConsentStatus: string
}

interface IListEngagementData {
  listEngagements: {
    Engagements: IEngagementDetail[]
  }
}

interface IOptInConsentModalProps {
  intl: IntlShape
}
interface IOptInConsentModalState {
  engagementsList: IEngagementDetail[]
  loading: boolean
  errorExist: boolean
  externalCustomer: boolean
}

export class OptInConsentModal extends React.Component<
  RouteComponentProps & IOptInConsentModalProps,
  IOptInConsentModalState
> {
  private readonly optedIn: string = "OptedIn"

  private readonly notSet: string = "NotSet"

  constructor(props: RouteComponentProps & IOptInConsentModalProps) {
    super(props)
    this.state = {
      loading: false,
      engagementsList: [],
      externalCustomer: false,
      errorExist: false
    }
  }

  async componentDidMount() {
    if (await this.isExternalCustomer()) {
      this.getNotSetListEngagementsData()
    }
  }

  isExternalCustomer = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()
    const isExternal = UserPermissionLevel.getInstance().getIsExternalCustomer()
    this.setState({
      externalCustomer: isExternal
    })
    return isExternal
  }

  private getNotSetListEngagementsData = async () => {
    let result: IEngagementDetail[] = []
    try {
      const response = await API.graphql(graphqlOperation(listEngagements))
      const engagementData = response as {
        data: IListEngagementData
      }
      result = engagementData.data.listEngagements.Engagements
      this.setState({
        engagementsList: result.filter(
          x =>
            !x.DataShareConsentStatus ||
            x.DataShareConsentStatus.toLowerCase() === this.notSet.toLowerCase()
        )
      })
      this.setState({
        loading: false,
        errorExist: false
      })
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line no-console
      console.error(err)
      this.setState({
        errorExist: true
      })
    }
  }

  private handleDismiss = (detail: ModalProps.DismissDetail) => {
    // Only close modal when close button clicked
    // Do not close modal when click outside modal or esc key pressed
    if (detail.reason === "closeButton") {
      this.optInToListedEngagements()
    }
  }

  // Listed engagements should be only DatashareConsent NotSet
  private optInToListedEngagements = async () => {
    const { engagementsList } = this.state
    try {
      const mutationResponses = []
      for (let i = 0; i < engagementsList.length; i += 1) {
        mutationResponses.push(
          API.graphql(
            graphqlOperation(modifyCustomerDataShareConsent, {
              id: engagementsList[i].Id,
              customerDataShareConsentStatus: this.optedIn
            })
          ) as Promise<GraphQLResult>
        )
      }

      this.setState({
        engagementsList: []
      })

      await Promise.all(mutationResponses)
    } catch (err: any) { // eslint-disable-line
      if (err.errors && err.errors[0].errorType === "MappingTemplate") {
        // eslint-disable-next-line no-console
        console.log("Already Opted In")
      } else {
        // eslint-disable-next-line no-console
        console.error(err)
        this.setState({
          errorExist: true
        })
      }
    }
  }

  render() {
    const {
      intl: { formatMessage }
    } = this.props
    const {
      loading,
      externalCustomer,
      errorExist,
      engagementsList
    } = this.state
    return (
      <div>
        <Modal
          id="consentNotificationModal"
          onDismiss={({ detail }) => this.handleDismiss(detail)}
          visible={
            externalCustomer &&
            engagementsList.length > 0 &&
            !loading &&
            !errorExist
          }
          size="medium"
          footer={
            <Grid gridDefinition={[{ colspan: 4, push: 8 }]}>
              <Box float="right">
                <Button
                  id="consentConfirmationOkButton"
                  variant="primary"
                  onClick={this.optInToListedEngagements}
                >
                  {formatMessage(common.ok)}
                </Button>
              </Box>
            </Grid>
          }
          header={formatMessage(messages.EMAHeader)}
        >
          {formatMessage(messages.EMAOptInInformation)}
        </Modal>
      </div>
    )
  }
}

export default injectIntl(withRouter(OptInConsentModal))
