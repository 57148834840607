import { defineMessages } from "react-intl"

const messages = defineMessages({
  title: {
    id: "deleteAccountModal.title",
    defaultMessage: "Are you sure you want to delete your account?"
  },
  warningContent: {
    id: "deleteAccountModal.warningContent",
    defaultMessage:
      "If you delete your account, you will lose access to all your engagements. Other users may continue to access engagements shared with them; to revoke shared access, you must manually delete those engagements."
  },
  confirmationInputLabel: {
    id: "deleteAccountModal.confirmationInputLabel",
    defaultMessage:
      'To confirm deletion of your user account, enter "delete" in the text input field'
  },
  confirmationInputPlaceholder: {
    id: "deleteAccountModal.confirmationInputPlaceholder",
    defaultMessage: "delete"
  },
  deleteButtonContent: {
    id: "deleteAccountModal.deleteButtonContent",
    defaultMessage: "Delete account"
  },
  deletionInProgressFlashbarTitle: {
    id: "deleteAccountModal.deletionInProgressFlashbarTitle",
    defaultMessage: "Account deletion in progress"
  },
  deletionInProgressFlashbarContent: {
    id: "deleteAccountModal.deletionInProgressFlashbarContent",
    defaultMessage: "This may take a few moments"
  },
  deletionSuccessFlashbarTitle: {
    id: "deleteAccountModal.deletionSuccessFlashbarTitle",
    defaultMessage: "Account deletion succeeded"
  },
  deletionErrorFlashbarTitle: {
    id: "deleteAccountModal.deletionErrorFlashbarTitle",
    defaultMessage: "Account deletion failed"
  },
  deletionErrorFlashbarContentPotentialOrphanedEngagements: {
    id:
      "deleteAccountModal.deletionErrorFlashbarContentPotentialOrphanedEngagements",
    defaultMessage:
      "Before deleting your account, you must delete all engagements that are only accessible to you: {engagementsList}"
  },
  deletionErrorFlashbarContentUnknownServerError: {
    id: "deleteAccountModal.deletionErrorFlashbarContentUnknownServerError",
    defaultMessage: "An unknown server error has occurred"
  }
})

export default messages
