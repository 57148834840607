/* eslint-disable import/prefer-default-export */
export const modifyCustomerDataShareConsent = `mutation ModifyCustomerDataShareConsent(
  $id: ID!,
  $customerDataShareConsentStatus: CustomerDataShareConsentMutationStatus!
) {
    modifyCustomerDataShareConsent( 
      id:$id,
      customerDataShareConsentStatus: $customerDataShareConsentStatus
    ) {
      Id
    }
}
`

/* eslint-disable import/prefer-default-export */
export const deleteEngagement = `mutation deleteEngagement($id: ID!) {
    deleteEngagement(id: $id)
}
`
