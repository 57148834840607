import { defineMessages } from "react-intl"

const messages = defineMessages({
  dataShareConsentOptionsHeader: {
    id: "customerDataShareConsentOptions.header",
    defaultMessage: "Enhanced Migration Assistance"
  },
  customerDecisionFieldTitle: {
    id: "customerDataShareConsentOptions.decisionFieldTitle",
    defaultMessage: "Option for Enhanced Migration Assistance"
  },
  enhancedMigrationAssistance: {
    id: "customerDataShareConsentOptions.enhancedMigrationAssistance",
    defaultMessage: "Enhanced Migration Assistance"
  },
  consentModalConfirmationTitle: {
    id: "customerDataShareConsentOptions.consentModalConfirmationTitle",
    defaultMessage: "Confirmation"
  },
  meaDescription: {
    id: "customerDataShareConsentOptions.description",
    defaultMessage:
      "The business case for AWS is strongest when Migration Evaluator can work with your account manager and experts from other AWS service teams. You may choose to opt out of sharing your inventory and utilization details by disabling Enhanced Migration Assistance."
  },
  confirmOptOutHint: {
    id: "customerDataShareConsentOptions.confirmOptOutHint",
    defaultMessage:
      "Opting out of Enhanced Migration Assistance will limit your white-gloved AWS support to only the assigned Migration Evaluator solution architect and program manager. If you are working with an AWS account team, they will no longer have access to your Migration Evaluator engagement."
  },
  consentStatusUpdateFail: {
    id: "customerDataShareConsentOptions.consentStatusUpdateFail",
    defaultMessage:
      "Error updating consent status; please try again or contact your account manager"
  },
  consentStatusUpdateSuccess: {
    id: "customerDataShareConsentOptions.consentStatusUpdateSuccess",
    defaultMessage: "Successfully updated opt in status"
  },
  termsAndConditionsLinkDescription: {
    id: "customerDataShareConsentOptions.termsAndConditionsLinkDescription",
    defaultMessage: "Learn more about the terms and conditions"
  }
})

export default messages
