import { defineMessages } from "react-intl"

const messages = defineMessages({
  title: {
    id: "frequentlyAskedQuestions.title",
    defaultMessage: "Frequently asked questions"
  },
  howToFindOutWhoIsAccountManager: {
    id: "frequentlyAskedQuestions.howToFindOutWhoIsAccountManager",
    defaultMessage:
      "How do I find out who my Migration Evaluator program manager is?"
  },
  howToFindOutWhoIsAccountManagerAnswer: {
    id: "frequentlyAskedQuestions.howToFindOutWhoIsAccountManagerAnswer",
    defaultMessage: "Contact support at "
  },
  howToRenewAccessKey: {
    id: "frequentlyAskedQuestions.howToRenewAccessKey",
    defaultMessage: "How do I request my access key to be renewed?"
  },
  howToRenewAccessKeyAnswer: {
    id: "frequentlyAskedQuestions.howToRenewAccessKeyAnswer",
    defaultMessage:
      "Contact your Migration Evaluator program manager to renew your access key."
  },
  howToGetAccessToExistingEngagement: {
    id: "frequentlyAskedQuestions.howToGetAccessToExistingEngagement",
    defaultMessage:
      "How do I get access to an existing Migration Evaluator engagement?"
  },
  howToGetAccessToExistingEngagementAnswer: {
    id: "frequentlyAskedQuestions.howToGetAccessToExistingEngagementAnswer",
    defaultMessage:
      "Request an assessment via {assessmentCentralLinkAddress} and one of our Migration Evaluator specialists will help you."
  },
  assessmentCentral: {
    id: "frequentlyAskedQuestions.assessmentCentral",
    defaultMessage: "Assessment Central"
  },
  cannotSeeCustomerData: {
    id: "frequentlyAskedQuestions.cannotSeeCustomerData",
    defaultMessage:
      "I have been added to an engagement; how come I cannot see my customer's data?"
  },
  cannotSeeCustomerDataAnswer: {
    id: "frequentlyAskedQuestions.cannotSeeCustomerDataAnswer",
    defaultMessage:
      'Customer data will be shared with AWS account teams after the customer provides authorization. Reach out to your customer and request that they opt into Enhanced Migration Assistance within their "My engagements" dashboard.'
  },
  howToRequestEngagementForCustomer: {
    id: "frequentlyAskedQuestions.howToRequestEngagementForCustomer",
    defaultMessage: "How do I request an engagement for my customer?"
  },
  howToRequestEngagementForCustomerAnswer: {
    id: "frequentlyAskedQuestions.howToRequestEngagementForCustomerAnswer",
    defaultMessage:
      "Please review our {howToEngageWikiLinkAddress} and follow the steps to add Migration Evaluator to your SFDC opportunity."
  },
  wikiPage: {
    id: "frequentlyAskedQuestions.wikiPage",
    defaultMessage: "Wiki page"
  },
  howToLearnMore: {
    id: "frequentlyAskedQuestions.howToLearnMore",
    defaultMessage: "How do I learn more about Migration Evaluator?"
  },
  howToLearnMoreAnswer: {
    id: "frequentlyAskedQuestions.howToLearnMoreAnswer",
    defaultMessage:
      "Please go to our {trainingWikiLinkAddress}. Here you will find a collection of 100 and 200 level classes. If you have additional questions, we also have {officeHoursLinkAddress}."
  },
  trainingWikiPage: {
    id: "frequentlyAskedQuestions.trainingWikiPage",
    defaultMessage: "Training wiki page"
  },
  officeHours: {
    id: "frequentlyAskedQuestions.officeHours",
    defaultMessage: "Office hours"
  },
  termsAndConditionsDescriptionPart1: {
    id: "frequentlyAskedQuestions.termsAndConditionsDescription1",
    defaultMessage:
      "Use of Migration Evaluator is subject to the terms of the AWS Customer Agreement and AWS Service Terms. Customer data processed by AWS during your use of Migration Evaluator is subject to the data protection terms in Section 1 of the AWS Service Terms and will be stored in AWS US East (N. Virginia). By using Migration Evaluator, you are authorizing an AWS solutions architect to access your data in order to provide the service to you. You can share your data in Migration Evaluator with your AWS account team by using the Enhanced Migration Assistance feature."
  },
  termsAndConditionsDescriptionPart2: {
    id: "frequentlyAskedQuestions.termsAndConditionsDescription2",
    defaultMessage:
      "You can configure data collected from the Migration Evaluator Collector to be sent to AWS Application Discovery Service (ADS). Please note that the AWS region for AWS Application Discovery Service may be different than the AWS region for Migration Evaluator or your source server, which could result in data being sent cross-region."
  },
  termsAndConditionsLinkDescription: {
    id: "frequentlyAskedQuestions.termsAndConditionsLinkDescription",
    defaultMessage: "Learn more about the terms and conditions"
  }
})

export default messages
