import { defineMessages } from "react-intl"

const messages = defineMessages({
  discoverDocumentTitle: {
    id: "discover.documentTitle",
    defaultMessage: "Migration Evaluator Discovery"
  },
  discoverTitle: {
    id: "discover.title",
    defaultMessage: "Migration Evaluator Discovery"
  },
  discoverStartDiscovery: {
    id: "discover.startDiscovery",
    defaultMessage: "Start discovery"
  },
  discoverDiscoverServers: {
    id: "discover.discoverServers",
    defaultMessage: "Discover servers"
  },
  discoverMeasurePerformance: {
    id: "discover.measurePerformance",
    defaultMessage: "Measure performance"
  },
  discoverCaptureLicenseAndEnvironments: {
    id: "discover.captureLicenseAndEnvironments",
    defaultMessage: "Capture license and environments"
  }
})

export default messages
