import { defineMessages } from "react-intl"

const messages = defineMessages({
  emsNavSectionDiscover: {
    id: "ems.nav.section.discover",
    defaultMessage: "Discover"
  },
  emsNavSectionDashboard: {
    id: "ems.nav.section.dashboard",
    defaultMessage: "Dashboard"
  },
  emsNavItemOnPremDataCollectors: {
    id: "ems.nav.item.onPremDataCollectors",
    defaultMessage: "On-premises data collectors"
  },
  emsNavItemSelfReportedFiles: {
    id: "ems.nav.item.selfReportedFiles",
    defaultMessage: "Self-reported files"
  },
  emsNavItemTools: {
    id: "ems.nav.item.tools",
    defaultMessage: "Tools"
  },
  emsNavItemMyEngagements: {
    id: "ems.nav.item.myEngagements",
    defaultMessage: "My engagements"
  },
  emsNavItemAccountManagement: {
    id: "ems.nav.item.accountManagement",
    defaultMessage: "Account management"
  },
  breadCrumbRootName: {
    id: "breadcrumb.rootName",
    defaultMessage: "Migration Evaluator"
  }
})

export default messages
