import React from "react"
import { injectIntl, IntlShape, MessageDescriptor } from "react-intl"
import { v4 as uuidv4 } from "uuid"
import { Flashbar, FlashbarProps } from "@amzn/awsui-components-react/polaris"
import { API, graphqlOperation } from "aws-amplify"
import messages from "./ServiceMessageBanner.messages"
import { GetConsoleWebConfig } from "./graphql/queries"
import ConsoleWebConfig from "./ConsoleWebConfig"

interface IServiceMessageBannerProps {
  intl: IntlShape
}

interface IServiceMessageBannerState {
  flashbarItems: FlashbarProps.MessageDefinition[]
}

interface IConsoleWebConfigBlob {
  headers: {
    ConfigurationVersion: number
  }
  body: {
    ServiceMessageCode?: number
  }
}

interface IConsoleWebConfigRawBlob {
  getConsoleWebConfig: {
    headers: any
    body: string
  }
}

export class ServiceMessageBanner extends React.Component<
  IServiceMessageBannerProps,
  IServiceMessageBannerState
> {
  private readonly MESSAGE_MAP: Map<number, MessageDescriptor> = new Map([
    [1, messages.serviceMessageTechnicalIssues],
    [2, messages.serviceMessageServiceMaintenance]
  ])

  private readonly API_POLL_INTERVAL_MS = 10000

  private readonly CLIENT_ID: String

  private readonly CONFIGURATION_VERSION_REGEX = /Configuration-Version=([\d]*)/

  private configurationVersion: number

  private consoleWebConfig: ConsoleWebConfig

  private pollInterval: NodeJS.Timeout

  constructor(props: IServiceMessageBannerProps) {
    super(props)

    this.CLIENT_ID = uuidv4()
    this.configurationVersion = 0
    this.consoleWebConfig = new ConsoleWebConfig()

    this.state = {
      flashbarItems: []
    }

    this.pollInterval = setInterval(async () => {
      /* istanbul ignore next */
      await this.queryForConsoleConfigAndUpdateBanner()
    }, this.API_POLL_INTERVAL_MS)
  }

  componentDidMount = async () => {
    await this.queryForConsoleConfigAndUpdateBanner()
  }

  componentWillUnmount = () => {
    clearInterval(this.pollInterval)
  }

  private queryForConsoleConfigAndUpdateBanner = async () => {
    const {
      intl: { formatMessage }
    } = this.props

    try {
      const response = await API.graphql<IConsoleWebConfigRawBlob>(
        graphqlOperation(GetConsoleWebConfig, {
          clientId: this.CLIENT_ID,
          configurationVersion: this.configurationVersion
        })
      )

      const consoleWebConfigRawBlob = response as {
        data: IConsoleWebConfigRawBlob
      }

      const consoleWebConfigBlob = this.processRawWebConfigBlob(
        consoleWebConfigRawBlob.data
      )

      // Always store the latest received configuration version
      this.configurationVersion =
        consoleWebConfigBlob.headers.ConfigurationVersion

      // This means that a ServiceMessageCode was not sent,
      // most likely because we already have the latest version
      // https://docs.aws.amazon.com/appconfig/2019-10-09/APIReference/API_GetConfiguration.html
      if (consoleWebConfigBlob.body.ServiceMessageCode === undefined) {
        return
      }

      this.consoleWebConfig = new ConsoleWebConfig(
        consoleWebConfigBlob.body.ServiceMessageCode
      )

      // '0' means that everything is okay, don't need to display anything
      if (consoleWebConfigBlob.body.ServiceMessageCode === 0) {
        this.setState({
          flashbarItems: []
        })

        return
      }

      const messageDescriptor = this.MESSAGE_MAP.get(
        this.consoleWebConfig.serviceMessageCode
      )

      if (messageDescriptor === undefined) {
        // eslint-disable-next-line
        console.warn(
          `Service message code ${
            this.consoleWebConfig.serviceMessageCode
          } could not be mapped to in ${JSON.stringify(this.MESSAGE_MAP)}`
        )
        return
      }

      this.setState({
        flashbarItems: [
          {
            type: "warning",
            dismissible: false,
            content: formatMessage(messageDescriptor)
          }
        ]
      })
    } catch (err: any) { // eslint-disable-line
      // eslint-disable-next-line
      console.error(err)
    }
  }

  private processRawWebConfigBlob = (
    rawConsoleBlob: IConsoleWebConfigRawBlob
  ): IConsoleWebConfigBlob => {
    return {
      headers: {
        ConfigurationVersion: this.extractConfigurationVersion(rawConsoleBlob)
      },
      body: {
        ServiceMessageCode: this.extractServiceMessageCode(rawConsoleBlob)
      }
    } as IConsoleWebConfigBlob
  }

  private extractConfigurationVersion = (
    rawConsoleBlob: IConsoleWebConfigRawBlob
  ): number => {
    const headersBlob = rawConsoleBlob.getConsoleWebConfig.headers
    const configurationVersionRegexMatch = this.CONFIGURATION_VERSION_REGEX.exec(
      headersBlob
    )

    return configurationVersionRegexMatch === null
      ? 0
      : parseInt(configurationVersionRegexMatch[1], 10)
  }

  private extractServiceMessageCode = (
    rawConsoleBlob: IConsoleWebConfigRawBlob
  ): number | undefined => {
    try {
      const bodyBlob = JSON.parse(rawConsoleBlob.getConsoleWebConfig.body)
      return bodyBlob.ServiceMessageCode
    } catch {
      // This means that a ServiceMessageCode was not sent,
      // most likely because we already have the latest version
      // https://docs.aws.amazon.com/appconfig/2019-10-09/APIReference/API_GetConfiguration.html
      return undefined
    }
  }

  render() {
    const { flashbarItems } = this.state

    return (
      <>
        <Flashbar id="serviceMessageBanner" items={flashbarItems} />
      </>
    )
  }
}

export default injectIntl(ServiceMessageBanner)
