import { Box, Spinner } from "@amzn/awsui-components-react/polaris"
import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom"

import { Hub } from "aws-amplify"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"
import commonMessages from "../Common.messages"
import messages from "./SingleSignOnCallbackHandler.messages"

interface ISingleSignOnCallbackHandlerProps {
  intl: IntlShape
}

interface ISingleSignOnCallbackHandlerState {
  federateAuthSuccess: boolean
  unauthorizedInternalUser: boolean
  loading: boolean
}

export class SingleSignOnCallbackHandler extends React.PureComponent<
  ISingleSignOnCallbackHandlerProps & RouteComponentProps,
  ISingleSignOnCallbackHandlerState
> {
  constructor(props: ISingleSignOnCallbackHandlerProps & RouteComponentProps) {
    super(props)
    this.state = {
      federateAuthSuccess: false,
      loading: true,
      unauthorizedInternalUser: false
    }
  }

  async componentDidMount() {
    const {
      intl: { formatMessage }
    } = this.props
    document.title = formatMessage(messages.redirectAuthDocumentTitle)

    this.setState({
      loading: true
    })
    Hub.listen("auth", data => {
      switch (data.payload.event) {
        case "signIn":
          this.checkUnauthorizedInternalUser()
          break
        case "signIn_failure":
          this.setState({
            unauthorizedInternalUser: true,
            federateAuthSuccess: false,
            loading: false
          })
          break
        case "signOut":
          break
        default:
          break
      }
    })
  }

  checkUnauthorizedInternalUser = async () => {
    await UserPermissionLevel.getInstance().checkUserPermissionLevel()
    if (UserPermissionLevel.getInstance().getIsUnauthorizedInternalUser()) {
      this.setState({
        unauthorizedInternalUser: true,
        federateAuthSuccess: false,
        loading: false
      })
    } else {
      this.setState({
        unauthorizedInternalUser: false,
        federateAuthSuccess: true,
        loading: false
      })
    }
  }

  waitOnRedirectAuthEvent = () => {
    const {
      intl: { formatMessage }
    } = this.props

    return (
      <div className="awsui text-center">
        <span className="v-center">
          <Box color="text-status-inactive">
            <Spinner data-testid="ssoCallbackSpinner" />{" "}
            {formatMessage(commonMessages.loading)}
          </Box>
        </span>
      </div>
    )
  }

  getFailStateContainer = () => {
    const {
      intl: { formatMessage }
    } = this.props
    return (
      <div id="unauthorizedMessage" className="awsui text-center">
        <span className="v-center">
          <Box color="text-status-inactive">
            {formatMessage(messages.userNotAuthorized)}
          </Box>
        </span>
      </div>
    )
  }

  render() {
    const {
      federateAuthSuccess,
      loading,
      unauthorizedInternalUser
    } = this.state

    if (loading) {
      return this.waitOnRedirectAuthEvent()
    }
    if (federateAuthSuccess) {
      return <Redirect push to="/" />
    }
    if (unauthorizedInternalUser) {
      return this.getFailStateContainer()
    }
    return this.getFailStateContainer()
  }
}

export default injectIntl(withRouter(SingleSignOnCallbackHandler))
