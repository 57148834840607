import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import {
  Box,
  Button,
  Container,
  Header,
  Grid,
  StatusIndicator,
  ColumnLayout,
  FormField,
  Form,
  Input,
  InputProps
} from "@amzn/awsui-components-react/polaris"
import { Auth } from "aws-amplify"
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom"
import messages from "./ResetPassword.messages"
import commonMessages from "../Common.messages"
import Constants from "../../Constants"
import { AuthConfigureCommon } from "./AuthConfigureCommon"
import AppConfig from "../../Config"

const { Config } = AppConfig

interface IResetPasswordProps {
  intl: IntlShape
}
interface IResetPasswordState {
  codeSent: boolean
  successfullyResetPassword: boolean
  loading: boolean
  resetPassword: IResetPasswordFormState
  sendVerificationCode: ISendVerificationCodeFormState
}

interface ISendVerificationCodeFormState {
  userName: string
  userNameError: string
  serverSideError: string
}

interface IResetPasswordFormState {
  code: string
  codeError: string
  password: string
  passwordError: string
  retypePassword: string
  retypePasswordError: string
  serverSideError: string
  validateOnChange: boolean
}

export class ResetPassword extends React.PureComponent<
  IResetPasswordProps & RouteComponentProps,
  IResetPasswordState
> {
  constructor(props: IResetPasswordProps & RouteComponentProps) {
    super(props)
    this.state = {
      loading: false,
      codeSent: false,
      successfullyResetPassword: false,
      resetPassword: {
        code: "",
        codeError: "",
        password: "",
        passwordError: "",
        retypePassword: "",
        retypePasswordError: "",
        serverSideError: "",
        validateOnChange: false
      },
      sendVerificationCode: {
        userName: "",
        userNameError: "",
        serverSideError: ""
      }
    }
  }

  componentDidMount() {
    const {
      intl: { formatMessage },
      location: { state }
    } = this.props
    document.title = formatMessage(messages.resetPasswordDocumentTitle)

    const locationState = state as any
    if (locationState) {
      if (locationState.codeSent || locationState.userName) {
        this.setState(prevState => {
          return {
            codeSent: locationState.codeSent === true,
            sendVerificationCode: {
              ...prevState.sendVerificationCode,
              userName: locationState.userName
            }
          }
        })
      }
    }
  }

  onUserNameChange = (detail: InputProps.ChangeDetail) => {
    const {
      resetPassword: { validateOnChange }
    } = this.state
    this.setState(state => {
      return {
        sendVerificationCode: {
          ...state.sendVerificationCode,
          userName: detail.value
        }
      }
    })
    if (validateOnChange) {
      this.validateUserNameInput()
    }
  }

  onCodeChange = (detail: InputProps.ChangeDetail) => {
    const {
      resetPassword: { validateOnChange }
    } = this.state
    this.setState(state => {
      return {
        resetPassword: { ...state.resetPassword, code: detail.value }
      }
    })
    if (validateOnChange) {
      this.validateResetPasswordInputs()
    }
  }

  onPasswordChange = (detail: InputProps.ChangeDetail) => {
    const {
      resetPassword: { validateOnChange }
    } = this.state
    this.setState(state => {
      return {
        resetPassword: { ...state.resetPassword, password: detail.value }
      }
    })
    if (validateOnChange) {
      this.validateResetPasswordInputs()
    }
  }

  onRetypePasswordChange = (detail: InputProps.ChangeDetail) => {
    const {
      resetPassword: { validateOnChange }
    } = this.state
    this.setState(state => {
      return {
        resetPassword: {
          ...state.resetPassword,
          retypePassword: detail.value
        }
      }
    })
    if (validateOnChange) {
      this.validateResetPasswordInputs()
    }
  }

  onReturnKeySendVerificationCode = (
    event: CustomEvent<InputProps.KeyDetail>
  ) => {
    const {
      detail: { keyCode }
    } = event
    if (keyCode === Constants.ReturnKeyCode) {
      this.onSendVerificationCode()
    }
  }

  onSendVerificationCode = async () => {
    this.setState(state => {
      return {
        successfullyResetPassword: false,
        loading: false,
        resetPassword: {
          ...state.resetPassword,
          validateOnChange: true
        },
        sendVerificationCode: {
          ...state.sendVerificationCode,
          serverSideError: ""
        }
      }
    })

    if (this.validateUserNameInput()) {
      this.forgotPassword(false)
    }
  }

  forgotPassword = async (useOldPool: boolean) => {
    const {
      sendVerificationCode: { userName }
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    if (useOldPool) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      AuthConfigureCommon.useOldPool()
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      AuthConfigureCommon.useNewPool()
    }

    try {
      await Auth.forgotPassword(userName.toLowerCase())

      this.setState(state => {
        return {
          loading: false,
          codeSent: true,
          resetPassword: { ...state.resetPassword, validateOnChange: false }
        }
      })
    } catch (err: any) { // eslint-disable-line
      let errorMessage: string
      switch (err.code) {
        case "UserNotFoundException":
          if (Config.Features.MultiPoolLogin && !useOldPool) {
            this.forgotPassword(true)
            return
          }
          errorMessage = formatMessage(
            messages.resetPasswordVerificationCodeUserNotFoundError
          )
          break
        case "InvalidParameterException":
          if (
            err.message &&
            (err.message as string).includes(
              Constants.Cognito
                .ResetPasswordEmailNotVerifiedExceptionPartialMessage
            )
          ) {
            errorMessage = formatMessage(
              messages.resetPasswordUserEmailNotVerifiedError
            )
          } else {
            errorMessage = "Unknown error"
            // eslint-disable-next-line
            console.error(err)
            break
          }
          break
        case "LimitExceededException":
          errorMessage = formatMessage(
            messages.resetPasswordVerificationCodeTooManyRequestsError
          )
          break
        default:
          errorMessage = "Unknown error"
          // eslint-disable-next-line
          console.error(err)
          break
      }
      this.setState(state => {
        return {
          successfullyResetPassword: false,
          loading: false,
          sendVerificationCode: {
            ...state.sendVerificationCode,
            serverSideError: errorMessage
          }
        }
      })
    }
  }

  onResendCode = () => {
    this.setState({ codeSent: false })
  }

  onReturnKeySubmitNewPassword = (event: CustomEvent<InputProps.KeyDetail>) => {
    const {
      detail: { keyCode }
    } = event
    if (keyCode === Constants.ReturnKeyCode) {
      this.onSubmitNewPassword()
    }
  }

  onSubmitNewPassword = async () => {
    this.setState(state => {
      return {
        successfullyResetPassword: false,
        loading: false,
        resetPassword: {
          ...state.resetPassword,
          serverSideError: "",
          validateOnChange: true
        }
      }
    })

    if (this.validateResetPasswordInputs()) {
      this.setState({
        successfullyResetPassword: false,
        loading: true
      })

      await this.forgotPasswordSubmit(false)
    }
  }

  forgotPasswordSubmit = async (useOldPool: boolean) => {
    const {
      sendVerificationCode: { userName },
      resetPassword: { code, password }
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    if (useOldPool) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      AuthConfigureCommon.useOldPool()
    } else {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      AuthConfigureCommon.useNewPool()
    }

    try {
      await Auth.forgotPasswordSubmit(userName.toLowerCase(), code, password)

      this.setState({
        successfullyResetPassword: true,
        loading: false
      })
    } catch (err: any) { // eslint-disable-line
      let errorMessage: string
      switch (err.code) {
        case "UserNotFoundException":
          if (Config.Features.MultiPoolLogin && !useOldPool) {
            this.forgotPasswordSubmit(true)
            return
          }
          errorMessage = formatMessage(messages.resetPasswordInvalidCodeError)
          break
        case "CodeMismatchException":
          errorMessage = formatMessage(messages.resetPasswordInvalidCodeError)
          break
        case "InvalidPasswordException":
          errorMessage = formatMessage(
            messages.resetPasswordInvalidPasswordError
          )
          break
        case "ExpiredCodeException":
          errorMessage = formatMessage(messages.resetPasswordExpiredCodeError)
          break
        default:
          errorMessage = "Unknown error"
          // eslint-disable-next-line
            console.error(err)
          break
      }

      this.setState(state => {
        return {
          successfullyResetPassword: false,
          loading: false,
          resetPassword: {
            ...state.resetPassword,
            serverSideError: errorMessage
          }
        }
      })
    }
  }

  validateUserNameInput = (): boolean => {
    const {
      sendVerificationCode: { userName }
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    let userNameError: string = ""
    if (!userName || userName === "") {
      userNameError = formatMessage(messages.resetPasswordEmailRequired)
    } else if (
      !userName.match(
        /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.)+([a-zA-Z]{2,})$/i
      )
    ) {
      userNameError = formatMessage(messages.resetPasswordEmailInvalid)
    }

    this.setState(state => {
      return {
        sendVerificationCode: { ...state.sendVerificationCode, userNameError }
      }
    })

    return userNameError === ""
  }

  validateResetPasswordInputs = (): boolean => {
    const {
      resetPassword: { code, password, retypePassword }
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    let codeError: string = ""
    if (!code || code === "") {
      codeError = formatMessage(messages.resetPasswordCodeRequired)
    }

    let passwordError: string = ""
    if (!password || password === "") {
      passwordError = formatMessage(messages.resetPasswordPasswordRequired)
    } else if (password.length < Constants.Cognito.PasswordMinLength) {
      passwordError = formatMessage(messages.resetPasswordPasswordInvalid)
    }

    let retypePasswordError: string = ""
    if (!retypePassword || retypePassword !== password) {
      retypePasswordError = formatMessage(
        messages.resetPasswordPasswordsDoNotMatch
      )
    }

    this.setState(state => {
      return {
        resetPassword: {
          ...state.resetPassword,
          codeError,
          passwordError,
          retypePasswordError
        }
      }
    })

    return (
      codeError === "" && passwordError === "" && retypePasswordError === ""
    )
  }

  getFetchUsernameContainer = () => {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      sendVerificationCode: { userName, userNameError, serverSideError }
    } = this.state
    return (
      <Box margin={{ left: "xl", right: "xl" }}>
        <Container
          header={
            <Header variant="h2">
              {formatMessage(messages.resetPasswordHeader)}
            </Header>
          }
          footer={
            <Grid gridDefinition={[{ colspan: 12 }]}>
              <Box float="right">
                <Button
                  id="sendVerificationSubmitButton"
                  variant="primary"
                  onClick={this.onSendVerificationCode}
                >
                  {formatMessage(commonMessages.submit)}
                </Button>
              </Box>
            </Grid>
          }
        >
          <div>
            <Form id="sendVerificationCodeForm" errorText={serverSideError}>
              <ColumnLayout>
                <FormField
                  id="sendVerificationEmailFormField"
                  stretch
                  errorText={userNameError}
                  label={formatMessage(messages.resetPasswordEmail)}
                  description={formatMessage(
                    messages.resetPasswordEmailDescription
                  )}
                >
                  <Input
                    id="sendVerificationEmailInput"
                    type="email"
                    autoFocus
                    value={userName}
                    onChange={({ detail }) => this.onUserNameChange(detail)}
                    onKeyDown={this.onReturnKeySendVerificationCode}
                    ariaLabelledby={formatMessage(messages.resetPasswordEmail)}
                  />
                </FormField>
                <Box padding={{ bottom: "xs" }} />
              </ColumnLayout>
            </Form>
          </div>
        </Container>
      </Box>
    )
  }

  getResetPasswordContainer = () => {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      resetPassword: {
        code,
        codeError,
        password,
        passwordError,
        retypePassword,
        retypePasswordError,
        serverSideError
      }
    } = this.state

    return (
      <Box margin={{ left: "xl", right: "xl" }}>
        <Container
          header={
            <Header variant="h2">
              {formatMessage(messages.resetPasswordHeader)}
            </Header>
          }
          footer={
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <Box>
                <Button
                  id="resetPasswordResendCodeButton"
                  variant="link"
                  onClick={this.onResendCode}
                >
                  {formatMessage(messages.resetPasswordResendCode)}
                </Button>
              </Box>
              <Box float="right">
                <Button
                  id="resetPasswordSubmitButton"
                  variant="primary"
                  onClick={this.onSubmitNewPassword}
                >
                  {formatMessage(commonMessages.submit)}
                </Button>
              </Box>
            </Grid>
          }
        >
          <div>
            <Form id="resetPasswordForm" errorText={serverSideError}>
              <ColumnLayout>
                <FormField
                  id="resetPasswordVerificationCodeFormField"
                  stretch
                  errorText={codeError}
                  label={formatMessage(messages.resetPasswordVerificationCode)}
                  description={formatMessage(
                    messages.resetPasswordVerificationCodeDescription
                  )}
                >
                  <Input
                    id="resetPasswordVerificationCodeInput"
                    type="text"
                    autoFocus
                    value={code}
                    onChange={({ detail }) => this.onCodeChange(detail)}
                    ariaLabelledby={formatMessage(
                      messages.resetPasswordVerificationCode
                    )}
                  />
                </FormField>
                <FormField
                  id="resetPasswordNewPasswordFormField"
                  stretch
                  errorText={passwordError}
                  label={formatMessage(messages.resetPasswordNewPassword)}
                  description={formatMessage(
                    messages.resetPasswordNewPasswordDescription
                  )}
                >
                  <Input
                    id="resetPasswordNewPasswordInput"
                    type="password"
                    value={password}
                    onChange={({ detail }) => this.onPasswordChange(detail)}
                    onKeyDown={this.onReturnKeySubmitNewPassword}
                    ariaLabelledby={formatMessage(
                      messages.resetPasswordNewPassword
                    )}
                  />
                </FormField>
                <FormField
                  id="resetPasswordRetypePasswordFormField"
                  stretch
                  errorText={retypePasswordError}
                  label={formatMessage(messages.resetPasswordRetypePassword)}
                >
                  <Input
                    id="resetPasswordRetypePasswordInput"
                    type="password"
                    value={retypePassword}
                    onChange={({ detail }) =>
                      this.onRetypePasswordChange(detail)
                    }
                    onKeyDown={this.onReturnKeySubmitNewPassword}
                    ariaLabelledby={formatMessage(
                      messages.resetPasswordRetypePassword
                    )}
                  />
                </FormField>
                <Box padding={{ bottom: "xs" }} />
              </ColumnLayout>
            </Form>
          </div>
        </Container>
      </Box>
    )
  }

  wrapInAwsUiGrid = (innerContent: any): any => {
    return (
      <div className="awsui">
        <Grid
          gridDefinition={[
            { colspan: 12 },
            { colspan: { m: 4, xxs: 10 }, push: { m: 4, xxs: 1 } }
          ]}
        >
          <Box padding={{ top: "xxs" }} />
          <div> {innerContent} </div>
        </Grid>
      </div>
    )
  }

  render() {
    const {
      codeSent: hasValidUsername,
      successfullyResetPassword,
      loading
    } = this.state

    const {
      intl: { formatMessage }
    } = this.props

    if (loading) {
      return (
        <div className="awsui text-center">
          <StatusIndicator type="loading">
            <span> {formatMessage(commonMessages.loading)} </span>
          </StatusIndicator>
        </div>
      )
    }

    if (!hasValidUsername) {
      return this.wrapInAwsUiGrid(this.getFetchUsernameContainer())
    }

    if (successfullyResetPassword) {
      return <Redirect to={`/${Constants.PathName.SignIn}`} />
    }

    return this.wrapInAwsUiGrid(this.getResetPasswordContainer())
  }
}

export default injectIntl(withRouter(ResetPassword))
