export type FormattedBytes = {
  Value: number
  Unit: "byte" | "kilobyte" | "megabyte" | "gigabyte" | "terabyte" | "petabyte"
}
export const formatBytes = (
  bytes: number,
  decimals: number = 2
): FormattedBytes => {
  if (bytes === 0) return { Value: 0, Unit: "byte" }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [
    "byte",
    "kilobyte",
    "megabyte",
    "gigabyte",
    "terabyte",
    "petabyte"
  ]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return {
    // eslint-disable-next-line no-restricted-properties
    Value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    Unit: sizes[i] as
      | "byte"
      | "kilobyte"
      | "megabyte"
      | "gigabyte"
      | "terabyte"
      | "petabyte"
  }
}

export default { formatBytes }
