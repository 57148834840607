import { defineMessages } from "react-intl"

const messages = defineMessages({
  enableMfaDocumentTitle: {
    id: "enableMfa.documentTitle",
    defaultMessage: "Setup MFA"
  },
  enableMfaScanQrCode: {
    id: "enableMfa.scanQrCode",
    defaultMessage: "Scan QR code"
  },
  enableMfaScanQrCodeDescription: {
    id: "enableMfa.scanQrCodeDescription",
    defaultMessage:
      "Scan this QR code with a time-based one-time password generator app like Google Authenticator"
  },
  enableMfaTotpSetupKey: {
    id: "enableMfa.totpSetupKey",
    defaultMessage: "MFA setup key"
  },
  enableMfaTotpSetupKeyDescription: {
    id: "enableMfa.totpSetupKeyDescription",
    defaultMessage:
      "In case you are not able to use QR code, use this key to manually setup the OTP app"
  },
  enableMfaToken: {
    id: "enableMfa.token",
    defaultMessage: "One-time password"
  },
  enableMfaTokenDescription: {
    id: "enableMfa.tokenDescription",
    defaultMessage: "Enter your 6 digit MFA token from the OTP app"
  },
  enableMfaErrorTokenInvalid: {
    id: "enableMfa.error.tokenInvalid",
    defaultMessage:
      "Invalid token; enter 6 digit MFA token generated by the OTP app"
  },
  enableMfaErrorTokenMismatch: {
    id: "enableMfa.error.tokenMismatch",
    defaultMessage: "Entered token does not match; please try again"
  },
  enableMfaEnabled: {
    id: "enableMfa.enabled",
    defaultMessage: "Multi-factor authentication on your account is enabled"
  }
})

export default messages
