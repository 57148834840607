export const getEngagementAndCredentials = `query GetEngagementAndCredentials($id: ID!) {
  getEngagement(id: $id) {
    Id
    Name
    S3Bucket
    S3KeyPrefix
  }
  getCustomerDataTempCredentials(engagementId: $id) {
    AccessKey
    SecretAccessKey
    SessionToken
    Expiration
  }
}
`
export const GetCustomerDataTempCredentials = `query GetCustomerDataTempCredentials($id: ID!) {
  getCustomerDataTempCredentials(engagementId: $id) {
    AccessKey
    SecretAccessKey
    SessionToken
    Expiration
  }
}
`
export const generateUploadS3PresignedUrlQuery = `query GenerateUploadS3PresignedUrl(
  $engagementId: ID!, $dataFormat: InventoryDataFormat!, $fileName: String!
) {
  generateUploadS3PresignedUrl(engagementId: $engagementId, dataFormat: $dataFormat, fileName: $fileName) {
    PresignedUrl
  }
}
`
export const generateDownloadS3PresignedUrlQuery = `query generateDownloadS3PresignedUrl(
  $engagementId: ID!, $importJobId: ID!, $resultFileType: ImportJobResultFileType!
) {
  generateDownloadS3PresignedUrl(engagementId: $engagementId, importJobId: $importJobId, resultFileType: $resultFileType) {
    PresignedUrl
  }
}
`
export const listLatestImportJobsByFileName = `query ListImportJobs($id: ID!) {
  listLatestImportJobsByFileName(engagementId: $id) {
    ImportJobs {
      CreatedOn
      DataFormat
      Id
      ImportStatus
      ImportSourceFile {
        FileName
        ProcessedOriginalAvailable
        ProcessedOriginalS3PresignedUrl
      }
      ValidationResult {
        ValidationStatus
        ValidationReportAvailable
        ValidationReportS3PresignedUrl
      }
    }  
  }
}
`
