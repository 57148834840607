import { defineMessages } from "react-intl"

const messages = defineMessages({
  accountCreationFailedHeader: {
    id: "accountDetailsAlert.accountCreationFailedHeader",
    defaultMessage: "Account creation failed"
  },
  accountCreationFailedText: {
    id: "accountDetailsAlert.accountCreationFailedText",
    defaultMessage: "An error occurred while creating your account."
  },
  accountCreationFailedRecovery: {
    id: "accountDetailsAlert.accountCreationFailedRecovery",
    defaultMessage:
      "The development team has been notified and will investigate the issue."
  },
  accountCreationTimeoutHeader: {
    id: "accountDetailsAlert.accountCreationTimeoutHeader",
    defaultMessage: "Expect longer processing time"
  },
  accountCreationTimeoutText: {
    id: "accountDetailsAlert.accountCreationTimeoutText",
    defaultMessage:
      "Please anticipate longer processing time to create your account and refresh at a later time."
  },
  accountClosureFailedHeader: {
    id: "accountDetailsAlert.accountClosureFailedHeader",
    defaultMessage: "Account closure failed"
  },
  accountClosureFailedText: {
    id: "accountDetailsAlert.accountClosureFailedText",
    defaultMessage: "An error occurred while closing your account."
  },
  accountClosureFailedRecovery: {
    id: "accountDetailsAlert.accountClosureFailedRecovery",
    defaultMessage:
      "The development team has been notified and will investigate the issue."
  },
  accountClosureTimeoutHeader: {
    id: "accountDetailsAlert.accountClosureTimeoutHeader",
    defaultMessage: "Expect longer processing time"
  },
  accountClosureTimeoutText: {
    id: "accountDetailsAlert.accountClosureTimeoutText",
    defaultMessage:
      "Please anticipate longer processing time to close your account and refresh at a later time."
  },
  accountAuditLogRetrievalFailedHeader: {
    id: "accountDetailsAlert.accountAuditLogRetrievalFailedHeader",
    defaultMessage: "Audit log retrieval failed"
  },
  accountAuditLogRetrievalFailedText: {
    id: "accountDetailsAlert.accountAuditLogRetrievalFailedText",
    defaultMessage: "An error occured while retrieving the audit logs."
  },
  accountFederationFailedHeader: {
    id: "accountDetailsAlert.accountFederationFailedHeader",
    defaultMessage: "Account federation failed"
  },
  accountFederationFailedText: {
    id: "accountDetailsAlert.accountFederationFailedText",
    defaultMessage: "An error occured while federating into your account."
  },
  createAccessKeysFailedHeader: {
    id: "accountDetailsAlert.createAccessKeysFailedHeader",
    defaultMessage: "Access key creation failed"
  },
  createAccessKeysFailedText: {
    id: "accountDetailsAlert.createAccessKeysFailedText",
    defaultMessage: "An error occured while creating the access keys."
  },
  accessKeysLimitExceededFailedHeader: {
    id: "accountDetailsAlert.accessKeysLimitExceededFailedHeader",
    defaultMessage: "Access key creation failed"
  },
  accessKeysLimitExceededFailedText: {
    id: "accountDetailsAlert.accessKeysLimitExceededFailedText",
    defaultMessage:
      "Access key limit exceeded. Only 2 access keys can be created per account."
  },
  generalErrorInternal: {
    id: "accountDetailsAlert.generalErrorInternal",
    defaultMessage: "If you need further support, {createTicket}."
  },
  generalErrorRecoveryExternal: {
    id: "accountDetailsAlert.generalErrorRecoveryExternal",
    defaultMessage:
      "If you need further support, please reach out to your Migration Evaluator program manager."
  },
  createTroubleTicket: {
    id: "accountDetailsAlert.createTroubleTicket",
    defaultMessage: "create a ticket"
  }
})

export default messages
