import { defineMessages } from "react-intl"

const messages = defineMessages({
  title: {
    id: "toolsSurvey.title",
    defaultMessage: "How did we do?"
  },
  description: {
    id: "toolsSurvey.description",
    defaultMessage:
      "At Amazon, we obsess over our customers. Please take 5 minutes to fill out this survey on your experience with our tools."
  },
  surveyLinkText: {
    id: "toolsSurvey.surveyLinkText",
    defaultMessage: "Go to survey (~5 minutes)"
  }
})

export default messages
