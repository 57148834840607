import React from "react"
import axios from "axios"
import { injectIntl, IntlShape } from "react-intl"
import { withRouter, RouteComponentProps } from "react-router-dom"
import {
  Box,
  Grid,
  Link,
  Alert,
  ColumnLayout,
  FormField,
  Button,
  RadioGroup,
  Spinner,
  Icon,
  Form
} from "@amzn/awsui-components-react/polaris"
import { API, graphqlOperation } from "aws-amplify"
import JSZip from "jszip"
import { generateUploadS3PresignedUrlQuery } from "./graphql/queries"
import messages from "./CustomerReportedFilesUploadSingleFile.messages"
import { formatBytes } from "../../lib/utility"
import AppConfig from "../../Config"
import constants from "../../Constants"
import { getSelfReportedDataTemplateQuery } from "../tools/graphql/queries"
import ErrorMessage from "./CustomerReportedFilesUploadSingleFile.constants"

const { Config } = AppConfig

export enum FileUploadStatus {
  NotStarted,
  InProgress,
  Uploaded,
  Failed
}

export enum DataFormat {
  MeSimple = "MeSimple",
  MeCollectorExport = "MeCollectorExport",
  AdsCollectorExport = "AdsCollectorExport"
}

interface IPresignedUrlQueryResponse {
  generateUploadS3PresignedUrl: {
    PresignedUrl: string
  }
}

interface ISelectedFile {
  file: File
  status: FileUploadStatus
  errorMessages: string[]
  failureMessage?: string
}

interface ICustomerReportedFilesUploadProps {
  intl: IntlShape
  engagementId: string
  isModalVisible: boolean
  onFilesUploaded: Function
  onUploadStarted: Function
}
interface ICustomerReportedFilesUploadState {
  errorExists: boolean
  selectedFile?: ISelectedFile
  uploadInProgress: boolean
  loading: boolean
  dataFormat?: DataFormat
  simpleMeTemplateUrl?: string
}

interface ICustomerReportedFilesImportTemplate {
  CustomerSelfReportedDataTemplate: string
}

export class CustomerReportedFilesUpload extends React.Component<
  RouteComponentProps & ICustomerReportedFilesUploadProps,
  ICustomerReportedFilesUploadState
> {
  private fileInputRef: React.RefObject<HTMLInputElement> = React.createRef()

  private willUnmount: boolean = false

  private formatMsg: Function

  constructor(props: RouteComponentProps & ICustomerReportedFilesUploadProps) {
    super(props)

    const {
      intl: { formatMessage }
    } = this.props

    this.formatMsg = formatMessage

    this.state = {
      errorExists: false,
      selectedFile: undefined,
      uploadInProgress: false,
      loading: false,
      dataFormat: undefined,
      simpleMeTemplateUrl: undefined
    }
  }

  async componentDidMount() {
    await this.getImportTemplate()
  }

  async componentDidUpdate(
    prevProps: RouteComponentProps & ICustomerReportedFilesUploadProps,
    prevState: ICustomerReportedFilesUploadState
  ) {
    const { engagementId, isModalVisible } = this.props
    const { engagementId: prevEngagementId } = prevProps

    if (prevEngagementId !== engagementId) {
      this.removeSelectedFileAndFormat()
    }

    const { selectedFile: currentSelectedFile } = this.state
    // Need to make condition really narrow so that this is only called when needed as it includes setState function
    if (
      !isModalVisible &&
      currentSelectedFile &&
      currentSelectedFile.status === FileUploadStatus.Uploaded
    ) {
      this.removeSelectedFileAndFormat()
    }

    const { selectedFile } = this.state
    const { selectedFile: prevSelectedFile } = prevState

    if (prevSelectedFile !== undefined && selectedFile !== undefined) {
      if (
        prevSelectedFile.file.name + prevSelectedFile.file.size !==
        selectedFile.file.name + selectedFile.file.size
      ) {
        this.validateSelectedFile()
      }
    } else if (selectedFile !== undefined) {
      this.validateSelectedFile()
    }
  }

  componentWillUnmount() {
    this.willUnmount = true
  }

  getImportTemplate = async () => {
    try {
      this.setState({ errorExists: false })
      const response = await API.graphql(
        graphqlOperation(getSelfReportedDataTemplateQuery)
      )

      const responseData = response as {
        data: { getInstallers: ICustomerReportedFilesImportTemplate }
      }
      if (!this.willUnmount) {
        this.setState({
          simpleMeTemplateUrl:
            responseData.data.getInstallers.CustomerSelfReportedDataTemplate
        })
      }
    } catch (err: any) { // eslint-disable-line
      this.setState({ errorExists: true })
    }
  }

  private getPresignedUrl = async (
    selectedFile: ISelectedFile,
    dataFormat: DataFormat
  ) => {
    const { engagementId } = this.props

    const response = await API.graphql(
      graphqlOperation(generateUploadS3PresignedUrlQuery, {
        engagementId,
        dataFormat,
        fileName: selectedFile.file.name
      })
    )
    const queryResponse = response as {
      data: IPresignedUrlQueryResponse
    }
    return queryResponse.data.generateUploadS3PresignedUrl.PresignedUrl
  }

  private onDataFormatChange = (dataFormat: string) => {
    this.setState({
      dataFormat: dataFormat as DataFormat
    })
  }

  private onSelectedFileChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault()
    this.setState({
      selectedFile:
        this.fileInputRef.current !== null &&
        this.fileInputRef.current.files !== null
          ? {
              file: this.fileInputRef.current.files[0],
              status: FileUploadStatus.NotStarted,
              errorMessages: []
            }
          : undefined
    })
  }

  private getFailureMessageFromError(errors: any[]) {
    if (
      errors?.some(error => {
        return error.message.includes(ErrorMessage.FILE_NAME_VALIDATION_ERROR)
      })
    ) {
      return this.formatMsg(messages.invalidFileName)
    }
    return this.formatMsg(messages.unexpectedError)
  }

  private selectFilesToUpload = () => {
    this.fileInputRef.current!.click()
  }

  private removeSelectedFile = () => {
    this.fileInputRef.current!.value = ""
    this.setState({ selectedFile: undefined })
  }

  private removeSelectedFileAndFormat = () => {
    this.fileInputRef.current!.value = ""
    this.setState({ selectedFile: undefined, dataFormat: undefined })
  }

  private validateSelectedFile = () => {
    const { selectedFile } = this.state
    if (selectedFile !== undefined) {
      this.validateFile(selectedFile)
    }
  }

  private validateFile = (selectedFile: ISelectedFile) => {
    const errorMessages: string[] = []

    if (selectedFile.file.size > Config.CustomerReportedFile.MaxFileSizeBytes) {
      errorMessages.push(
        this.formatMsg(messages.fileSizeGreaterThanMaxAllowed, {
          maxAllowedFileSize: this.formatFileSize(
            Config.CustomerReportedFile.MaxFileSizeBytes
          )
        })
      )
    }

    const fileName = selectedFile.file.name.toLowerCase()
    if (
      !Config.CustomerReportedFile.AllowedFileTypes.some(s =>
        fileName.endsWith(s)
      )
    ) {
      errorMessages.push(
        this.formatMsg(messages.fileTypeNotAllowed, {
          allowedFileTypes: Config.CustomerReportedFile.AllowedFileTypes.join(
            ", "
          )
        })
      )
    }

    this.setState({
      selectedFile: { ...selectedFile, errorMessages }
    })
  }

  private uploadAndCallback = async () => {
    const { onUploadStarted, onFilesUploaded } = this.props
    onUploadStarted()
    await this.uploadSelectedFile()
    onFilesUploaded()
  }

  private uploadSelectedFile = () => {
    const { selectedFile, dataFormat } = this.state
    if (selectedFile && dataFormat) {
      return Promise.resolve(this.uploadFile(selectedFile, dataFormat))
    }
    return Promise.resolve()
  }

  private containsFilesWithExt = async (
    selectedFile: ISelectedFile,
    ext: string
  ): Promise<boolean> => {
    const zipFile = await JSZip.loadAsync(selectedFile.file)
    return Object.values(zipFile.files).some(file => file.name.endsWith(ext))
  }

  private uploadFile = async (
    selectedFile: ISelectedFile,
    dataFormat: DataFormat
  ) => {
    this.setState({
      selectedFile: { ...selectedFile, status: FileUploadStatus.InProgress },
      uploadInProgress: true
    })

    // Validate ADS exports.
    if (dataFormat === DataFormat.AdsCollectorExport) {
      const isValid: boolean = await this.containsFilesWithExt(
        selectedFile,
        ".csv"
      )
      if (!isValid) {
        this.setState({
          selectedFile: {
            ...selectedFile,
            status: FileUploadStatus.Failed,
            failureMessage: this.formatMsg(messages.emptyAdsExportError)
          },
          uploadInProgress: false
        })
        return
      }
    }

    try {
      const presignedUrl = await this.getPresignedUrl(selectedFile, dataFormat)
      await axios.put(presignedUrl, selectedFile.file)

      this.setState({
        selectedFile: { ...selectedFile, status: FileUploadStatus.Uploaded },
        uploadInProgress: false
      })
    } catch (err: any) { // eslint-disable-line
      const { errors } = err as { errors: any[] }
      const failureMessage: string = errors
        ? this.getFailureMessageFromError(errors)
        : this.formatMsg(messages.unexpectedError)
      // eslint-disable-next-line no-console
      console.error(err)

      this.setState({
        selectedFile: {
          ...selectedFile,
          status: FileUploadStatus.Failed,
          failureMessage
        },
        uploadInProgress: false
      })
    }
  }

  private getAlertType = (
    status: FileUploadStatus
  ): "success" | "error" | "warning" | "info" | undefined => {
    let alertType: "success" | "error" | "warning" | "info" | undefined = "info"
    switch (status) {
      case FileUploadStatus.NotStarted:
      case FileUploadStatus.InProgress:
        alertType = "info"
        break
      case FileUploadStatus.Uploaded:
        alertType = "success"
        break
      case FileUploadStatus.Failed:
        alertType = "error"
        break
      default:
        alertType = "info"
        break
    }
    return alertType
  }

  private formatFileSize = (fileSize: number): string => {
    const {
      intl: { formatNumber }
    } = this.props

    const fileSizeBytes = formatBytes(fileSize)

    return formatNumber(fileSizeBytes.Value, {
      style: "unit",
      unit: fileSizeBytes.Unit,
      unitDisplay: "short"
    })
  }

  private getTableItems = () => {
    const { simpleMeTemplateUrl } = this.state

    const meSimpleLabel = (
      <div>
        {this.formatMsg(messages.meSimpleText)}
        <Link
          id="MeSimpleFormatHelpLink"
          href={simpleMeTemplateUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;
          {this.formatMsg(messages.fileFormatHelpText)}
          &nbsp;
          <Icon variant="link" name="external" />
        </Link>
      </div>
    )

    const meCollectorExportLabel = (
      <div>
        {this.formatMsg(messages.meCollectorExportText)}
        <Link
          id="MeCollectorExportFormatHelpLink"
          href={Config.Support.MeCollectorExportHelpLinkAddress}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;
          {this.formatMsg(messages.fileFormatHelpText)}
          &nbsp;
          <Icon variant="link" name="external" />
        </Link>
      </div>
    )

    const adsCollectorExportLabel = (
      <div>
        {this.formatMsg(messages.adsCollectorExportText)}
        <Link
          id="adsCollectorExportFormatHelpLink"
          href={Config.Support.AdsCollectorExportHelpLinkAddress}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;
          {this.formatMsg(messages.fileFormatHelpText)}
          &nbsp;
          <Icon variant="link" name="external" />
        </Link>
      </div>
    )

    return [
      {
        value: DataFormat.MeSimple,
        label: meSimpleLabel,
        description: this.formatMsg(messages.meSimpleDescriptionText)
      },
      {
        value: DataFormat.MeCollectorExport,
        label: meCollectorExportLabel,
        description: this.formatMsg(messages.meCollectorExportDescriptionText)
      },
      {
        value: DataFormat.AdsCollectorExport,
        label: adsCollectorExportLabel,
        description: this.formatMsg(messages.adsCollectorExportDescriptionText)
      }
    ]
  }

  private getInvalidFileTypeErrorMessage = (): string | undefined => {
    const { selectedFile, dataFormat } = this.state
    const {
      ValidMeSimpleFileTypes,
      ValidMeCollectorExportFileTypes,
      ValidAdsCollectorExportFileTypes
    } = constants.ValidUploadFileTypes
    const selectedFileType = selectedFile?.file?.type

    if (!selectedFileType || !dataFormat) {
      return undefined
    }
    if (
      dataFormat === DataFormat.MeSimple &&
      !ValidMeSimpleFileTypes.includes(selectedFileType)
    ) {
      return this.formatMsg(
        messages.meSimpleInvalidUploadFileTypeDescriptionText
      )
    }
    if (
      dataFormat === DataFormat.MeCollectorExport &&
      !ValidMeCollectorExportFileTypes.includes(selectedFileType)
    ) {
      return this.formatMsg(
        messages.meCollectorExportInvalidUploadFileTypeDescriptionText
      )
    }
    if (
      dataFormat === DataFormat.AdsCollectorExport &&
      !ValidAdsCollectorExportFileTypes.includes(selectedFileType)
    ) {
      return this.formatMsg(
        messages.adsCollectorExportInvalidUploadFileTypeDescriptionText
      )
    }
    return undefined
  }

  render() {
    const {
      intl: { formatDate, formatTime }
    } = this.props
    const {
      loading,
      selectedFile,
      uploadInProgress,
      dataFormat,
      errorExists
    } = this.state

    return (
      <div>
        {loading ? (
          <Grid gridDefinition={[{ colspan: 12 }]}>
            <Box textAlign="center">
              <Spinner size="big" variant="disabled" />
            </Box>
          </Grid>
        ) : (
          <Grid
            disableGutters
            gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}
          >
            <div>
              <Alert
                id="serverError"
                header={this.formatMsg(messages.unexpectedError)}
                type="error"
                dismissible
                visible={errorExists}
                onDismiss={() => this.setState({ errorExists: false })}
                dismissAriaLabel="Close alert"
              >
                {this.formatMsg(messages.meSimpleTemplateUnavailableText)}
              </Alert>
            </div>
            <div>
              <Form
                data-testid="uploadForm"
                actions={
                  <Box float="left">
                    <Button
                      id="uploadButton"
                      iconName="upload"
                      iconAlign="left"
                      variant="primary"
                      onClick={this.uploadAndCallback}
                      loading={uploadInProgress}
                      disabled={
                        loading ||
                        selectedFile === undefined ||
                        selectedFile.status !== FileUploadStatus.NotStarted ||
                        selectedFile.errorMessages.length !== 0 ||
                        dataFormat === undefined ||
                        !!this.getInvalidFileTypeErrorMessage()
                      }
                    >
                      {this.formatMsg(messages.uploadButtonText)}
                    </Button>
                  </Box>
                }
                errorText={this.getInvalidFileTypeErrorMessage()}
              >
                <ColumnLayout>
                  <FormField
                    stretch
                    constraintText={this.formatMsg(
                      messages.selectFilesDescription,
                      {
                        allowedFileTypes: Config.CustomerReportedFile.AllowedFileTypes.join(
                          ", "
                        ),
                        maxAllowedFileSize: this.formatFileSize(
                          Config.CustomerReportedFile.MaxFileSizeBytes
                        )
                      }
                    )}
                  >
                    <Button
                      iconName="folder"
                      id="chooseFilesButton"
                      onClick={this.selectFilesToUpload}
                      disabled={uploadInProgress}
                    >
                      {`${this.formatMsg(messages.selectFiles)} . . .`}
                    </Button>
                    <input
                      type="file"
                      ref={this.fileInputRef}
                      onChange={this.onSelectedFileChange}
                      accept={Config.CustomerReportedFile.AllowedFileTypes.join(
                        ", "
                      )}
                      style={{ visibility: "hidden" }}
                    />
                    {selectedFile !== undefined || dataFormat !== undefined ? (
                      <Box float="right">
                        <Button
                          id="clearFilesButton"
                          variant="link"
                          onClick={this.removeSelectedFileAndFormat}
                          disabled={uploadInProgress}
                        >
                          {this.formatMsg(messages.clearAll)}
                        </Button>
                      </Box>
                    ) : null}
                  </FormField>

                  <FormField stretch>
                    <div>
                      <Box variant="h4">
                        {this.formatMsg(messages.selectFileFormatText)}
                      </Box>
                    </div>
                    <RadioGroup
                      name="dataFormatGroup"
                      onChange={({ detail }) =>
                        this.onDataFormatChange(detail.value)
                      }
                      value={dataFormat === undefined ? null : dataFormat}
                      items={this.getTableItems()}
                      ariaRequired
                    />
                  </FormField>

                  <FormField stretch>
                    {selectedFile !== undefined ? (
                      <Alert
                        id="alertFileUpload"
                        type={this.getAlertType(selectedFile.status)}
                        key={`selected-file-${selectedFile.file.name}`}
                        dismissible={
                          selectedFile.status !== FileUploadStatus.InProgress
                        }
                        onDismiss={() => this.removeSelectedFile()}
                      >
                        <Grid
                          disableGutters
                          gridDefinition={[
                            { colspan: 12 },
                            { colspan: 4 },
                            { colspan: 8 },
                            { colspan: 12 }
                          ]}
                        >
                          <div>
                            <Box
                              padding="n"
                              variant="awsui-key-label"
                              fontSize="heading-xs"
                              display="inline"
                            >
                              {`${this.formatMsg(messages.file)}:`}
                            </Box>
                            &nbsp;
                            <Box variant="span" fontSize="heading-xs">
                              {selectedFile.file.name}
                            </Box>
                          </div>
                          <div>
                            <Box
                              padding="n"
                              variant="awsui-key-label"
                              fontSize="body-s"
                              display="inline"
                            >
                              {`${this.formatMsg(messages.fileSize)}:`}
                            </Box>
                            &nbsp;
                            <Box padding="n" variant="span" fontSize="body-s">
                              {this.formatFileSize(selectedFile.file.size)}
                            </Box>
                          </div>
                          <div className="no-padding">
                            <Box
                              variant="awsui-key-label"
                              fontSize="body-s"
                              display="inline"
                            >
                              {`${this.formatMsg(messages.lastModified)}:`}
                            </Box>
                            &nbsp;
                            <Box variant="span" fontSize="body-s">
                              {formatDate(selectedFile.file.lastModified)}
                              &nbsp;
                              {formatTime(selectedFile.file.lastModified)}
                            </Box>
                          </div>
                          <div className="no-padding">
                            {selectedFile.errorMessages.length > 0
                              ? selectedFile.errorMessages.map(
                                  validationErrorMessage => (
                                    <div key={validationErrorMessage}>
                                      <Box
                                        variant="span"
                                        color="text-status-error"
                                      >
                                        {validationErrorMessage}
                                      </Box>
                                    </div>
                                  )
                                )
                              : null}
                            {selectedFile.status === FileUploadStatus.Failed ? (
                              <Box variant="span" color="text-status-error">
                                {selectedFile.failureMessage}
                              </Box>
                            ) : null}
                          </div>
                        </Grid>
                      </Alert>
                    ) : null}
                    {selectedFile !== undefined ? null : (
                      <Alert type="warning">
                        <Box color="text-status-inactive">
                          {this.formatMsg(messages.noFilesSelected)}
                        </Box>
                      </Alert>
                    )}
                  </FormField>
                  <div />
                </ColumnLayout>
              </Form>
            </div>
          </Grid>
        )}
      </div>
    )
  }
}

export default injectIntl(withRouter(CustomerReportedFilesUpload))
