export enum DataType {
  Account = "Account",
  Request = "Request"
}

export enum Status {
  Pending = "Pending",
  Completed = "Completed",
  Active = "Active",
  Failed = "Failed",
  Inactive = "Inactive",
  Closed = "Closed",
  NotExist = "NotExist"
}

export enum ManagedAccountsAction {
  SCHEDULE_CLOSURE_ALL = "SCHEDULE_CLOSURE_ALL"
}

export enum ManagedAccountRoleName {
  SolutionArchitectRole = "ManagedAccountsSolutionArchitectRole",
  CustomerRole = "ManagedAccountsCustomerRole"
}

export const UNAVAILABLE_FIELD = "-"

export enum PollingConfigurations {
  PollIntervalMS = 3000,
  // for max wait time when there is no update to account
  MaxRetry = 20,
  // for max wait time when the account does not go into the desired status
  // 5 mins
  MaxWaitMS = 300000
}

export enum AlertType {
  None,
  AccountCreationError,
  AccountCreationTimeout,
  AccountClosureError,
  AccountClosureTimeout,
  FederationError,
  AuditLogRetrievalError,
  AccessKeysRetrievalError,
  AccessKeysLimitExceeded
}

export enum ErrorMessage {
  ACCESS_KEY_LIMIT_EXCEEDED = "Access key limit exceeded"
}

export const FEDERATION_DEFAULT_PAGE_REGEX = /(Destination=)(.*)/i
export enum FederationPages {
  AccessKeys = "https://console.aws.amazon.com/iamv2/home#/users/details/CollectorUser?section=security_credentials",
  MigrationHub = "https://console.aws.amazon.com/migrationhub/home"
}

export const CREATE_ACCOUNT_TICKET_URL =
  "https://t.corp.amazon.com/create/templates/d2cdb722-b705-4acb-a43d-e7a68b5ee424"

export const CLOSE_ACCOUNT_TICKET_URL =
  "https://t.corp.amazon.com/create/templates/1cd68d27-73fe-4175-ac32-1b6d5a36956c"

export const ACCOUNT_FEDERATION_TICKET_URL =
  "https://t.corp.amazon.com/create/templates/2811d1c2-634f-428b-815d-8114e5c815e3"

export const ACCOUNT_AUDIT_LOG_RETRIEVAL_TICKET_URL =
  "https://t.corp.amazon.com/create/templates/b7f87045-e29e-4f71-8d3d-a6c2de60928a"

export const ACCOUNT_ACCESS_KEYS_RETRIEVAL_TICKET_URL =
  "https://t.corp.amazon.com/create/templates/e25d9105-32d3-4b73-aa1a-c23fe0a2b719"

export const ACCOUNT_ALREADY_EXISTS_EXCPETION_MESSAGE =
  "Cannot have more than one active managed account per engagement"
