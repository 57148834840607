import { defineMessages } from "react-intl"

const messages = defineMessages({
  headerSignIn: {
    id: "header.signIn",
    defaultMessage: "Sign in"
  },
  headerChangePassword: {
    id: "header.changePassword",
    defaultMessage: "Change password"
  },
  headerEnableMfa: {
    id: "header.enableMfa",
    defaultMessage: "Enable MFA"
  },
  headerDisableMfa: {
    id: "header.disableMfa",
    defaultMessage: "Disable MFA"
  },
  headerSignOut: {
    id: "header.signOut",
    defaultMessage: "Sign out"
  },
  headerDeleteAccount: {
    id: "header.deleteAccount",
    defaultMessage: "Delete account"
  },
  headerDisableMfaConfirmationMessage: {
    id: "header.disableMfaConfirmationMessage",
    defaultMessage:
      "Are you sure you want to disable multi-factor authentication on your account?"
  },
  headerEnableMfaModalTitle: {
    id: "header.enableMfaModalTitle",
    defaultMessage: "Setup multi-factor authentication"
  },
  headerDisableMfaModalTitle: {
    id: "header.disableMfaModalTitle",
    defaultMessage: "Disable multi-factor authentication"
  },
  headerMfaDisabledMessage: {
    id: "header.mfaDisabledMessage",
    defaultMessage: "Multi-factor authentication on you account is disabled"
  },
  accountDeletionSuccessFlashbarContent: {
    id: "header.accountDeletionSuccessFlashbarContent",
    defaultMessage:
      "You will be redirected to the sign-in page in {seconds} second(s)."
  },
  accountDeletionSuccessFlashbarSignoutButtonContent: {
    id: "header.accountDeletionSuccessFlashbarSignoutButtonContent",
    defaultMessage: "Sign out now"
  },
  headerContactSupport: {
    id: "header.contactSupport",
    defaultMessage: "Contact support"
  }
})

export default messages
