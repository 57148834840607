import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import {
  Box,
  Container,
  Header,
  Link,
  Icon
} from "@amzn/awsui-components-react/polaris"
import { injectIntl, IntlShape } from "react-intl"
import messages from "./FrequentlyAskedQuestions.messages"
import Config from "../../Config"

const AppConfig = Config.Config

interface IFrequentlyAskedQuestionsProps {
  intl: IntlShape
  externalCustomer: boolean
}
interface IFrequentlyAskedQuestionsState {}

export class FrequentlyAskedQuestions extends React.PureComponent<
  RouteComponentProps & IFrequentlyAskedQuestionsProps,
  IFrequentlyAskedQuestionsState
> {
  render() {
    const {
      intl: { formatMessage }
    } = this.props

    const { externalCustomer } = this.props

    return (
      <Container
        id="FrequentlyAskedQuestionsContainer"
        header={<Header variant="h3">{formatMessage(messages.title)}</Header>}
        footer={
          <div>
            <Box variant="p" id="termsAndConditionsDescription1">
              {formatMessage(messages.termsAndConditionsDescriptionPart1)}
            </Box>
            <Box variant="p" id="termsAndConditionsDescription2">
              {formatMessage(messages.termsAndConditionsDescriptionPart2)}
            </Box>
            <Link
              data-testid="termsAndConditionsLink"
              href={AppConfig.Support.TermsAndConditionsLinkAddress}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage(messages.termsAndConditionsLinkDescription)}
              &nbsp;
              <Icon variant="link" name="external" />
            </Link>
          </div>
        }
      >
        <div>
          {externalCustomer ? (
            <>
              <Box variant="h4" data-testid="howToRenewAccessKey">
                {formatMessage(messages.howToRenewAccessKey)}
              </Box>
              <Box variant="p" data-testid="howToRenewAccessKeyAnswer">
                {formatMessage(messages.howToRenewAccessKeyAnswer)}
              </Box>
              <Box
                variant="h4"
                data-testid="howToFindOutWhoIsAccountManager"
                padding={{ top: "s" }}
              >
                {formatMessage(messages.howToFindOutWhoIsAccountManager)}
              </Box>
              <Box
                variant="p"
                data-testid="howToFindOutWhoIsAccountManagerAnswer"
              >
                {formatMessage(messages.howToFindOutWhoIsAccountManagerAnswer)}
                <Link
                  data-testid="faqSupportEmailLink"
                  href={`mailto:${AppConfig.Support.EmailAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &nbsp;{AppConfig.Support.EmailAddress}&nbsp;
                  <Icon variant="link" name="envelope" />
                </Link>
              </Box>
            </>
          ) : (
            <>
              <Box
                variant="h4"
                data-testid="howToGetAccessToExistingEngagement"
              >
                {formatMessage(messages.howToGetAccessToExistingEngagement)}
              </Box>
              <Box
                variant="p"
                data-testid="howToGetAccessToExistingEngagementAnswer"
              >
                {formatMessage(
                  messages.howToGetAccessToExistingEngagementAnswer,
                  {
                    assessmentCentralLinkAddress: (
                      <Link
                        data-testid="assessmentCentralLink"
                        key="assessmentCentralLink"
                        href={AppConfig.Support.AssessmentCentralLinkAddress}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatMessage(messages.assessmentCentral)}&nbsp;
                        <Icon variant="link" name="external" />
                      </Link>
                    )
                  }
                )}
              </Box>
              <Box
                variant="h4"
                data-testid="cannotSeeCustomerData"
                padding={{ top: "s" }}
              >
                {formatMessage(messages.cannotSeeCustomerData)}
              </Box>
              <Box variant="p" data-testid="cannotSeeCustomerDataAnswer">
                {formatMessage(messages.cannotSeeCustomerDataAnswer)}
              </Box>
              <Box
                variant="h4"
                data-testid="howToRequestEngagementForCustomer"
                padding={{ top: "s" }}
              >
                {formatMessage(messages.howToRequestEngagementForCustomer)}
              </Box>
              <Box
                variant="p"
                data-testid="howToRequestEngagementForCustomerAnswer"
              >
                {formatMessage(
                  messages.howToRequestEngagementForCustomerAnswer,
                  {
                    howToEngageWikiLinkAddress: (
                      <Link
                        data-testid="howToEngageWikiLink"
                        key="howToEngageWikiLink"
                        href={AppConfig.Support.HowToEngageWikiLinkAddress}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatMessage(messages.wikiPage)}&nbsp;
                        <Icon variant="link" name="external" />
                      </Link>
                    )
                  }
                )}
              </Box>
              <Box
                variant="h4"
                data-testid="howToLearnMore"
                padding={{ top: "s" }}
              >
                {formatMessage(messages.howToLearnMore)}
              </Box>
              <Box variant="p" data-testid="howToLearnMoreAnswer">
                {formatMessage(messages.howToLearnMoreAnswer, {
                  trainingWikiLinkAddress: (
                    <Link
                      data-testid="trainingWikiLink"
                      key="trainingWikiLink"
                      href={AppConfig.Support.TrainingWikiLinkAddress}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage(messages.trainingWikiPage)}&nbsp;
                      <Icon variant="link" name="external" />
                    </Link>
                  ),
                  officeHoursLinkAddress: (
                    <Link
                      data-testid="officeHoursLink"
                      key="officeHoursLink"
                      href={AppConfig.Support.OfficeHoursLinkAddress}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage(messages.officeHours)}&nbsp;
                      <Icon variant="link" name="external" />
                    </Link>
                  )
                })}
              </Box>
            </>
          )}
        </div>
        <br />
      </Container>
    )
  }
}

export default injectIntl(withRouter(FrequentlyAskedQuestions))
