import React from "react"
import { injectIntl, IntlShape } from "react-intl"
import {
  Button,
  ButtonProps,
  StatusIndicator,
  Popover
} from "@amzn/awsui-components-react"
import commonMessages from "../Common.messages"

interface ICopyButtonProps {
  intl: IntlShape
  value: string
  variant?: ButtonProps.Variant
}

export class CopyButton extends React.PureComponent<ICopyButtonProps> {
  copyValueToClipboard = () => {
    const { value } = this.props
    navigator.clipboard.writeText(value).catch((
      err // eslint-disable-next-line no-console
    ) => console.error("Failed to copy text to clipboard", err))
  }

  render() {
    const {
      intl: { formatMessage },
      variant
    } = this.props
    return (
      <Popover
        size="small"
        position="top"
        triggerType="custom"
        dismissButton={false}
        content={
          <StatusIndicator type="success">
            {formatMessage(commonMessages.copiedToClipboard)}
          </StatusIndicator>
        }
      >
        <Button
          ariaLabel={formatMessage(commonMessages.copy)}
          id="copyButton"
          iconName="copy"
          variant={variant || "normal"}
          onClick={this.copyValueToClipboard}
        />
      </Popover>
    )
  }
}

export default injectIntl(CopyButton)
