import { defineMessages } from "react-intl"

const messages = defineMessages({
  title: {
    id: "customerReportedFileUploadSingleFile.title",
    defaultMessage: "Upload self-reported files"
  },
  unexpectedError: {
    id: "customerReportedFileUploadSingleFile.unexpectedError",
    defaultMessage: "Encountered an unexpected error"
  },
  emptyAdsExportError: {
    id: "customerReportedFileUploadSingleFile.emptyAdsExportError",
    defaultMessage: "The uploaded ZIP file does not contain CSV files"
  },
  selectFilesLabel: {
    id: "customerReportedFileUploadSingleFile.selectFilesLabel",
    defaultMessage: "Select file to upload"
  },
  selectFiles: {
    id: "customerReportedFileUploadSingleFile.selectFiles",
    defaultMessage: "Choose file"
  },
  selectFilesDescription: {
    id: "customerReportedFileUploadSingleFile.selectFilesDescription",
    defaultMessage:
      "Only files with the following extensions are allowed: {allowedFileTypes} with maximum size of {maxAllowedFileSize}"
  },
  file: {
    id: "customerReportedFileUploadSingleFile.file",
    defaultMessage: "File"
  },
  fileSize: {
    id: "customerReportedFileUploadSingleFile.fileSize",
    defaultMessage: "Size"
  },
  lastModified: {
    id: "customerReportedFileUploadSingleFile.lastModified",
    defaultMessage: "Last modified"
  },
  uploadButtonText: {
    id: "customerReportedFileUploadSingleFile.fileUpload",
    defaultMessage: "Upload"
  },
  noFilesSelected: {
    id: "customerReportedFileUploadSingleFile.noFilesSelected",
    defaultMessage: "No file selected"
  },
  fileSizeGreaterThanMaxAllowed: {
    id: "customerReportedFileUploadSingleFile.fileSizeGreaterThanMaxAllowed",
    defaultMessage:
      "File size exceeded the maximum allowed file size of {maxAllowedFileSize}"
  },
  fileTypeNotAllowed: {
    id: "customerReportedFileUploadSingleFile.fileTypeNotAllowed",
    defaultMessage: "File type must be one of the following: {allowedFileTypes}"
  },
  invalidFileName: {
    id: "customerReportedFileUploadSingleFile.invalidFileName",
    defaultMessage: "Invalid character(s) in filename"
  },
  clearAll: {
    id: "customerReportedFileUploadSingleFile.clearAll",
    defaultMessage: "Clear all"
  },
  selectFileFormatText: {
    id: "customerReportedFileUploadSingleFile.selectFileFormatText",
    defaultMessage: "Select file format:"
  },
  meSimpleText: {
    id: "customerReportedFileUploadSingleFile.meSimpleText",
    defaultMessage: "Data Import Template"
  },
  meCollectorExportText: {
    id: "customerReportedFileUploadSingleFile.meCollectorExportText",
    defaultMessage: "Inventory and Utilization Export"
  },
  adsCollectorExportText: {
    id: "customerReportedFileUploadSingleFile.adsCollectorExportText",
    defaultMessage: "Application Discovery Service Export"
  },
  fileFormatHelpText: {
    id: "customerReportedFileUploadSingleFile.fileFormatHelpText",
    defaultMessage: "Learn more"
  },
  meSimpleTemplateUnavailableText: {
    id: "customerReportedFileUploadSingleFile.meSimpleTemplateUnavailableText",
    defaultMessage: "Could not retrieve the latest Data Import Template."
  },
  meSimpleDescriptionText: {
    id: "customerReportedFileUploadSingleFile.meSimpleDescriptionText",
    defaultMessage:
      "A Microsoft Excel file with a standard set of fields containing inventory data"
  },
  meCollectorExportDescriptionText: {
    id: "customerReportedFileUploadSingleFile.meCollectorExportDescriptionText",
    defaultMessage: "An export of Migration Evaluator collector inventory"
  },
  adsCollectorExportDescriptionText: {
    id:
      "customerReportedFileUploadSingleFile.adsCollectorExportDescriptionText",
    defaultMessage: "A ZIP file containing a set of CSV files"
  },
  meSimpleInvalidUploadFileTypeDescriptionText: {
    id:
      "customerReportedFileUploadSingleFile.meSimpleInvalidUploadFileTypeDescriptionText",
    defaultMessage:
      "Data Import Template extension must be either .xlsx or .xls"
  },
  meCollectorExportInvalidUploadFileTypeDescriptionText: {
    id:
      "customerReportedFileUploadSingleFile.meCollectorExportInvalidUploadFileTypeDescriptionText",
    defaultMessage:
      "Inventory and Utilization Export extension must be either .xlsx or .xls"
  },
  adsCollectorExportInvalidUploadFileTypeDescriptionText: {
    id:
      "customerReportedFileUploadSingleFile.adsCollectorExportInvalidUploadFileTypeDescriptionText",
    defaultMessage:
      "Application Discovery Service Export extension must be .zip"
  }
})

export default messages
