import React from "react"
import {
  useCollection,
  UseCollectionOptions
} from "@amzn/awsui-collection-hooks"
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PaginationProps,
  Table,
  TableProps,
  TextFilter
} from "@amzn/awsui-components-react"

export interface ITableWrapperProps<T> {
  "data-testid"?: string
  header: React.ReactNode
  loading: boolean
  loadingText: string
  empty: React.ReactNode
  items: T[]
  columnDefinitions: TableProps.ColumnDefinition<T>[]
  useCollectionOptions: UseCollectionOptions<T>
  selectionProps?: {
    selectionType: TableProps.SelectionType
    selectedItems: ReadonlyArray<T>
    onSelectionChange: (
      e: CustomEvent<TableProps.SelectionChangeDetail<T>>
    ) => void
  }
  filterProps?: {
    filteringPlaceholder: string
  }
  paginationProps?: {
    ariaLabels: PaginationProps.Labels
  }
  preferenceProps?: {
    preferenceTitle: string
    preferenceConfirmLabel: string
    preferenceCancelLabel: string
    preferenceDisabled: boolean
    pageSizePreference: CollectionPreferencesProps.PageSizePreference
    currentPreferences: CollectionPreferencesProps.Preferences
    preferenceOnConfirm: (
      e: Omit<
        CustomEvent<CollectionPreferencesProps.Preferences>,
        "preventDefault"
      >
    ) => void
  }
}

// functional component to take advantage of the useCollection hook's
// client side rendering features
function TableWrapper<T>(props: ITableWrapperProps<T>) {
  const {
    "data-testid": testId,
    header,
    loading,
    loadingText,
    empty,
    items,
    columnDefinitions,
    useCollectionOptions,
    selectionProps,
    filterProps,
    paginationProps,
    preferenceProps
  } = props

  const {
    items: tableItems,
    collectionProps,
    filterProps: hookFilterProps,
    paginationProps: hookPaginationProps
  } = useCollection(items, {
    ...useCollectionOptions
  })

  // custom event handlers that override the default behavior
  collectionProps.selectedItems =
    selectionProps?.selectedItems ?? collectionProps.selectedItems
  collectionProps.onSelectionChange =
    selectionProps?.onSelectionChange ?? collectionProps.onSelectionChange

  return (
    <Table
      data-testid={testId}
      empty={empty}
      header={header}
      loading={loading}
      loadingText={loadingText}
      items={tableItems}
      columnDefinitions={columnDefinitions}
      selectionType={selectionProps?.selectionType}
      preferences={
        preferenceProps?.preferenceTitle && (
          <CollectionPreferences
            title={preferenceProps.preferenceTitle}
            preferences={preferenceProps?.currentPreferences}
            confirmLabel={preferenceProps?.preferenceConfirmLabel!}
            cancelLabel={preferenceProps?.preferenceCancelLabel!}
            disabled={preferenceProps?.preferenceDisabled}
            pageSizePreference={preferenceProps?.pageSizePreference}
            onConfirm={preferenceProps?.preferenceOnConfirm}
          />
        )
      }
      // useCollection props
      sortingColumn={collectionProps.sortingColumn}
      sortingDescending={collectionProps.sortingDescending}
      onSortingChange={collectionProps.onSortingChange}
      selectedItems={collectionProps.selectedItems}
      onSelectionChange={collectionProps.onSelectionChange}
      trackBy={collectionProps.trackBy}
      ref={collectionProps.ref}
      filter={
        useCollectionOptions.filtering && (
          <TextFilter
            filteringText={hookFilterProps.filteringText}
            filteringPlaceholder={filterProps?.filteringPlaceholder}
            onChange={hookFilterProps.onChange}
            data-testid="engagementSearchTbx"
          />
        )
      }
      pagination={
        useCollectionOptions.pagination && (
          <Pagination
            ariaLabels={paginationProps?.ariaLabels}
            currentPageIndex={hookPaginationProps.currentPageIndex}
            pagesCount={hookPaginationProps.pagesCount}
            onChange={hookPaginationProps.onChange}
          />
        )
      }
    />
  )
}

export default TableWrapper
