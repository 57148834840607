import { defineMessages } from "react-intl"

const messages = defineMessages({
  title: {
    id: "customerReportedFilesImport.title",
    defaultMessage: "Import"
  },
  summary: {
    id: "customerReportedFilesImport.summary",
    defaultMessage:
      "Use inventory you already have to upload data about servers, licenses, and environments from existing data sources."
  },
  downloadTemplateInstructions: {
    id: "customerReportedFilesImport.downloadTemplateInstructions",
    defaultMessage:
      "Download the inventory template. If running the on-premises data collector, download a prepopulated template from your collector. Otherwise, download a blank template:"
  },
  downloadTemplateLink: {
    id: "customerReportedFilesImport.downloadTemplateLink",
    defaultMessage: "Download the blank template"
  },
  fillInRequiredFieldsInstructions: {
    id: "customerReportedFilesImport.fillInRequiredFieldsInstructions",
    defaultMessage:
      "Fill in all of the required fields and as many of the recommended fields as possible"
  },
  uploadFileInstructions: {
    id: "customerReportedFilesImport.uploadFileInstructions",
    defaultMessage: "Import and upload the file on the self-reported files page"
  },
  viewAndImportFiles: {
    id: "customerReportedFilesImport.viewAndImportFiles",
    defaultMessage: "View and import files"
  }
})

export default messages
